import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Box, TextField } from '@mui/material';

import FeedbackIcon from '@mui/icons-material/Feedback';

import { CircularProgress } from '@mui/material';

import PrimaryButton from '../PrimaryButton/PrimaryButton';

import ButtonLoader from '../ButtonLoader/ButtonLoader';
import { reportProblemOrder } from '../../slices/orderSlice';
import {
  setCurrentCartTab,
  setCurrentGuestReceiptTab,
} from '../../slices/authNavReducer';
import { open } from '../../slices/snackbarReducer';
import { useAppDispatch, useAppSelector } from '../../hooks';

import './GuestReceiptOffScreen.scss';

export default function AccountNavOffScreen(props: any) {
  const dispatch = useAppDispatch();
  const { t, i18n }: any = useTranslation();

  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  const viaCart: boolean = useAppSelector((state) => state.order.viaCart);

  const orderLoading: boolean = useAppSelector(
    (state) => state.order.getExistingOrderLoading
  );

  const order: any = useAppSelector((state) => state.order.existingOrder);

  if (orderLoading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Formik
        initialValues={{
          note: '',
        }}
        validationSchema={Yup.object().shape({
          note: Yup.string()
            .max(250, 'Comment should be less than 250 characters')
            .required(),
        })}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          setSubmitting(true);

          dispatch(
            reportProblemOrder(order._id, values.note, () => {
              setSubmitting(false);
              if (viaCart) {
                dispatch(setCurrentCartTab('receipt'));
              } else {
                dispatch(setCurrentGuestReceiptTab('receipt'));
              }

              dispatch(
                open({
                  message: 'Your feedback was sent.',
                  severity: 'success',
                })
              );
            })
          );
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} className="form-login">
            <h2 className="heading-sans-serif">
              Report a problem with your order
            </h2>
            <Box mt={4}></Box>

            <p className="offscreen-menu__content__section-heading">Comments</p>
            <TextField
              color="secondary"
              label="Note"
              name="note"
              fullWidth
              variant="outlined"
              multiline
              value={values.note}
              onBlur={handleBlur}
              onChange={handleChange}
              error={Boolean(errors.note && touched.note)}
              style={{ marginBottom: '32px' }}
              // helperText={errors.notes && touched.notes && errors.notes}
            />

            <PrimaryButton
              style={{ width: '100%', marginTop: '2em' }}
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <ButtonLoader />
              ) : (
                <>
                  <FeedbackIcon
                    style={{ marginRight: '8px', marginTop: '4px' }}
                  />{' '}
                  Report problem
                </>
              )}
            </PrimaryButton>
          </form>
        )}
      </Formik>
    </>
  );
}
