import { createSlice } from '@reduxjs/toolkit';
import feathers from '../feathers';

import IngredientsService from '../api/ingredients';

import { AppDispatch, AppThunk } from '../store';

const initialState = {
  ingredients: {
    data: [],
    total: 0,
  },
  ingredientsLoading: true,
};

const ingredientsSlice = createSlice({
  name: 'ingredients',
  initialState,
  reducers: {
    getIngredientsSuccess: (state, action) => {
      state.ingredients = action.payload;
      state.ingredientsLoading = false;
    },
    setIngredientsLoading: (state, action) => {
      state.ingredientsLoading = action.payload;
    },
  },
});

export const { getIngredientsSuccess, setIngredientsLoading } =
  ingredientsSlice.actions;

export const getProducts =
  (query: object | undefined): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setIngredientsLoading(true));
      const products = await IngredientsService.find(query);
      dispatch(getIngredientsSuccess(products));
      dispatch(setIngredientsLoading(false));
    } catch (error) {
      console.log(error);
    }
  };

export default ingredientsSlice.reducer;
