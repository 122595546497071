import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import { Provider } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { Wrapper } from "@googlemaps/react-wrapper";

import Home from "./pages/Home/Home";
import Catering from "./pages/Catering/Catering";

import OAuthSuccess from "./components/OAuthSuccess/OAuthSuccess";
import Navigation from "./components/Navigation/Navigation";
import Backdrop from "./components/Backdrop/Backdrop";

import SnackbarContainer from "./components/SnackbarContainer/SnackbarContainer";

import feathers from "./feathers";

import store from "./store";

import { loginSuccess, loginFailed } from "./slices/authReducer";
import { getSubscription } from "./slices/subscriptionReducer";

import VerifyEmail from "./pages/VerifyEmail";
import ResetPassword from "./pages/ResetPassword";
import CartOffScreen from "./components/CartOffScreen/CartOffScreen";
import { getCategories } from "./slices/categoriesReducer";
import stripe from "./stripe";

import { useAppDispatch, useAppSelector } from "./hooks";

import "./App.scss";
import ErrorBoundary from "./components/SliderErrorBoundary/SliderErrorBoundary";

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

const theme = createTheme({
  palette: {
    primary: { main: "#00b89b" },
    secondary: { main: "#707e81" },
    warning: { main: "#d32f2f" },
  },

  components: {
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
    },
  },
});

const AccountNavOffScreen = React.lazy(
  () => import("./components/AccountNavOffScreen/AccountNavOffScreen")
);
const AuthAccountNavOffScreen = React.lazy(
  () => import("./components/AuthAccountNavOffScreen/AuthAccountNavOffScreen")
);
const GiftCardBuyOffScreen = React.lazy(
  () => import("./components/GiftCardBuyOffScreen/GiftCardBuyOffScreen")
);
const GuestReceiptOffScreen = React.lazy(
  () => import("./components/GuestReceiptOffScreen/GuestReceiptOffScreen")
);

function App() {
  const dispatch = useAppDispatch();
  const currentTab: string = useAppSelector(
    (state) => state.authNav.currentTabNonAuth
  );
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    // dispatch(checkingIfAuthenticated(true));
    dispatch(getCategories());

    feathers
      .reAuthenticate()
      .then((res) => {
        dispatch(loginSuccess(res));
        dispatch(getSubscription(res.user.subscriptionId));
      })
      .catch((err) => {
        dispatch(loginFailed());
      })
      .finally(() => {
        // dispatch(checkingIfAuthenticated(true));
      });

    let urlObject = new URLSearchParams(window.location.search);

    if (urlObject.has("order")) {
      console.log("has order");
      document.body.classList.add("guest-receipt--open");
    }
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Wrapper apiKey={"AIzaSyBVJzk-BCm6eiiOso1yuJ73Fn75JUtDRwE"}>
          <BrowserRouter>
            <div className="App">
              <SnackbarContainer />

              <Navigation />

              <Routes>
                <Route index element={<Home />} />
                <Route path="/catering" element={<Catering />} />
                <Route path="/oauth-success" element={<OAuthSuccess />} />
                <Route path="/verify-email/:token" element={<VerifyEmail />} />
                <Route
                  path="/reset-password/:token"
                  element={<ResetPassword />}
                />
              </Routes>
              <ErrorBoundary>
                <Suspense fallback={<div>Loading...</div>}>
                  <Elements stripe={stripe}>
                    <CartOffScreen />
                    <GiftCardBuyOffScreen />
                  </Elements>

                  {isAuthenticated ? (
                    <AuthAccountNavOffScreen />
                  ) : (
                    <AccountNavOffScreen />
                  )}

                  <GuestReceiptOffScreen />

                  {/* {currentTab != "" && <Backdrop />} */}
                </Suspense>
              </ErrorBoundary>
            </div>
          </BrowserRouter>
        </Wrapper>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default () => (
  <Provider store={store}>
    <App />
  </Provider>
);
