import { findPlace } from '.';

export default async function fetchPlaces(
  setOptions: Function,
  searchTerm: String,
  lastId: String,
  setLoading: Function,
  setOpen: Function
) {
  setLoading(true);

  console.log(searchTerm);
  console.log(lastId);

  const response = await findPlace(lastId, searchTerm);

  setLoading(false);
  console.log("fetchplace response", response);
  console.log("fetch place response items", response.data.Items);

  if (response.data.Items.length > 0) {
    setOptions(response.data.Items);
    setOpen(true);
  } else {
    setOptions([]);
  }
  // Object.keys(response).map(key => response[key].item[0]) as CountryType[]
}
