import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import feathers from "../feathers";
import { AppDispatch, AppThunk } from "../store";

const initialState = {
  organization: null,
  organizationLoading: true,
  selectedWeek: [1, 2, 3].indexOf(moment().isoWeekday()) >= 0 ? 1 : 2,
};

const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    getOrganizationSuccess: (state, action) => {
      state.organization = action.payload;
      state.organizationLoading = false;
    },
    setSelectedWeek: (state, action) => {
      state.selectedWeek = action.payload;
    },
  },
});

export const { getOrganizationSuccess, setSelectedWeek } =
  organizationSlice.actions;

export const getOrganization = (): AppThunk => async (dispatch) => {
  try {
    const org = await feathers.service("organization").find();
    console.log(org.data[0]);
    dispatch(getOrganizationSuccess(org.data[0]));
  } catch (error) {
    console.log(error);
  }
};

export default organizationSlice.reducer;
