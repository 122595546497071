import React from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import LanguageIcon from "@mui/icons-material/Language";

import logo from "../../assets/vittle-logo-teal.svg";

import { useAppSelector } from "../../hooks";

import "./Navigation.scss";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";

const pages = [
  {
    title: "HOW IT WORKS",
    link: "/on-the-menu",
  },
  {
    title: "CATERING",
    link: "/catering",
  },
  {
    title: "GIFTS",
    link: "/on-the-menu",
  },
  {
    title: "ABOUT",
    link: "/about",
  },
];
const settings = ["Profile", "Account", "Dashboard", "Logout"];
function Navigation(props: any) {
  const { t, i18n }: any = useTranslation();
  const [currLang, setCurrentLang] = React.useState(i18n.language);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  const user: any = useAppSelector((state) => state.auth.user);

  const cartItems: any = useAppSelector((state) => state.cart.items.length);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const toggleCart = (e: any) => {
    e.preventDefault();
    document.body.classList.toggle("cart--open");
  };

  const toggleSignUpMenu = () => {
    if (!isAuthenticated) {
      document.body.classList.toggle("signup-menu--open");
    }
  };

  const toggleAccountMenu = () => {
    if (isAuthenticated) {
      document.body.classList.toggle("account-menu--open");
    }
  };

  const toggleGiftCardMenu = () => {
    document.body.classList.toggle("gift-card-buy--open");
  };

  const changeLang = () => {
    if (i18n.language === "en") {
      i18n.changeLanguage("fr");
      setCurrentLang("fr");
    } else {
      i18n.changeLanguage("en");
      setCurrentLang("en");
    }
  };

  return (
    <React.Fragment>
      <AppBar position="static" color="primary" className="header">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} /> */}
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <img alt="Vittle Logo" src={logo} />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                className="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page, i) => (
                  <MenuItem key={i} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page.title}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            {/* <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} /> */}
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <img alt="Vittle Logo" src={logo} />
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page, i) => {
                if (page.title === "GIFTS") {
                  return (
                    <Button
                      key={i}
                      component={Link}
                      to="#"
                      onClick={(e: any) => {
                        console.log("yes");
                        toggleGiftCardMenu();
                        e.preventDefault();
                      }}
                      sx={{ my: 2, color: "white", display: "block" }}
                    >
                      {
                        // @ts-ignore
                        page.title
                      }
                    </Button>
                  );
                } else {
                  return (
                    <Button
                      key={i}
                      onClick={handleCloseNavMenu}
                      component={Link}
                      to={
                        // @ts-ignore
                        page.link
                      }
                      sx={{ my: 2, color: "white", display: "block" }}
                    >
                      {
                        // @ts-ignore
                        page.title
                      }
                    </Button>
                  );
                }
              })}
            </Box>

            <Box
              sx={{
                flexGrow: 0,
                display: { xs: "flex", md: "flex", lg: "flex" },
              }}
            >
              {/* <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                </IconButton> */}
              <Tooltip
                title="Open settings"
                sx={{
                  display: { xs: "none", md: "none", lg: "flex" },
                }}
              >
                <Button
                  component={Link}
                  to="#"
                  onClick={() => changeLang()}
                  style={{
                    fontWeight: 600,
                    fontFamily: "Inter-ExtraBold, sans-serif",
                  }}
                >
                  <LanguageIcon style={{ marginRight: "8px" }} />
                  {currLang}
                </Button>
              </Tooltip>
              <Tooltip
                title="Open settings"
                sx={{
                  display: { xs: "none", md: "none", lg: "flex" },
                }}
              >
                <Button
                  component={Link}
                  to="#"
                  onClick={toggleCart}
                  className={cartItems !== 0 ? "items-in-cart" : ""}
                  style={{
                    fontWeight: 600,
                    fontFamily: "Inter-ExtraBold, sans-serif",
                  }}
                >
                  {cartItems !== 0 && (
                    <>
                      <div style={{ position: "relative", zIndex: 1 }}>
                        <ShoppingBasketIcon style={{ color: "#33474c" }} />
                        <span
                          style={{
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%, -50%)",
                            background: "#33474c",
                            width: "8px",
                            height: "8px",
                          }}
                        ></span>
                      </div>
                      <span
                        className="products-area__item__info__pricing__count"
                        style={{
                          marginLeft: "-12px",
                          marginRight: "4px",
                          width: "24px",
                          height: "24px",
                          position: "relative",
                          zIndex: 2,
                          border: "2px solid white",
                        }}
                        // style={{
                        //   width: '20px',
                        //   height: '20px',
                        //   display: 'inline-block',
                        //   background:
                        // }}
                      >
                        {cartItems}
                      </span>
                    </>
                  )}
                  <span className="cart-text">{t("cart")}</span>
                </Button>
              </Tooltip>

              {!isAuthenticated && (
                <Tooltip title="Open settings">
                  <Button
                    component={Link}
                    to="#"
                    onClick={toggleSignUpMenu}
                    style={{
                      fontWeight: 600,
                      fontFamily: "Inter-ExtraBold,> sans-serif",
                    }}
                  >
                    <Avatar
                      className="avatar"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "10px",
                      }}
                    />{" "}
                    {t("Signin2")}
                  </Button>
                </Tooltip>
              )}

              {isAuthenticated && user !== null && (
                <Tooltip title="Open settings">
                  <Button onClick={toggleAccountMenu}>
                    <Avatar
                      style={{
                        marginRight: "10px",
                        width: "24px",
                        height: "24px",
                      }}
                      src={
                        //@ts-ignore
                        user !== null &&
                        (user.hasOwnProperty("facebookId")
                          ? //@ts-ignore
                            user.picture.url
                          : "")
                      }
                    >
                      {
                        //@ts-ignore
                        user && user.firstName && `${user.firstName[0]}`
                      }
                    </Avatar>
                    {
                      //@ts-ignore
                      user && user.firstName && `${user.firstName}`
                    }
                  </Button>
                </Tooltip>
              )}

              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {/* <nav className="container-wide mainNav">
        <div className="mainNav__left">
          <div className="mobileMenuIcon" onClick={() => toggleMobileNav()}>
            <img src={menuIcon} alt="Menu Icon" />
          </div>
          <Link to="/">
            <img alt="Vittle Logo" src={logo} />
          </Link>
          <Button component={Link} to="/on-the-menu">
            How it Works
          </Button>
          <Button component={Link} to="/catering">
            Catering
          </Button>
          <Button
            component={Link}
            to="#"
            onClick={(e: any) => {
              console.log('yes');
              toggleGiftCardMenu();
              e.preventDefault();
            }}
          >
            Gifts
          </Button>
          <Button component={Link} to="/about">
            About
          </Button>
        </div>
        <div className="mainNav__right">
          <Button
            component={Link}
            to="#"
            onClick={() => changeLang()}
            style={{
              fontWeight: 600,
              fontFamily: 'Inter-ExtraBold, sans-serif',
            }}
          >
            <LanguageIcon style={{ marginRight: '8px' }} />
            {currLang}
          </Button>

          <Button
            component={Link}
            to="#"
            onClick={toggleCart}
            className={cartItems !== 0 ? 'items-in-cart' : ''}
            style={{
              fontWeight: 600,
              fontFamily: 'Inter-ExtraBold, sans-serif',
            }}
          >
            {cartItems !== 0 && (
              <>
                <div style={{ position: 'relative', zIndex: 1 }}>
                  <ShoppingBasketIcon style={{ color: '#33474c' }} />
                  <span
                    style={{
                      position: 'absolute',
                      left: '50%',
                      top: '50%',
                      transform: 'translate(-50%, -50%)',
                      background: '#33474c',
                      width: '8px',
                      height: '8px',
                    }}
                  ></span>
                </div>
                <span
                  className="products-area__item__info__pricing__count"
                  style={{
                    marginLeft: '-12px',
                    marginRight: '4px',
                    width: '24px',
                    height: '24px',
                    position: 'relative',
                    zIndex: 2,
                    border: '2px solid white',
                  }}
                  // style={{
                  //   width: '20px',
                  //   height: '20px',
                  //   display: 'inline-block',
                  //   background:
                  // }}
                >
                  {cartItems}
                </span>
              </>
            )}
            <span className="cart-text">{t('cart')}</span>
          </Button>

          {!isAuthenticated && (
            <Button
              component={Link}
              to="#"
              onClick={toggleSignUpMenu}
              style={{
                fontWeight: 600,
                fontFamily: 'Inter-ExtraBold, sans-serif',
              }}
            >
              <Avatar
                className="avatar"
                style={{
                  width: '20px',
                  height: '20px',
                  marginRight: '10px',
                }}
              />{' '}
              {t('Signin2')}
            </Button>
          )}

          {isAuthenticated && user !== null && (
            <Box display="flex" alignItems="center">
              <Box display="flex" alignItems="center">
                <Button onClick={toggleAccountMenu}>
                  <Avatar
                    style={{
                      marginRight: '10px',
                      width: '24px',
                      height: '24px',
                    }}
                    src={
                      //@ts-ignore
                      user !== null &&
                      (user.hasOwnProperty('facebookId')
                        ? //@ts-ignore
                          user.picture.url
                        : '')
                    }
                  >
                    {
                      //@ts-ignore
                      user && user.firstName && `${user.firstName[0]}`
                    }
                  </Avatar>
                  {
                    //@ts-ignore
                    user && user.firstName && `${user.firstName}`
                  }
                </Button>
              </Box>
              <Box></Box>
            </Box>
          )}
        </div>
      </nav> */}

      {/* {pathname == "/catering" ||
      pathname == "/catering-menu" ||
      pathname == "/catering-menu-item" ||
      pathname === "/catering-events" ||
      pathname === "/catering/event" ||
      pathname === "catering/request-a-quote" ? (
        <div className='subNav'>
          <div>
            <Link to='/catering-events'>
              <a href='/catering-events'>Events</a>
            </Link>
            <Link to='/catering-menu'>
              <a href='/catering-menu'>Catering Menu</a>
            </Link>
            <Link to='/about-us'>
              <a href='/about-us'>About us</a>
            </Link>
          </div>
        </div>
      ) : (
        ""
      )} */}

      {/* <nav className="mobileNav">
        <div className="mobileNav__close" onClick={() => toggleMobileNav()}>
          <img src="/close-icon.svg" alt="Close Icon" />
        </div>

        <Link to="/on-the-menu">On the Menu</Link>
        <Link to="/pricing">Pricing</Link>
        <Link to="/gifts">Gifts</Link>
        <Link to="/catering">Catering</Link>
      </nav> */}
    </React.Fragment>
  );
}

export default Navigation;
