import moment from 'moment';

export default (selectedWeek: number) => {
  const weekMonth = moment().add(selectedWeek, 'w').format('MMMM');

  const weekStartDate = moment()
    .add(selectedWeek, 'weeks')
    .startOf('isoWeek')
    .format('D');

  const weekEndDate = moment()
    .add(selectedWeek, 'weeks')
    .startOf('isoWeek')
    .add(4, 'd')
    .format('D');

  return `Week of ${weekMonth} ${weekStartDate}-${weekEndDate}`;
};
