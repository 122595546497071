/* eslint-disable import/no-anonymous-default-export */
// Category API
import feathers from "../../feathers";

export default {
  get: (id: string) => {
    return feathers.service("categories").get(id);
  },

  find: (query: object | undefined = undefined) => {
    if (query) {
      return feathers.service("categories").find({
        query,
      });
    }

    return feathers.service("categories").find();
  },
};
