/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { useTranslation } from "react-i18next";
import currency from "currency.js";
import {
  Box,
  Tooltip,
  IconButton,
  Link as MaterialUILink,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import {
  setProductToEdit,
  setProductToEditIndex,
} from "../../slices/cartReducer";

import { setCurrentCartTab, setCurrentTab } from "../../slices/authNavReducer";
import calculateTotalItemPrice from "../../modules/calculateTotalItemPrice";

import "./CartItem.scss";
import { useAppDispatch } from "../../hooks";
import {
  setSubscriptionProductToEdit,
  setSubscriptionProductToEditIndex,
  setViewingProductFromSubscripionWeek,
} from "../../slices/subscriptionReducer";

interface CartItemProps {
  item: any;
  index?: number;
  discountPrice?: number;
  noHover?: boolean;
  editable?: boolean;
  viewingItemFromSubscription?: boolean;
  isDishDelivered?: boolean;
}

export default function (props: CartItemProps) {
  const dispatch = useAppDispatch();
  const { t, i18n }: any = useTranslation();

  const {
    item,
    index,
    editable,
    viewingItemFromSubscription,
    isDishDelivered,
  } = props;

  const renderSelectedExtras = () => {
    if (!item.hasOwnProperty("selectedExtras")) {
      return "";
    }

    if (item.selectedExtras.length === 0) {
      return "";
    }

    return item.selectedExtras.map((e: any) => {
      return (
        <p
          style={{
            marginTop: 0,
            marginBottom: 0,
            fontSize: "14px",
            color: "#b9b2b6",
          }}
        >
          {e.title}
        </p>
      );
    });
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        className={`cart-line-item ${
          props.noHover ? "cart-line-item--no-hover" : ""
        } ${isDishDelivered ? "cart-line-item--disabled-background" : ""}`}
        flexDirection="column"
        onClick={() => {
          if (props.viewingItemFromSubscription) {
            dispatch(setViewingProductFromSubscripionWeek(true));
            dispatch(setSubscriptionProductToEdit(item));
            dispatch(setSubscriptionProductToEditIndex(index));
            dispatch(setCurrentTab("edit-subscription-cart-item"));
          } else {
            dispatch(setViewingProductFromSubscripionWeek(false));
            dispatch(setProductToEdit(item));
            dispatch(setProductToEditIndex(index));
            dispatch(setCurrentCartTab("edit-cart-item"));
          }
        }}
      >
        <Box display="flex" alignItems="flex-start" width="100%">
          <img
            width="64px"
            src={
              item.variants[0].thumbnailURL
                ? `https://s3.amazonaws.com/vittle-new/${item.variants[0].thumbnailURL}`
                : "https://via.placeholder.com/64x64/ccc"
            }
            alt=""
          />

          <Box display="flex" width="100%" justifyContent="space-between">
            <Box display="flex" flexDirection="column" pr={2} width="60%">
              <p className="offscreen-menu__content__section-heading fw-400--family mt-0 mb-0 cart-line-item__line-clamp">
                {i18n.language === "fr" && item.hasOwnProperty("titleFR")
                  ? item.titleFR
                  : item.title}
              </p>
              <p
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  fontSize: "14px",
                  color: "#b9b2b6",
                }}
              >
                {item.selectedVariant.name ||
                  item.selectedVariant.unit + " " + item.selectedVariant.value}
              </p>

              {renderSelectedExtras()}
            </Box>

            <Box
              display="flex"
              width="40%"
              justifyContent="flex-end"
              alignItems="flex-start"
              mr={"-8px"}
            >
              <Box
                display="flex"
                alignItems="flex-end"
                flexDirection="column"
                pr={1}
              >
                <p className="offscreen-menu__content__section-heading fw-400--family mt-0 mb-0">
                  {i18n.language === "fr"
                    ? currency(
                        calculateTotalItemPrice(item, props.discountPrice),
                        {
                          pattern: "# !",
                          separator: " ",
                          decimal: ",",
                        }
                      ).format()
                    : currency(
                        calculateTotalItemPrice(item, props.discountPrice)
                      ).format()}

                  {props.discountPrice && (
                    <span className="discount-price">
                      {i18n.language === "fr"
                        ? currency(calculateTotalItemPrice(item), {
                            pattern: "# !",
                            separator: " ",
                            decimal: ",",
                          }).format()
                        : currency(calculateTotalItemPrice(item)).format()}
                    </span>
                  )}
                </p>
                <p
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    fontSize: "14px",
                    color: "#b9b2b6",
                  }}
                >
                  {t("qty")}: {item.quantity}
                </p>
              </Box>
              {editable ? (
                <Tooltip title={t("edit") || " "}>
                  <IconButton style={{ position: "relative", top: "8px" }}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                ""
              )}
            </Box>
          </Box>
        </Box>

        {/* <Alert
          style={{ width: '100%' }}
          //@ts-ignore
          severity="success"
          icon={<LocalOfferIcon />}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Typography>Promotion: 2 for $15 </Typography>
            <Button>Apply</Button>
          </Box>
        </Alert> */}
      </Box>
      {/* <Divider style={{ width: '100%', margin: '16px 24px 0' }} /> */}
    </>
  );
}
