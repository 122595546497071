import currency from 'currency.js';

export default function (
  cartItems: Array<object>,
  itemId: string,
  extraId: string
) {
  const itemIndex = cartItems.findIndex((e: any) => e.id === itemId);

  if (itemIndex == -1) {
    return 0;
  }

  //@ts-ignore
  const extraIndex = cartItems[itemIndex].selectedExtras.findIndex(
    (e: any) => e._id == extraId
  );

  if (extraIndex == -1) {
    return 0;
  }

  return currency(
    //@ts-ignore
    cartItems[itemIndex].selectedExtras[extraIndex].quantity *
      //@ts-ignore
      cartItems[itemIndex].selectedExtras[extraIndex].price
  ).format();
}
