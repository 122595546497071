import React from 'react';

import './TestimonialSlide.scss';

export default function (props: any) {
  return (
    <div className={`TestimonialSlide`}>
      {props.image && props.imageAlt && (
        <div className={`testimonialImage`}>
          <img alt={props.imageAlt} src={props.image} />
        </div>
      )}

      <p className={`testimonial`}>{props.testimonial}</p>

      <p className={`testimonialName`}>{props.testimonialName}</p>
      <p className={`testimonialDesignation`}>{props.testimonialDesignation}</p>
    </div>
  );
}
