import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      Account: 'Account',
      'Email address': 'Email address',
      Password: 'Password',
      Signin: 'Sign in',
      Signin2: 'Sign in',
      'Forgot password': 'Forgot password',
      'Not a member yet?': 'Not a member yet?',
      'Sign up': 'Sign up',
      'Continue with Facebook': 'Continue with Facebook',
      cart: 'Cart',
      'Sign up by email2': 'Sign up by email',
      Profile: 'Profile',
      addProfile: 'Add profile',
      Billing: 'Billing',
      'Sign out': 'Sign out',

      cartEmpty: 'Your cart is empty',
      qty: 'Qty',
      edit: 'Edit',
      summary: 'Summary',

      //address
      addAddress: 'Add address',
      organization: 'Organization',
      editAddress: 'Edit address',
      typeOfAddress: 'Type',
      home: 'Home',
      apartment: 'Apartment',
      work: 'Work',
      address: 'Address',
      deliveryAddress: 'Delivery address',
      deliveryOptions: 'Delivery options',
      leaveAtDoor: 'Leave at door',
      meetAtDoor: 'Meet at door',
      meetOutside: 'Meet outside',
      covidMessage:
        'We’re only offering contactless delivery as precautionary health measure for our local couriers.',

      //checkout
      contact: 'Contact',
      addDeliveryAddress: 'Add delivery address',
      Payment: 'Payment',
      selectDeliveryDate: 'Select delivery date',
      products: 'Products',
      grocery: 'Grocery',
      preparedMeals: 'Prepared meals',
      deliveryFee: 'Delivery fee',
      subTotal: 'Subtotal',
      checkout: 'Checkout',
      taxDisclaimer:
        'Tax for the full value and delivery fees will be calculated at checkout. By clicking checkout you consent to our',
      terms: 'Terms',
      free: 'Free',

      privacyPolicy: 'Privacy policy',
    },
  },
  fr: {
    translation: {
      Account: 'Se connecter',
      'Email address': 'Email',
      Password: 'Mot de passe',
      Signin: 'Se connecter ',
      'Forgot password': "J'ai oublié mon mot de passe",
      'Not a member yet?': 'Pas encore inscrit ?',
      'Already a member?': 'Déjà inscrit ?',
      'Sign up': 'Créer mon compte',
      'Continue with Facebook': 'Continuer avec Facebook',
      Signin2: 'Connexion',
      addProfile: 'Ajouter un profil',

      cart: 'Panier',
      'Sign up by email': 'Se connecter par email',
      'Continue checkout as guest': 'Continuer sans ouvrir de session',
      'Guest checkout': 'Continuer comme invité',
      'By clicking sign up you consent to our':
        'En créant votre compte, vous acceptez l’intégralité de notre',
      'Privacy Policy': 'Politique de confidentialité',
      'Proceed and create a Vittle account later':
        'Poursuivez et créez un compte Vittle plus tard',
      'Sign up by email2': 'Créer mon compte par email',
      Contact: 'Coordonnées',
      'First name': 'Prénom',
      'Last name': 'Nom',
      Phone: 'Téléphone',
      Apply: 'Appliquer',
      //Signed in
      Profile: 'Profil',
      Primary: 'Primaire',
      Delivery: 'Livraison',
      Add: 'Ajouter',
      Billing: 'Billing',
      'Expires on': 'Expire en',
      'Sign out': 'Déconnexion',

      cartEmpty: 'Votre panier est vide',
      qty: 'Qté',
      edit: 'Modifier',
      summary: 'Commande',

      //address
      addAddress: "Ajouter l'adresse",
      organization: 'Organisation',
      editAddress: "Modifier l'adresse",
      typeOfAddress: "Type d'adresse",
      home: 'Maison',
      apartment: 'Appartement',
      work: 'Bureau',
      address: 'Adresse',
      deliveryAddress: 'Adresse de livraison',
      deliveryOptions: 'Options de livraison',
      leaveAtDoor: 'Laisser à la porte',
      meetAtDoor: 'Rendez-vous à la porte',
      meetOutside: "Rendez-vous à l'extérieur",
      free: 'Gratuit',

      //checkout
      contact: 'Coordonnées',
      addDeliveryAddress: 'Ajouter une adresse de livraison',
      Payment: 'Paiement',
      selectDeliveryDate: 'Sélectionnez date de livraison',
      products: 'Produits',
      grocery: 'Épicerie',
      preparedMeals: 'Prepared meals',
      deliveryFee: 'Frais de livraison',
      subTotal: 'Sous-total',
      checkout: 'Commander',
      taxDisclaimer:
        'La taxe pour la valeur totale et les frais de livraison seront calculés au moment de la commande. En cliquant sur commander vous acceptez nos',
      terms: 'Conditions',
      covidMessage:
        'À cause de COVID-19 et par mesure de précaution pour nos livreurs nous offrons que la livraison sans contact.',

      confirmOrderDisclaimer:
        'By clicking confirm order you agree to our Terms and consent to our.',
      privacyPolicy: 'Politique de confidentialité',
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
