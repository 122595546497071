import React from 'react';
import feathers from '../../feathers';
import { useAppDispatch } from '../../hooks';
import { loginSuccess } from '../../slices/authReducer';
import { getSubscription } from '../../slices/subscriptionReducer';

export default function OAuthSuccess(props: any) {
  const dispatch = useAppDispatch();

  async function getAccessToken() {
    const token = await feathers.authentication.getFromLocation(
      window.location
    );
    return token;
  }

  async function setAccessToken(accessToken: string) {
    await feathers.authentication.setAccessToken(accessToken);
  }

  React.useEffect(() => {
    feathers.authentication
      .getFromLocation(window.location)
      .then((res: any) => {
        feathers.authentication
          .setAccessToken(res)
          .then((res: any) => {
            feathers
              .reAuthenticate()
              .then((res: any) => {
                console.log(res);

                dispatch(loginSuccess(res));
                dispatch(getSubscription(res.user.subscriptionId));

                props.history.push('/');
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err: any) => {
            console.log(err);
          });
      })
      .catch((err) => console.log(err));
  }, []);

  return null;
}
