import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import SubscriptionService from "../api/subscription";
import UserService from "../api/user";
import OrderService from "../api/orders";
import SubscriptionStatusesService from "../api/subscription-statuses";
import { AppThunk } from "../store";
import { setCurrentTab } from "./authNavReducer";
import { open } from "./snackbarReducer";
import { AddItemToCartProps } from "./cartReducer";
import moment from "moment";
import { useState, useEffect } from "react";

const initialState = {
  subscription: {},
  paymentMethods: [],
  subscriptionError: false,
  subscriptionLoading: true,
  paymentMethodLoading: true,
  stripeCustomer: null,
  deleteCardLoading: false,
  pauseAWeekLoading: false,

  subscriptionStatuses: {
    data: [],
    total: 0,
  },
  subscriptionStatusesLoading: true,

  changeDeliveryPreferencesSubmitting: false,
  updateAutoRenewStatusSubmitting: false,
  changeDeliveryPreferencesError: null,

  subscriptionStatus: null,
  subscriptionStatusLoading: true,

  currentViewingSubscriptionWeekNumber: 2,

  currentlyViewingSubscriptionOrder: null,
  currentlyViewingSubscriptionOrderLoading: true,
  currentlyViewingSubscriptionOrderUpdating: false,
  viewingProductFromSubscripionWeek: false,

  currentViewingSubscriptionOrderItems: [],

  currentlyViewingSubscriptionOrderMealPlan: {
    name: "1 meal per day",
    pricePerMeal: 8,
    totalPrice: 40,
    minimumDishPerDay: 1,
    deliveryFee: 0,
    freeDelivery: true,
  },
  currentlyViewingSubscriptionOrderMealPlanName: "1 meal per day",
  currentOrderMealPlanOverridden: false,

  temporarySubscriptionOrderItem: null,

  subscriptionOrderItemProductToEditIndex: 0,
  subscriptionOrderItemProductToEdit: null,

  customMealPlan: [],
  planDay: null,
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    setSubscriptionLoading: (state, action) => {
      state.subscriptionLoading = action.payload;
    },
    setSubscriptionStatusesLoading: (state, action) => {
      state.subscriptionStatusesLoading = action.payload;
    },
    fetchSubscriptionStatusesSuccess: (state, action) => {
      state.subscriptionStatuses = action.payload;
      state.subscriptionStatusesLoading = false;
    },
    unloadSubscriptionStatuses: (state) => {
      state.subscriptionStatuses = {
        data: [],
        total: 0,
      };
      state.subscriptionStatusesLoading = true;
    },
    setSubscriptionStatusLoading: (state, action) => {
      state.subscriptionStatusLoading = action.payload;
    },
    fetchSubscriptionStatusSuccess: (state, action) => {
      state.subscriptionStatus = action.payload;
      state.subscriptionStatusLoading = false;
    },
    unloadSubscriptionStatus: (state) => {
      state.subscriptionStatus = null;
      state.subscriptionStatusesLoading = true;
    },
    setViewingProductFromSubscripionWeek: (state, action) => {
      state.viewingProductFromSubscripionWeek = action.payload;
    },
    setCurrentlyViewingSubscriptionOrderLoading: (state, action) => {
      state.currentlyViewingSubscriptionOrderLoading = action.payload;
    },
    setCurrentlyViewingSubscriptionOrderUpdating: (state, action) => {
      state.currentlyViewingSubscriptionOrderUpdating = action.payload;
    },
    currentlyViewingSubscriptionOrderUpdateSuccess: (state, action) => {
      state.currentlyViewingSubscriptionOrder = action.payload;
    },
    fetchCurrentlyViewingSubscriptionOrderSuccess: (state, action) => {
      state.currentlyViewingSubscriptionOrder = action.payload;

      if (action.payload !== null) {
        state.currentViewingSubscriptionOrderItems = action.payload.lineItems;
      } else {
        state.currentViewingSubscriptionOrderItems = [];
      }

      state.currentlyViewingSubscriptionOrderLoading = false;
    },
    unloadCurrentlyViewingSubscriptionOrder: (state) => {
      state.currentlyViewingSubscriptionOrder = null;
      state.currentlyViewingSubscriptionOrderLoading = true;
    },
    setDeleteCardLoading: (state, action) => {
      state.deleteCardLoading = action.payload;
    },
    setPauseAWeekLoading: (state, action) => {
      state.pauseAWeekLoading = action.payload;
    },
    setCurrentViewingSubscriptionWeekNumber: (state, action) => {
      state.currentViewingSubscriptionWeekNumber = action.payload;
    },
    getSubscriptionSuccess: (state, action) => {
      state.subscription = action.payload;
      console.log("setMealPlan", action.payload);
      // state.customMealPlan = action.payload.mealPlan;
    },
    setPaymentMethodsLoading: (state, action) => {
      state.paymentMethodLoading = action.payload;
    },
    getPaymentMethodsSuccess: (state, action) => {
      state.paymentMethods = action.payload;
    },
    addPaymentMethodSuccess: (state, action) => {
      //@ts-ignore
      state.paymentMethods.push(action.payload);
    },
    getStripeCustomerSuccess: (state, action) => {
      state.stripeCustomer = action.payload;
    },
    deleteCardSuccess: (state, action) => {
      // state.paymentMethods = action.payload;

      if (state.paymentMethods.length === 0) {
        return;
      }

      const index = state.paymentMethods.findIndex(
        (e: any) => e.id === action.payload.id
      );

      if (index === -1) {
        return;
      }

      state.paymentMethods.splice(index, 1);
    },

    addItemToSubscriptionOrder: (
      state,
      action: PayloadAction<AddItemToCartProps>
    ) => {
      // state.cart.push(action.payload);
      //@ts-ignore
      state.currentViewingSubscriptionOrderItems.push(action.payload);
    },

    clearSubscriptionOrder: (state) => {
      state.currentViewingSubscriptionOrderItems = [];
    },

    removeItemFromSubscriptionOrder: (state, action) => {
      const index = action.payload;

      state.currentViewingSubscriptionOrderItems.splice(index, 1);
    },

    changeItemQuantityTo: (state, action) => {
      const index = action.payload.index;

      //@ts-ignore
      state.currentViewingSubscriptionOrderItems[index].quantity =
        action.payload.quantity;
    },

    increaseSubscriptionOrderItemQuantity: (state, action) => {
      const index = action.payload;

      //@ts-ignore
      state.currentViewingSubscriptionOrderItems[index].quantity =
        //@ts-ignore
        state.currentViewingSubscriptionOrderItems[index].quantity + 1;

      //@ts-ignore
      state.currentViewingSubscriptionOrderItems[index].total = //@ts-ignore
        state.currentViewingSubscriptionOrderItems[index].price * //@ts-ignore
        state.currentViewingSubscriptionOrderItems[index].quantity;
    },

    decreaseSubscriptionOrderItemQuantity: (state, action) => {
      const index = action.payload;

      //@ts-ignore
      if (state.currentViewingSubscriptionOrderItems[index].quantity === 1) {
        state.currentViewingSubscriptionOrderItems.splice(index, 1);
        return;
      }

      //@ts-ignore
      state.currentViewingSubscriptionOrderItems[index].quantity =
        //@ts-ignore
        state.currentViewingSubscriptionOrderItems[index].quantity - 1;

      //@ts-ignore
      state.currentViewingSubscriptionOrderItems[index].total =
        //@ts-ignore
        state.currentViewingSubscriptionOrderItems[index].price *
        //@ts-ignore
        state.currentViewingSubscriptionOrderItems[index].quantity;
    },

    addExtraToProduct: (state, action) => {
      const extra = action.payload;

      //@ts-ignore
      if (
        state.currentViewingSubscriptionOrderItems[
          state.subscriptionOrderItemProductToEditIndex
          //@ts-ignore
        ].selectedExtras.length > 0
      ) {
        const extraPresentIndex = state.currentViewingSubscriptionOrderItems[
          //@ts-ignore
          state.subscriptionOrderItemProductToEditIndex
          //@ts-ignore
        ].selectedExtras.findIndex((e: any) => e._id === extra._id);
        if (
          //@ts-ignore
          extraPresentIndex >= 0
        ) {
          state.currentViewingSubscriptionOrderItems[
            //@ts-ignore
            state.subscriptionOrderItemProductToEditIndex
            //@ts-ignore
          ].selectedExtras[extraPresentIndex].quantity =
            state.currentViewingSubscriptionOrderItems[
              //@ts-ignore
              state.subscriptionOrderItemProductToEditIndex
              //@ts-ignore
            ].selectedExtras[extraPresentIndex].quantity + 1;

          state.currentViewingSubscriptionOrderItems[
            //@ts-ignore
            state.subscriptionOrderItemProductToEditIndex
            //@ts-ignore
          ].selectedExtras[extraPresentIndex].total =
            state.currentViewingSubscriptionOrderItems[
              //@ts-ignore
              state.subscriptionOrderItemProductToEditIndex
              //@ts-ignore
            ].selectedExtras[extraPresentIndex].quantity *
            state.currentViewingSubscriptionOrderItems[
              //@ts-ignore
              state.subscriptionOrderItemProductToEditIndex
              //@ts-ignore
            ].selectedExtras[extraPresentIndex].price;

          return;
        }
      }

      state.currentViewingSubscriptionOrderItems[
        //@ts-ignore
        state.subscriptionOrderItemProductToEditIndex
        //@ts-ignore
      ].selectedExtras.push(extra);
    },

    removeExtraFromProduct: (state, action) => {
      const index = action.payload.extraId;

      if (action.payload.editSlider) {
        const selectedExtraIndex = state.currentViewingSubscriptionOrderItems[
          //@ts-ignore
          state.subscriptionOrderItemProductToEditIndex
          //@ts-ignore
        ].selectedExtras.findIndex((e: any) => e._id === index);

        if (
          selectedExtraIndex !== -1 &&
          state.currentViewingSubscriptionOrderItems.length > 0
        ) {
          if (
            //@ts-ignore
            state.currentViewingSubscriptionOrderItems[
              state.subscriptionOrderItemProductToEditIndex
              //@ts-ignore
            ].selectedExtras[selectedExtraIndex].quantity === 1
          ) {
            //@ts-ignore
            state.currentViewingSubscriptionOrderItems[
              state.subscriptionOrderItemProductToEditIndex
              //@ts-ignore
            ].selectedExtras.splice(selectedExtraIndex, 1);
          } else {
            //@ts-ignore
            state.currentViewingSubscriptionOrderItems[
              state.subscriptionOrderItemProductToEditIndex
              //@ts-ignore
            ].selectedExtras[selectedExtraIndex].quantity =
              //@ts-ignore
              state.currentViewingSubscriptionOrderItems[
                state.subscriptionOrderItemProductToEditIndex
                //@ts-ignore
              ].selectedExtras[selectedExtraIndex].quantity - 1;
            //@ts-ignore

            state.currentViewingSubscriptionOrderItems[
              state.subscriptionOrderItemProductToEditIndex
              //@ts-ignore
            ].selectedExtras[selectedExtraIndex].total =
              //@ts-ignore
              state.currentViewingSubscriptionOrderItems[
                state.subscriptionOrderItemProductToEditIndex
                //@ts-ignore
              ].selectedExtras[selectedExtraIndex].price *
              //@ts-ignore
              state.currentViewingSubscriptionOrderItems[
                state.subscriptionOrderItemProductToEditIndex
                //@ts-ignore
              ].selectedExtras[selectedExtraIndex].quantity;
          }
        }
      }
    },

    setSubscriptionProductToEdit: (state, action) => {
      state.subscriptionOrderItemProductToEdit = action.payload;
      state.subscriptionOrderItemProductToEditIndex =
        state.currentViewingSubscriptionOrderItems.findIndex(
          (e: any) => e.id === action.payload.id
        );
      console.log("HERE");
      console.log(
        state.currentViewingSubscriptionOrderItems.findIndex(
          (e: any) => e.id === action.payload.id
        )
      );
    },

    setSubscriptionProductToEditIndex: (state, action) => {
      state.subscriptionOrderItemProductToEditIndex = action.payload;
    },

    resetSubscriptionOrderItems: (state) => {
      state.currentViewingSubscriptionOrderItems = [];
      state.subscriptionOrderItemProductToEdit = null;
      state.subscriptionOrderItemProductToEditIndex = 0;
    },

    setTemporaryCartItem: (state, action) => {
      state.temporarySubscriptionOrderItem = action.payload;
    },

    setSelectedMealPlan: (state, action) => {
      console.log("Action", action.payload);
      state.currentlyViewingSubscriptionOrderMealPlan = action.payload;
      state.currentlyViewingSubscriptionOrderMealPlanName = action.payload.name;
    },

    changeDeliveryPreferencesSubmitting: (state, action) => {
      state.changeDeliveryPreferencesSubmitting = action.payload;
    },

    setUpdateAutoRenewStatusSubmitting: (state, action) => {
      state.updateAutoRenewStatusSubmitting = action.payload;
    },

    setPlanDay: (state, action) => {
      state.planDay = action.payload;
    },

    addCustomMeal: (state, action) => {
      state.customMealPlan = action.payload;
    },
  },
});

export const {
  setSubscriptionLoading,

  setSubscriptionStatusesLoading,
  fetchSubscriptionStatusesSuccess,
  unloadSubscriptionStatuses,

  setSubscriptionStatusLoading,
  fetchSubscriptionStatusSuccess,
  unloadSubscriptionStatus,

  setSubscriptionProductToEditIndex,
  setSubscriptionProductToEdit,
  setViewingProductFromSubscripionWeek,
  setCurrentlyViewingSubscriptionOrderLoading,
  setCurrentlyViewingSubscriptionOrderUpdating,
  currentlyViewingSubscriptionOrderUpdateSuccess,
  fetchCurrentlyViewingSubscriptionOrderSuccess,
  unloadCurrentlyViewingSubscriptionOrder,
  setSelectedMealPlan,

  changeDeliveryPreferencesSubmitting,
  setUpdateAutoRenewStatusSubmitting,

  addItemToSubscriptionOrder,
  increaseSubscriptionOrderItemQuantity,
  decreaseSubscriptionOrderItemQuantity,
  removeItemFromSubscriptionOrder,
  clearSubscriptionOrder,

  setPaymentMethodsLoading,
  setPauseAWeekLoading,
  setCurrentViewingSubscriptionWeekNumber,
  getSubscriptionSuccess,
  addPaymentMethodSuccess,
  getPaymentMethodsSuccess,
  getStripeCustomerSuccess,
  setDeleteCardLoading,
  deleteCardSuccess,

  setPlanDay,
  addCustomMeal,
} = subscriptionSlice.actions;

const stripeP = require("stripe")("sk_test_z5R5jzFu07WDOnzuvzgTLyqD");

export default subscriptionSlice.reducer;

export const getSubscription =
  (customerId: string): AppThunk =>
  async (dispatch) => {
    dispatch(setSubscriptionLoading(true));

    try {
      const sub = await SubscriptionService.get(customerId);
      console.log("Subscription", sub);
      dispatch(getSubscriptionSuccess(sub));

      if (sub.paymentMethod === "card" && sub.stripe_customer_id) {
        dispatch(setPaymentMethodsLoading(true));

        try {
          // const paymentMethods = await SubscriptionService.getPaymentMethods(
          //   sub.stripe_customer_id
          // );
          let payList: any[] = [];
          console.log("Payment", sub.stripePaymentMethods);
          sub.stripePaymentMethods.map(async (method: any) => {
            console.log("aaaaaa", method);
            const paymentMethods = await stripeP.paymentMethods.retrieve(
              method
            );
            console.log("hhh", payList, paymentMethods);
            // setList((res) => [...res, paymentMethods]);
            // payList.push(paymentMethods);
          });
          console.log("list", payList);
          // if (sub.stripePaymentMethods[0]) {
          //   const paymentMethods = await stripeP.paymentMethods.retrieve(
          //     sub.stripePaymentMethods[0]
          //   );
          // }
          dispatch(getPaymentMethodsSuccess(payList));
          // console.log(customer);
          // @ts-ignore
          // dispatch(getStripeCustomerSuccess(customer));
        } catch (error) {
          console.log(error);
        } finally {
          dispatch(setPaymentMethodsLoading(false));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setSubscriptionLoading(false));
    }
  };

export const deleteCard =
  (paymentMethodId: string): AppThunk =>
  async (dispatch) => {
    dispatch(setDeleteCardLoading(true));

    try {
      const sub = await SubscriptionService.deletePaymentMethod(
        paymentMethodId
      );
      dispatch(deleteCardSuccess(sub));

      dispatch(setCurrentTab("account"));
      dispatch(
        open({
          message: "Billing method deleted successfully",
          severity: "success",
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        open({
          message:
            error.message || "There was a problem deleting your payment method",
        })
      );
    } finally {
      dispatch(setDeleteCardLoading(false));
    }
  };

export const updateExistingOrder =
  (id: string, data: any, successFunc: Function): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setCurrentlyViewingSubscriptionOrderUpdating(true));
      const order = await OrderService.patch(id, data);

      dispatch(currentlyViewingSubscriptionOrderUpdateSuccess(order));
      dispatch(setCurrentlyViewingSubscriptionOrderUpdating(false));
      successFunc();
    } catch (error) {
      console.log(error);
      dispatch(
        open({
          message: error.message || error,
          severity: "error",
        })
      );
      dispatch(setCurrentlyViewingSubscriptionOrderUpdating(false));
    }
  };

export const pauseAWeek =
  (selectedWeek: number): AppThunk =>
  async (dispatch) => {
    dispatch(setPauseAWeekLoading(true));

    try {
      const sub = await SubscriptionStatusesService.pauseAWeek(selectedWeek);

      console.log(sub);

      dispatch(
        open({
          message: `Meal plan paused for the week of  ${moment()
            .add(selectedWeek, "w")
            .startOf("isoWeek")
            .format("MMMM D")}-${
            moment()
              .add(selectedWeek, "w")
              .startOf("isoWeek")
              .add(5, "d")
              .format("MMMM") !==
            moment().add(selectedWeek, "w").startOf("isoWeek").format("MMMM")
              ? moment()
                  .add(selectedWeek, "w")
                  .startOf("isoWeek")
                  .add(5, "d")
                  .format("MMMM D")
              : moment()
                  .add(selectedWeek, "w")
                  .startOf("isoWeek")
                  .add(5, "d")
                  .format("D")
          }`,
          severity: "success",
        })
      );

      dispatch(setCurrentTab("profile-subscriptions"));
    } catch (error) {
      console.log(error);
      dispatch(
        open({
          message: error.message || "There was a problem pausing this week.",
        })
      );
      dispatch(setPauseAWeekLoading(false));
    }
  };

export const fetchSubscriptionStatuses =
  (query: object | undefined = undefined): AppThunk =>
  async (dispatch) => {
    dispatch(setSubscriptionStatusesLoading(true));

    try {
      console.log("checkpoint1", query);
      const sub = await SubscriptionStatusesService.find(query);

      console.log("subscript nstatus *****************************", sub);

      dispatch(fetchSubscriptionStatusesSuccess(sub));
    } catch (error) {
      // console.log(error);
      // dispatch(
      //   open({
      //     message: error.message || 'There was a problem pausing this week.',
      //   })
      // );
      dispatch(setSubscriptionStatusesLoading(false));
    }
  };

export const fetchSubscriptionStatus =
  (query: object | undefined = undefined): AppThunk =>
  async (dispatch) => {
    dispatch(setSubscriptionStatusLoading(true));

    try {
      console.log("checkpoint 2");
      const status = await SubscriptionStatusesService.find(query);

      console.log(status);

      if (status.total !== 0) {
        dispatch(fetchSubscriptionStatusSuccess(status.data[0]));
      } else {
        dispatch(fetchSubscriptionStatusSuccess(null));
      }
    } catch (error) {
      console.log(error);
      dispatch(
        open({
          message: error.message || "There was a problem pausing this week.",
        })
      );
      dispatch(setSubscriptionStatusLoading(false));
    }
  };

export const fetchSubscriptionOrder =
  (query: object | undefined = undefined): AppThunk =>
  async (dispatch) => {
    try {
      const order = await OrderService.find(query);
      console.log("In subscription order");

      console.log(order);

      if (order.total !== 0) {
        dispatch(fetchCurrentlyViewingSubscriptionOrderSuccess(order.data[0]));
      } else {
        dispatch(fetchCurrentlyViewingSubscriptionOrderSuccess(null));
      }
    } catch (error) {
      console.log(error);
      dispatch(setCurrentlyViewingSubscriptionOrderLoading(false));
    }
  };

export const updateDeliveryPreferences =
  (
    id: string,
    deliveryTime: string,
    deliveryType: string,
    setSubmitting: Function,
    successAction: Function | null | undefined = undefined
  ): AppThunk =>
  async (dispatch) => {
    console.log("Calling change password");
    try {
      const sub = await UserService.updateDeliveryPreferences(
        id,
        deliveryTime,
        deliveryType
      );
      // dispatch(changeLanguageSuccess(language));

      dispatch(getSubscriptionSuccess(sub));
      setSubmitting(false);
      dispatch(changeDeliveryPreferencesSubmitting(false));

      if (successAction) {
        successAction();
      }
    } catch (error) {
      console.log(error);
      setSubmitting(false);
      dispatch(changeDeliveryPreferencesSubmitting(false));
    }
  };

export const updateAutoRenew =
  (
    id: string,
    autoRenew: boolean,
    successAction: Function | null | undefined = undefined
  ): AppThunk =>
  async (dispatch) => {
    console.log("Calling updateAutoRenew");
    try {
      dispatch(setUpdateAutoRenewStatusSubmitting(true));

      const sub = await SubscriptionService.patch(id, {
        autoRenew,
      });

      dispatch(getSubscriptionSuccess(sub));
      dispatch(setUpdateAutoRenewStatusSubmitting(false));

      if (successAction) {
        successAction();
      }
    } catch (error) {
      console.log(error);
      dispatch(setUpdateAutoRenewStatusSubmitting(false));
    }
  };

export const updatePlan =
  (id: string, planName: string, plan: any): AppThunk =>
  async (dispatch) => {
    try {
      const data = await SubscriptionService.updatePlan(id, planName, plan);
      dispatch(getSubscriptionSuccess(data));
    } catch (error) {}
  };

export const makeMealPlan =
  (id: string, plan: any): AppThunk =>
  async (dispatch) => {
    try {
      await SubscriptionService.savePlan(id, plan);
    } catch (error) {}
  };
