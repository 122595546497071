import React from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Checkbox,
  Chip,
} from '@mui/material';
//@ts-ignore
import { Carousel } from 'react-responsive-carousel';
import { useDispatch } from 'react-redux';

import Helmet from 'react-helmet';
import LinkWithArrow from '../../components/LinkWithArrow/LinkWithArrow';
import CTAButtonWithHeading from '../../components/CTAButtonWithHeading/CTAButtonWithHeading';
import ImageWithTextBlock from '../../components/ImageWithTextBlock/ImageWithTextBlock';
import MenuItem from '../../components/MenuItem/MenuItem';
import TestimonialSlide from '../../components/TestimonialSlide/TestimonialSlide';

import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';

import './Slider.scss';
import './Catering.scss';

export default function Catering() {
  const dispatch = useDispatch();
  return (
    <>
      <Helmet title="Catering Us" />
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={9} md={9}>
            <Box
              p={8}
              display="flex"
              alignItems="Center"
              flexDirection="column"
            >
              <h1 className="heading-serif fw-400 heading-large text-center mt-0">
                Catering full-service pop-up dining experiences for social,
                corporate events, and weddings.
              </h1>

              <h2
                className="heading-sans-serif text-center"
                style={{ margin: 0, paddingBottom: '50px' }}
              >
                Sed egestas, ante sed mattis placerat, nulla felis ultricies
                ligula, eu vehicula leo ex vitae odio.
              </h2>

              <PrimaryButton
                style={{
                  fontSize: '21px',
                }}
                onClick={() => {
                  //   dispatch(setCurrentTabNonAuth('signup-options'));
                  //   toggleSignUpMenu();
                }}
              >
                Request a quote
                <span className="arrow" style={{ marginLeft: '8px' }}>
                  {'→'}
                </span>
              </PrimaryButton>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <div className="container container-wide">
        <Grid container>
          <div className="col">
            <img
              src="https://via.placeholder.com/1440x800"
              alt="Test"
              className="img-responsive"
            />
          </div>
        </Grid>
      </div>

      <Container>
        <section className={`sectionFeaturedClients`}>
          <Grid container>
            <div className="col-md-12">
              <h4 className="heading-serif heading-2x">FEATURED CLIENTS</h4>
            </div>
          </Grid>

          <Grid container>
            <div className="col-md-12">
              <div className={`featuredClients`}>
                <img src="https://via.placeholder.com/150x75" />
                <img src="https://via.placeholder.com/150x75" />
                <img src="https://via.placeholder.com/150x75" />
                <img src="https://via.placeholder.com/150x75" />
                <img src="https://via.placeholder.com/150x75" />
              </div>
            </div>
          </Grid>
        </section>
      </Container>

      <section className={`sectionChefs`}>
        <div className="container d-flex align-items-center">
          <Grid container>
            <div className="col col-md-10 col-lg-10 col-xs-12 col-md-offset-1 col-lg-offset-1">
              <h1 className="section-heading text-center mt-0 section-heading--no-margin-top">
                Skilled chefs, dedicated wait staff, and accessibility on a
                personal level.
              </h1>
            </div>
          </Grid>

          <Grid container>
            <div className="col col-md-6 col-lg-6 col-xs-12 col-md-offset-6 col-lg-offset-6 chef-paragraph">
              <p
                className="subheading color-secondary"
                style={{ paddingLeft: '16%', marginBottom: '100px' }}
              >
                Whether in your home or at a venue of your choice, our team can
                handle any event from a private dinner party for 20 or a black
                tie gala for 300. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Curabitur id enim sit amet ante dapibus
                facilisis sit amet et arcu. Phasellus fringilla lectus lacus, a
                porta purus hendrerit quis. Morbi mollis tortor lorem, nec
                pretium nisi aliquam at.
              </p>
              <div style={{ marginTop: '15px' }}>
                <LinkWithArrow
                  to="/learn-more"
                  text="Learn more"
                  label="Learn more"
                />
              </div>
            </div>
          </Grid>
        </div>

        <div className={`chefImages`}>
          <div className="container container-wide">
            <div
              className="row"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div className="col-md-5" style={{ marginTop: '-50%' }}>
                <img
                  src="https://via.placeholder.com/606x706"
                  className="img-responsive chef-image-1"
                />
              </div>
              <div className="col-md-5">
                <img
                  src="https://via.placeholder.com/606x706"
                  className="img-responsive chef-image-2"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={`sectionMenu`}>
        <Container>
          <Grid container style={{ marginBottom: '75px' }}>
            <div className="col col-md-6 col-lg-6 col-xs-12">
              <h1 className="heading-serif section-heading mt-0">
                Catering Menu
              </h1>
            </div>
            <div className="col col-md-6 col-lg-6 col-xs-12">
              <h2 className="heading-sans-serif">
                We strive to use only regional, wholesome ingredients that are
                seasonally inspired. Our chefs ensure each menu is perfectly
                tailored to accommodate your event and culinary needs.
              </h2>
            </div>
          </Grid>
        </Container>

        <Container>
          <Grid container spacing={4}>
            <MenuItem
              image="https://via.placeholder.com/345x345/f3f3f3?t=+"
              title="Title"
              text="Lorem ipsum dolor sit amet, presecuti comprehensam eos ea"
              category="CATEGORY"
            />
            <MenuItem
              image="https://via.placeholder.com/345x345/f3f3f3?t=+"
              title="Title"
              text="Lorem ipsum dolor sit amet, presecuti comprehensam eos ea"
              category="CATEGORY"
            />
            <MenuItem
              image="https://via.placeholder.com/345x345/f3f3f3?t=+"
              title="Title"
              text="Lorem ipsum dolor sit amet, presecuti comprehensam eos ea"
              category="CATEGORY"
            />
            <MenuItem
              image="https://via.placeholder.com/345x345/f3f3f3?t=+"
              title="Title"
              text="Lorem ipsum dolor sit amet, presecuti comprehensam eos ea"
              category="CATEGORY"
            />
            <MenuItem
              image="https://via.placeholder.com/345x345/f3f3f3?t=+"
              title="Title"
              text="Lorem ipsum dolor sit amet, presecuti comprehensam eos ea"
              category="CATEGORY"
            />
            <MenuItem
              image="https://via.placeholder.com/345x345/f3f3f3?t=+"
              title="Title"
              text="Lorem ipsum dolor sit amet, presecuti comprehensam eos ea"
              category="CATEGORY"
            />
          </Grid>
        </Container>

        <Container>
          <div className="row text-center" style={{ marginTop: '50px' }}>
            <div className="col-md-12">
              <LinkWithArrow
                downArrow
                to="/see-more"
                text="See more"
                label="See more catering options"
              />
            </div>
          </div>
        </Container>
      </section>

      <section className={`testimonialContainer`}>
        <Container>
          <Grid container>
            <div className="col-md-8 col-md-offset-2 col-lg-8 col-lg-offset-2 col-xs-12">
              <Carousel
                showStatus={false}
                showThumbs={false}
                showArrows={false}
                transitionTime={0}
                infiniteLoop
                autoPlay
                dynamicHeight
                interval={9000}
                swipeable
              >
                <div>
                  <TestimonialSlide
                    testimonial="“Lorem ipsum dolor sit amet, persecuti comprehensam eos ea, viris alterum ei vix.
                    Altera verterem nec cu, dolor quaeque expetenda pri ad. Virtute meliore vel eu.”"
                    testimonialName="John Doe"
                    testimonialDesignation="Owner"
                  />
                </div>
                <div>
                  <TestimonialSlide
                    testimonial="“Lorem ipsum dolor sit amet, persecuti comprehensam eos ea, viris alterum ei vix.
                      Altera verterem nec cu, dolor quaeque expetenda pri ad. Virtute meliore vel eu.”"
                    testimonialName="Jane Doe"
                    testimonialDesignation="Founder"
                  />
                </div>
                <div>
                  <TestimonialSlide
                    testimonial="“Lorem ipsum dolor sit amet, persecuti comprehensam eos ea, viris alterum ei vix.
                    Altera verterem nec cu, dolor quaeque expetenda pri ad. Virtute meliore vel eu.”"
                    testimonialName="John Smithsonian"
                    testimonialDesignation="Owner"
                  />
                </div>
                <div>
                  <TestimonialSlide
                    testimonial="“Lorem ipsum dolor sit amet, persecuti comprehensam eos ea, viris alterum ei vix.
                    Altera verterem nec cu, dolor quaeque expetenda pri ad. Virtute meliore vel eu.”"
                    testimonialName="John Smithsonian"
                    testimonialDesignation="Owner"
                  />
                </div>
                <div>
                  <TestimonialSlide
                    testimonial="“Lorem ipsum dolor sit amet, persecuti comprehensam eos ea, viris alterum ei vix.
                    Altera verterem nec cu, dolor quaeque expetenda pri ad. Virtute meliore vel eu.”"
                    testimonialName="Jane Smith"
                    testimonialDesignation="Owner"
                  />
                </div>
              </Carousel>
            </div>
          </Grid>
        </Container>
      </section>

      <section>
        <Container>
          <Grid container style={{ marginTop: '75px' }}>
            <div className="col col-md-10 col-lg-10 col-xs-12 col-md-offset-1 col-lg-offset-1">
              <h1 className="section-heading text-center mt-0 section-heading--no-margin-top">
                Lorem ipsum dolor sit amet.
              </h1>
            </div>
          </Grid>
        </Container>

        <ImageWithTextBlock
          image="https://via.placeholder.com/535x420/f3f3f3"
          imageAlt="Dummy Image"
          heading="Social and corporate events"
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eget nulla vel ligula placerat auctor.
            Aenean molestie, risus eget congue interdum, leo arcu scelerisque velit, a convallis tortor magna fringilla nulla."
          link="/learn-more"
          linkText="Learn more"
          linkLabel="Lear more"
          rowReverse
        />

        <ImageWithTextBlock
          image="https://via.placeholder.com/535x420/f3f3f3"
          imageAlt="Dummy Image"
          heading="Weddings"
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eget nulla vel ligula placerat auctor.
            Aenean molestie, risus eget congue interdum, leo arcu scelerisque velit, a convallis tortor magna fringilla nulla."
          link="/learn-more"
          linkText="Learn more"
          linkLabel="Learn more"
        />
      </section>

      <CTAButtonWithHeading
        text="Lorem ipsum dolor sit amet, persecuti comprehensam eos."
        buttonText="Request quote ->"
      />
    </>
  );
}
