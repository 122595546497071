import { Button, styled, darken } from '@mui/material';

export default styled(Button)({
  backgroundColor: '#fff',
  fontSize: '1rem',
  fontFamily: "'Inter-SemiBold', sans-serif",
  boxShadow: 'none',
  borderRadius: 0,
  border: '2px solid #1877f2',
  color: '#1877f2',
  textTransform: 'none',
  '&:hover': {
    color: '#FFF',
    backgroundColor: darken('#1877f2', 0.2),
    borderColor: darken('#1877f2', 0.2),
    'img path': {
      fill: '#fff',
    },
  },
  padding: '12px 32px',
  transition: 'all .25s ease',
});
