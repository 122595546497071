/* eslint-disable import/no-anonymous-default-export */
import feathers from "../../feathers";

export default {
  find: (query: object | undefined = undefined) => {
    if (query) {
      return feathers.service("subscription-statuses").find({
        query: query,
      });
    }

    return feathers.service("subscription-statuses").find();
  },

  pauseAWeek: (selectedWeek: number) => {
    return feathers.service("subscription-statuses").create({
      selectedWeek,
      status: "paused",
    });
  },
};
