/* eslint-disable import/no-anonymous-default-export */
import feathers from "../../feathers";

export default {
  get: (id: string) => {
    return feathers.service("activities").get(id);
  },

  create: (activity: any) => {
    return feathers.service("activities").create(activity);
  },
};
