/* eslint-disable import/no-anonymous-default-export */
import feathers from "../../feathers";

export default {
  get: (id: string) => {
    return feathers.service("subscriptions").get(id);
  },

  patch: (id: string, data: any) => {
    return feathers.service("subscriptions").patch(id, data);
  },

  getPaymentMethods: (stripeCustomerId: String) => {
    return feathers.service("/stripe/payment-method").find({
      query: {
        customer: stripeCustomerId,
        type: "card",
      },
    });
  },

  getStripeCustomer: (stripeCustomerId: String) => {
    return feathers.service("/stripe/customer").get(stripeCustomerId);
  },

  deletePaymentMethod: (paymentMethodId: String) => {
    return feathers.service("/stripe/payment-method").remove(paymentMethodId);
  },

  updatePlan: (id: string, planName: string, plan: any) => {
    return feathers.service("subscriptions").patch(id, {
      // customerId: id,
      planName: planName,
      plan: plan,
    });
  },

  savePlan: (id: string, plan: any) => {
    return feathers.service("subscriptions").patch(id, {
      customerId: id,
      savePlan: plan,
    });
  },
};
