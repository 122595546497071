import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { Formik } from "formik";
import * as Yup from "yup";

import TextField from "../../components/TextField/TextField";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import FacebookButton from "../../components/FacebookButton/FacebookButton";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from "@mui/icons-material/Help";
import VisibilityIcon from "@mui/icons-material/Visibility";

import {
  Divider,
  Box,
  IconButton,
  Grid,
  Button,
  CircularProgress,
  Autocomplete,
} from "@mui/material";

import Profile from "./Profile";
import Address from "./Address";
import EditCartItem from "./EditCartItem";
import SelectDeliveryDate from "./SelectDeliveryDate";
import Checkout from "./Checkout";
import Cart from "./Cart";
import MealPlan from "./MealPlan";

import Receipt from "../GuestReceiptOffScreen/GuestReceipt";
import CancelOrder from "../GuestReceiptOffScreen/CancelGuestOrder";
import ReportAProblem from "../GuestReceiptOffScreen/ReportAProblemGuestOrder";

// import 'react-dates/lib/css/_datepicker.css';
import fetchFinalAddress from "../../api/canada-post/fetchFinalAddress";
import fetchPlaces from "../../api/canada-post/fetchPlaces";

import "./CartOffScreen.scss";
import { setCurrentCartTab } from "../../slices/authNavReducer";
import AccountNavOffScreen from "../AccountNavOffScreen/AccountNavOffScreen";
import ButtonLoader from "../ButtonLoader/ButtonLoader";

import "react-phone-input-2/lib/material.css";
import feathersClient from "../../feathers";
import { login } from "../../slices/authReducer";
import { registerUser } from "../../slices/userReducer";
import { useAppSelector, useAppDispatch } from "../../hooks";

export default function CartOffScreen(props: any) {
  const { t, i18n }: any = useTranslation();
  const dispatch = useAppDispatch();

  const currentTab = useAppSelector((state) => state.authNav.currentCartTab);

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [lastId, setLastId] = React.useState("");
  const [descriptiveAddress, setDescriptiveAddress] = React.useState({} as any);
  const [finalAddressId, setFinalAddressId] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [address, setAddress] = React.useState("");

  React.useEffect(() => {
    if (searchTerm.length > 0 || lastId.length > 0) {
      fetchPlaces(setOptions, searchTerm, lastId, setLoading, setOpen);
    }
  }, [searchTerm, lastId]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  //USER WHEN SIGNED IN
  // const user: any = useAppSelector((state) => state.auth.user);

  // const subscription: any = useAppSelector(
  //   (state) => state.subscription.subscription
  // );

  // const paymentMethods: any = useAppSelector(
  //   (state) => state.subscription.paymentMethods
  // );

  // const paymentMethodLoading: Boolean = useAppSelector(
  //   (state) => state.subscription.paymentMethodLoading
  // );

  // const subscriptionLoading: Boolean = useAppSelector(
  //   (state) => state.subscription.subscriptionLoading
  // );

  //AddressComplete

  const handleAutoCompleteSelected = (e: any, v: any) => {
    if (!v) {
      return;
    }

    if (!v.hasOwnProperty("Next")) {
      return;
    }

    if (v.Next === "Find") {
      console.log("Going Find");
      setLastId(v.Id);
    }

    if (v.Next === "Retrieve") {
      console.log("Going Retrieve");
      setLastId("");
      setSearchTerm("");
      setAddress(v.Text + " " + v.Description);
      setFinalAddressId(v.Id);
      fetchFinalAddress(v.Id, setDescriptiveAddress);
    }
  };

  const handleAutoCompleteBlur = () => {
    if (lastId.length > 0) {
      setLastId("");
      setSearchTerm("");
      setOpen(false);
    }
  };

  const renderAddress = (address: any) => {
    return (
      <>
        <p
          className="font-weight-500 font-weight-medium"
          style={{
            fontFamily: "Inter-Regular, sans-serif",
            margin: 0,
          }}
        >
          {address.organization.length !== "" && (
            <>
              {address.organization} <br />
            </>
          )}
          {address.Line1}, {address.Line2}
          <br />
          {address.City}, {address.Province} {address.PostalCode}
          {address.buzzer !== "" && (
            <>
              <span>
                <br />
                Buzzer: {address.buzzer}
              </span>
            </>
          )}
        </p>
        {/* {address.primary && (
          <p
            style={{
              marginTop: 0,
              marginBottom: 0,
              fontSize: '14px',
              color: '#b9b2b6',
            }}
          >
            Primary
          </p>
        )} */}
      </>
    );
  };

  const handleGoBackButtonClick = () => {
    if (currentTab === "cancel-order" || currentTab === "report-a-problem") {
      dispatch(setCurrentCartTab("receipt"));
    }

    if (
      currentTab === "contact" ||
      currentTab === "credit-card" ||
      currentTab === "edit-cart-item" ||
      currentTab === "checkout" ||
      currentTab === "meal-plan" ||
      currentTab === "signup-options"
    ) {
      dispatch(setCurrentCartTab("cart"));
    }

    if (currentTab === "signin" || currentTab === "signup-email") {
      dispatch(setCurrentCartTab("signup-options"));
    }

    if (currentTab === "forgot-password") {
      dispatch(setCurrentCartTab("signin"));
    }

    if (
      currentTab === "add-address" ||
      currentTab === "add-contact" ||
      currentTab === "select-delivery-date"
    ) {
      dispatch(setCurrentCartTab("checkout"));
    }
  };

  return (
    <div className="cart offscreen-menu">
      <header className="signup-menu__top-bar offscreen-menu__top-bar">
        <IconButton
          className="offscreen-menu__top-bar__back"
          onClick={handleGoBackButtonClick}
        >
          {currentTab !== "cart" && <ArrowBackIcon style={{ color: "#FFF" }} />}
        </IconButton>

        <h4 className="heading-sans-serif color-white offscreen-menu__top-bar__heading">
          {currentTab === "cart"
            ? t("cart")
            : currentTab === "add-contact"
            ? t("addProfile")
            : currentTab === "receipt"
            ? `Order`
            : currentTab === "cancel-order"
            ? `Cancel Order`
            : currentTab === "meal-plan"
            ? `Meal Plan`
            : currentTab === "report-a-problem"
            ? `Report a problem`
            : currentTab === "select-delivery-date"
            ? t("selectDeliveryDate")
            : currentTab === "add-address"
            ? t("addAddress")
            : currentTab === "credit-card"
            ? "Add credit card"
            : currentTab === "add-gift-card-or-discount-code"
            ? "add gift card or discount"
            : currentTab == "edit-cart-item"
            ? "Edit Product"
            : "Checkout"}
        </h4>

        <IconButton
          className="offscreen-menu__top-bar__close"
          onClick={() => {
            document.body.classList.toggle("cart--open");
          }}
        >
          <CloseIcon fill="#fff" />
        </IconButton>
      </header>
      <div className="offscreen-menu__content text-align-center">
        {currentTab === "add-contact" && <Profile />}

        {currentTab === "add-payment" && <></>}

        {currentTab === "add-address" && <Address />}

        {currentTab === "select-delivery-date" && <SelectDeliveryDate />}

        {currentTab === "checkout" && <Checkout />}

        {currentTab === "receipt" && <Receipt placedOrder />}
        {currentTab === "cancel-order" && <CancelOrder />}
        {currentTab === "report-a-problem" && <ReportAProblem />}

        {currentTab === "meal-plan" && <MealPlan />}

        {currentTab === "add-gift-card-or-discount-code" && (
          <div className="form-login">
            {/* <form onSubmit={handleSubmit}> */}

            <TextField
              color="secondary"
              label="Enter gift card or promo code"
              name="giftCardOrPromoCode"
              fullWidth
              // value={values.emailAddress}
              // onBlur={handleBlur}
              // onChange={handleChange}
              // error={Boolean(errors.emailAddress && touched.emailAddress)}
              // helperText={
              //   errors.emailAddress &&
              //   touched.emailAddress &&
              //   errors.emailAddress
              // }
            />

            <PrimaryButton
              onClick={() => dispatch(setCurrentCartTab("checkout"))}
              style={{ marginTop: "1em", width: "100%" }}
            >
              {t("Apply")}
            </PrimaryButton>
          </div>
        )}

        {currentTab === "cart" && <Cart />}

        {currentTab === "edit-cart-item" && <EditCartItem />}

        {currentTab === "signin" && (
          <Formik
            initialValues={{ emailAddress: "", password: "" }}
            validationSchema={Yup.object().shape({
              emailAddress: Yup.string()
                .email("Enter a valid email address")
                .required("Email address is required"),
              password: Yup.string().required("Password is required"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              console.log("Pushing");
              dispatch(
                login(
                  values.emailAddress,
                  values.password,
                  false,
                  () => {
                    dispatch(setCurrentCartTab("checkout"));
                  },
                  setSubmitting
                )
              );
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <h1 className="offscreen-menu__content__heading text-center heading-sans-serif">
                  {t("Signin")}
                </h1>

                <TextField
                  color="secondary"
                  label={t("Email address")}
                  name="emailAddress"
                  fullWidth
                  value={values.emailAddress}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(errors.emailAddress && touched.emailAddress)}
                  // helperText={
                  //   errors.emailAddress &&
                  //   touched.emailAddress &&
                  //   errors.emailAddress
                  // }
                />

                <TextField
                  color="secondary"
                  label={t("Password")}
                  name="password"
                  type={passwordVisible ? "text" : "password"}
                  fullWidth
                  value={values.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(errors.password && touched.password)}
                  style={{ paddingRight: "6px" }}
                  // helperText={
                  //   errors.password && touched.password && errors.password
                  // }
                  InputProps={{
                    endAdornment: passwordVisible ? (
                      <IconButton
                        style={{
                          position: "absolute",
                          right: 0,
                        }}
                        size="small"
                        onClick={() => setPasswordVisible(!passwordVisible)}
                      >
                        <VisibilityIcon
                          style={{ cursor: "pointer", color: "#b2b9b6" }}
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        style={{
                          position: "absolute",
                          right: 0,
                        }}
                        size="small"
                        onClick={() => setPasswordVisible(!passwordVisible)}
                      >
                        <VisibilityOffIcon
                          onClick={() => setPasswordVisible(!passwordVisible)}
                          style={{ cursor: "pointer", color: "#b2b9b6" }}
                        />
                      </IconButton>
                    ),
                  }}
                />

                <p
                  className="form-login__forgot-password"
                  onClick={() => dispatch(setCurrentCartTab("forgot-password"))}
                >
                  <a className="link color-secondary-2 link--to-black">
                    {t("Forgot password")}
                  </a>
                </p>

                <PrimaryButton
                  type="submit"
                  style={{ width: "100%", marginTop: "1em", fontSize: "1em" }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? <ButtonLoader /> : t("Signin")}
                </PrimaryButton>

                <p
                  className="text-center"
                  onClick={() => dispatch(setCurrentCartTab("signup-options"))}
                >
                  <span style={{ color: "#33474C" }}>
                    {t("Not a member yet?")}{" "}
                  </span>
                  <a
                    href="#"
                    // onClick={handleSignUpLink}
                    className="link color-primary text-center"
                  >
                    {t("Sign up")} <span className="arrow">{"-->"}</span>
                  </a>
                </p>

                <FacebookButton
                  href={`${
                    process.env.NODE_ENV === "production"
                      ? process.env.REACT_APP_PROD_API_URL
                      : process.env.REACT_APP_LOCAL_API_URL
                  }oauth/facebook`}
                  style={{ width: "100%", marginTop: "1em" }}
                >
                  <img
                    src={require("../../assets/facebook.png")}
                    style={{
                      width: "24px",
                      height: "24px",
                      marginRight: "1em",
                      fill: "#1877f2",
                    }}
                  />
                  {t("Continue with Facebook")}
                </FacebookButton>
              </form>
            )}
          </Formik>
        )}

        {currentTab === "forgot-password" && (
          <Formik
            initialValues={{ emailAddress: "" }}
            validationSchema={Yup.object().shape({
              emailAddress: Yup.string()
                .email("Enter a valid email address")
                .required("Email address is required"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              console.log("Pushing");
              console.log(values);
              feathersClient
                .service("authManagement")
                .create({
                  action: "sendResetPwd",
                  value: { email: values.emailAddress }, // {email}, {token: verifyToken}
                })
                .then((e: any) => {
                  console.log("success");
                  console.log(e);
                })
                .catch((err: any) => {
                  console.log(err);
                  // setVerifiedError('Could not verify this token');
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form className="form-login" onSubmit={handleSubmit}>
                <h1 className="offscreen-menu__content__heading text-center heading-sans-serif">
                  Forgot password
                </h1>
                <TextField
                  color="secondary"
                  label="Email address"
                  name="emailAddress"
                  fullWidth
                  value={values.emailAddress}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(errors.emailAddress && touched.emailAddress)}
                  // helperText={
                  //   errors.emailAddress &&
                  //   touched.emailAddress &&
                  //   errors.emailAddress
                  // }
                />
                <PrimaryButton
                  type="submit"
                  style={{ width: "100%", marginTop: "1em" }}
                >
                  Send
                </PrimaryButton>
              </form>
            )}
          </Formik>
        )}

        {currentTab === "signup-options" && (
          <div className="form-login">
            <h1 className="offscreen-menu__content__heading text-center heading-sans-serif">
              {t("Sign up")}
            </h1>
            <FacebookButton
              href={`${
                process.env.NODE_ENV === "production"
                  ? process.env.REACT_APP_PROD_API_URL
                  : process.env.REACT_APP_LOCAL_API_URL
              }oauth/facebook`}
              style={{ width: "100%", marginTop: "1em" }}
            >
              <img
                src={require("../../assets/facebook.png")}
                style={{
                  width: "24px",
                  height: "24px",
                  marginRight: "1em",
                  fill: "#1877f2",
                }}
              />
              {t("Continue with Facebook")}
            </FacebookButton>
            <PrimaryButton
              onClick={() => dispatch(setCurrentCartTab("signup-email"))}
              style={{ marginTop: "1em", width: "100%" }}
            >
              {t("Sign up by email")}
            </PrimaryButton>
            <p
              className="text-center"
              onClick={() => dispatch(setCurrentCartTab("signin"))}
            >
              <span style={{ color: "#33474C" }}>{t("Already a member?")}</span>
              <a
                href="#"
                // onClick={handleSignUpLink}
                className="link color-primary text-center"
              >
                {" "}
                {t("Signin")} <span className="arrow">{"-->"}</span>
              </a>
            </p>

            <Box mt={3}></Box>
            <Divider />
            <Box mt={2}></Box>

            <Button
              variant="contained"
              color="secondary"
              style={{
                boxShadow: "none",
                borderRadius: "0",
                marginTop: "8px",
                padding: "12px 32px",
                textTransform: "none",
                fontSize: "1em",
                fontFamily: "Inter-SemiBold, sans-serif",
                transition: "all .25s ease",
                background: "rgba(0, 0, 0, 0.12)",
                // border: '2px solid rgba(0, 0, 0, 0.12)',
                width: "100%",
                color: "#000",
              }}
              onClick={() => dispatch(setCurrentCartTab("checkout"))}
            >
              {t("Continue checkout as guest")}
            </Button>

            <p
              className="text-center color-secondary-2"
              style={{ margin: "40px 0 50px", fontSize: "14px" }}
            >
              {t("By clicking sign up you consent to our")}{" "}
              <Link
                style={{ textDecoration: "underline" }}
                className="link color-secondary-2 link--to-black"
                to="/privacy-policy"
              >
                {t("Privacy Policy")}
              </Link>
              .
            </p>
          </div>
        )}

        {currentTab === "signup-email" && (
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              emailAddress: "",
              password: "",
              address: "",
              phone: "",
              customerAcceptsMarketing: true,
              settings: {
                language: "EN",
              },
            }}
            validationSchema={Yup.object().shape({
              firstName: Yup.string().required("First name is required"),
              lastName: Yup.string().required("Last name is required"),

              emailAddress: Yup.string()
                .email("Enter a valid email address")
                .required("Email address is required"),
              phone: Yup.string().required("Phone number is required"),
              password: Yup.string().required("Password is required"),
              address: Yup.string().required("Address is required"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              console.log(values);

              let customer = {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.emailAddress,
                password: values.password,
                phone: values.phone,
                acceptsMarketing: values.customerAcceptsMarketing,
                notes: "",
                secondary: false,
                settings: {
                  language: values.settings.language,
                },
                secondaryAccounts: [],

                address: [
                  {
                    canadaPostId: finalAddressId,
                    addressType: "home",
                    Label: descriptiveAddress.Label,
                    SubBuilding: descriptiveAddress.SubBuilding,
                    BuildingNumber: descriptiveAddress.BuildingNumber,
                    BuildingName: descriptiveAddress.BuildingName,
                    Street: descriptiveAddress.Street,
                    City: descriptiveAddress.City,
                    Line1: descriptiveAddress.Line1,
                    Line2: descriptiveAddress.Line2,
                    Line3: descriptiveAddress.Line3,
                    Province: descriptiveAddress.Province,
                    PostalCode: descriptiveAddress.PostalCode,
                    Type: descriptiveAddress.Type || "",
                    primary: true,
                    buzzer: "",
                    notes: "",
                    deliveryOption: "leaveAtDoor",
                    organization: "",
                  },
                ],
              };

              dispatch(
                registerUser(
                  customer,
                  props.history,
                  () => {
                    dispatch(setCurrentCartTab("checkout"));
                  },
                  setSubmitting
                )
              );
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form className="form-login" onSubmit={handleSubmit}>
                <>
                  <h1 className="offscreen-menu__content__heading text-center heading-sans-serif">
                    {t("Sign up by email2")}
                  </h1>
                  <p className="offscreen-menu__content__section-heading">
                    {t("Contact")}
                  </p>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        color="secondary"
                        label={t("First name")}
                        name="firstName"
                        fullWidth
                        value={values.firstName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(errors.firstName && touched.firstName)}
                        // helperText={
                        //   errors.firstName &&
                        //   touched.firstName &&
                        //   errors.firstName
                        // }
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        color="secondary"
                        label={t("Last name")}
                        name="lastName"
                        fullWidth
                        value={values.lastName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(errors.lastName && touched.lastName)}
                        // helperText={
                        //   errors.lastName && touched.lastName && errors.lastName
                        // }
                      />
                    </Grid>
                  </Grid>
                  <TextField
                    color="secondary"
                    label={t("Email address")}
                    name="emailAddress"
                    fullWidth
                    value={values.emailAddress}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(errors.emailAddress && touched.emailAddress)}
                    // helperText={
                    //   errors.emailAddress &&
                    //   touched.emailAddress &&
                    //   errors.emailAddress
                    // }
                  />
                  <PhoneInput
                    value={values.phone}
                    country="ca"
                    onChange={(
                      v: any,
                      country: any,
                      e: any,
                      formattedValue: any
                    ) => {
                      console.log(v);
                      console.log(country);
                      console.log(e);
                      console.log(formattedValue);
                      setFieldValue("phone", formattedValue);
                    }}
                    onBlur={handleBlur}
                    inputProps={{
                      name: "phone",
                      onChange: handleChange,
                      onBlur: handleBlur,
                    }}
                    inputClass={"MuiInputBase-input MuiInput-input"}
                    containerClass={
                      "MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-colorSecondary MuiInput-colorSecondary MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl"
                    }
                  />
                  <Autocomplete
                    freeSolo
                    style={{ width: "100%" }}
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    noOptionsText="No address found"
                    getOptionLabel={(option) =>
                      //@ts-ignore
                      option.Text + " " + option.Description
                    }
                    onChange={(e: any, v: any) => {
                      handleAutoCompleteSelected(e, v);
                      handleChange(e);
                    }}
                    options={options}
                    loading={loading}
                    onBlur={(e: any) => {
                      handleAutoCompleteBlur();
                      handleBlur(e);
                    }}
                    clearOnEscape={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={errors.address && touched.address ? true : false}
                        color="secondary"
                        fullWidth
                        margin="normal"
                        label="Delivery address"
                        name="address"
                        variant="standard"
                        onChange={(e: any) => {
                          setSearchTerm(e.target.value);
                          handleChange(e);
                        }}
                        value={values.address}
                        // helperText={
                        //   errors.deliveryAddress &&
                        //   touched.deliveryAddress &&
                        //   errors.deliveryAddress
                        // }
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {/* {!loading && addressCorrect ? <DoneIcon /> : null} */}

                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                  <img
                    style={{ marginTop: "-5px" }}
                    width="154"
                    height="21"
                    src={
                      i18n.language === "fr"
                        ? require("../../assets/address-complete-fr.png")
                        : require("../../assets/address-complete-en.png")
                    }
                  />
                  <Box mt={5} />
                  <p>Password</p>
                  <TextField
                    color="secondary"
                    label="Password"
                    name="password"
                    type={passwordVisible ? "text" : "password"}
                    fullWidth
                    value={values.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(errors.password && touched.password)}
                    style={{
                      paddingRight: "6px",
                    }}
                    // helperText={
                    //   errors.password && touched.password && errors.password
                    // }
                    InputProps={{
                      endAdornment: passwordVisible ? (
                        <IconButton
                          style={{
                            position: "absolute",
                            right: 0,
                          }}
                          size="small"
                          onClick={() => setPasswordVisible(!passwordVisible)}
                        >
                          <VisibilityIcon
                            style={{ cursor: "pointer", color: "#b2b9b6" }}
                          />
                        </IconButton>
                      ) : (
                        <IconButton
                          style={{
                            position: "absolute",
                            right: 0,
                          }}
                          size="small"
                          onClick={() => setPasswordVisible(!passwordVisible)}
                        >
                          <VisibilityOffIcon
                            onClick={() => setPasswordVisible(!passwordVisible)}
                            style={{ cursor: "pointer", color: "#b2b9b6" }}
                          />
                        </IconButton>
                      ),
                    }}
                  />
                  <PrimaryButton
                    style={{ width: "100%", marginTop: "1em" }}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? <ButtonLoader /> : "Sign up"}
                  </PrimaryButton>
                  <p
                    className="text-center color-secondary-2"
                    style={{ marginTop: "40px", fontSize: "14px" }}
                  >
                    By clicking sign up you consent to our{" "}
                    <Link
                      style={{ textDecoration: "underline" }}
                      className="link color-secondary-2 link--to-black"
                      to="/privacy-policy"
                    >
                      Privacy Policy
                    </Link>
                    .
                  </p>
                </>
              </form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
}
