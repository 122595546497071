/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Link } from "react-router-dom";
import currency from "currency.js";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Link as MaterialUILink,
  MenuItem,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import moment, { MomentInput } from "moment";

import { setSelectedWeek } from "../../slices/organizationSlice";
import { open as openSnackbar } from "../../slices/snackbarReducer";

import { setCurrentCartTab } from "../../slices/authNavReducer";

import PrimaryButton from "../PrimaryButton/PrimaryButton";
import EmptyCartItem from "../EmptyCartItem/EmptyCartItem";

import getProductPlatingDate from "../../utils/getProductPlatingDate";
import getMealCountForADay from "../../utils/getMealCountForADay";
import cartContainsMeals from "../../modules/cartContainsMeals";
import CartItem from "../CartItem/CartItem";
import calculateDeliveryFee from "../../modules/calculateDeliveryFee";
import calculateOrderLineItems from "../../modules/calculateOrderLineItems";
import { addFilter } from "../../slices/orderSlice";
import getExtrasTotalOnWholeOrder from "../../modules/getExtrasTotalOnWholeOrder";

export default function () {
  const dispatch = useAppDispatch();

  const { t, i18n }: any = useTranslation();

  const cartItems = useAppSelector((state) => state.cart.items);

  const selectedWeek = useAppSelector(
    (state) => state.organization.selectedWeek
  );

  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  const [selectedWeekChangeTo, setSelectedWeekChangeTo] = React.useState(0);
  const [weekChangeDialogOpen, setWeekChangeDialogOpen] = React.useState(false);

  const order: any = useAppSelector((state) => state.order.order);

  const selectedMealPlan: any = useAppSelector(
    (state) => state.cart.selectedMealPlan
  );

  const selectedMealPlanName: string = useAppSelector(
    (state) => state.cart.selectedMealPlanName
  );

  const cartTotal = useAppSelector((state) =>
    state.cart.items.length === 0
      ? 0
      : state.cart.items.reduce(
          (acc, curr) => acc + curr.quantity * curr.price,
          0
        )
  );

  const cartGroceryTotal = useAppSelector((state) =>
    state.cart.items.filter((e: any) => e.type === "Grocery").length === 0
      ? 0
      : state.cart.items
          .filter((e: any) => e.type === "Grocery")
          .reduce((acc, curr) => acc + curr.quantity * curr.price, 0)
  );

  const renderFreeDeliveryOnMinOrder = () => {
    if (i18n.language === "en") {
      return `Spend ${currency(100).subtract(cartTotal).format()} or more for
                  free delivery.`;
    }

    return `Dépensez ${currency(100, {
      pattern: "# !",
      separator: " ",
      decimal: ",",
    })
      .subtract(cartTotal)
      .format()} ou plus pour une livraison gratuite.`;
  };

  const countDeleveryFee = () => {
    let total = 0;
    cartItems.map((item) => (total += item.price));
    return total >= 100 ? 0 : 10;
  };

  const validateCartItems = () => {
    let issueWithMealCount = false;

    if (selectedMealPlanName === "a-la-carte" || selectedMealPlanName === "") {
      return true;
    }

    for (let i = 0; i <= 4; i++) {
      console.log(i);

      let day = moment().add(selectedWeek, "w").startOf("isoWeek").add(i, "d");

      if (
        getMealCountForADay(cartItems, selectedWeek, day) <
        selectedMealPlan.minimumDishPerDay
      ) {
        issueWithMealCount = true;
        break;
      }
    }

    if (issueWithMealCount) {
      dispatch(
        openSnackbar({
          message:
            "Select at least " +
            selectedMealPlan.minimumDishPerDay +
            ` meal${selectedMealPlan.minimumDishPerDay > 1 ? "s" : ""} per day`,
          severity: "error",
        })
      );

      return;
    }

    return true;
  };

  const getCartDeliveryFee = () => {
    const fee = calculateDeliveryFee(
      cartItems,
      selectedWeek,
      order.delivery.time,
      selectedMealPlanName,
      selectedMealPlan
    );

    if (fee !== 0) {
      return <span className="color-primary-dark">{t("free")}</span>;
    }

    return i18n.language === "fr"
      ? currency(fee, {
          pattern: "# !",
          separator: " ",
          decimal: ",",
        }).format()
      : currency(fee).format();
  };

  const renderPerDayMealPlanItemsText = (
    planMinimumDishes: number,
    mealCountForADay: number
  ) => {
    if (mealCountForADay <= planMinimumDishes) {
      return `${mealCountForADay} of ${planMinimumDishes} items`;
    }

    return `${mealCountForADay} items`;
  };

  return (
    <>
      {cartItems.length === 0 && (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            color="#bbb"
            flexDirection="column"
            marginTop={5}
          >
            <ShoppingCartIcon fontSize="large" />
            <h2 className="heading-sans-serif">{t("cartEmpty")}.</h2>
          </Box>
        </>
      )}

      {cartItems.length > 0 && (
        <>
          <Box mt={1}></Box>
          <h3 className="heading-sans-serif">{t("products")}</h3>
          <div className="cart-line-items-container">
            {cartItems.filter((e: any) => e.type === "Meals").length > 0 && (
              <Accordion defaultExpanded={true} onClick={() => null}>
                <AccordionDetails style={{ flexDirection: "column" }}>
                  <p
                    style={{
                      fontWeight: "bold",
                      padding: "0 24px",
                      marginBottom: 0,
                    }}
                  >
                    {moment().format("dddd, MMMM D")}
                  </p>

                  {cartItems
                    .filter((e: any) => e.type === "Meals")
                    .map((e: any, index: number) => (
                      <CartItem item={e} index={index} />
                    ))}

                  <EmptyCartItem
                    emptyCartNoThumb
                    handleClick={() => {
                      dispatch(
                        addFilter([
                          "day",
                          moment()
                            .add(selectedWeek, "w")
                            .startOf("isoWeek")
                            .add(0, "d")
                            .toISOString(),
                          ,
                        ])
                      );
                      document.body.classList.toggle("cart--open");
                    }}
                  />
                </AccordionDetails>
              </Accordion>
            )}

            {cartItems.filter((e: any) => e.type === "Grocery").length > 0 && (
              <Accordion defaultExpanded={cartItems.length <= 4 ? false : true}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: "#000" }} />}
                  className="background-filled"
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Box display="flex" alignItems="center">
                        <ShoppingCartIcon
                          style={{
                            marginRight: "8px",
                            fill: "#707e81",
                          }}
                        />{" "}
                        <Box>
                          <p className="offscreen-menu__content__section-heading fw-400 mb-0 mt-0">
                            {t("grocery")}
                          </p>
                          <p
                            style={{
                              marginTop: 0,
                              marginBottom: 0,
                              fontSize: "14px",
                              color: "#b9b2b6",
                            }}
                          >
                            {
                              cartItems.filter((e: any) => e.type === "Grocery")
                                .length
                            }{" "}
                            {i18n.language === "fr" ? "produit" : "item"}
                            {cartItems.filter((e: any) => e.type === "Grocery")
                              .length > 1
                              ? "s"
                              : ""}
                          </p>
                        </Box>
                      </Box>

                      <p
                        className="offscreen-menu__content__section-heading fw-400"
                        style={{ paddingRight: "8px" }}
                      >
                        {i18n.language === "fr"
                          ? currency(cartGroceryTotal, {
                              pattern: "# !",
                              separator: " ",
                              decimal: ",",
                            }).format()
                          : currency(cartGroceryTotal).format()}
                      </p>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails style={{ flexDirection: "column" }}>
                  {cartItems
                    .filter((e: any) => e.type === "Grocery")
                    .map((e: any, index: number) => (
                      <CartItem item={e} index={index} />
                    ))}
                </AccordionDetails>
              </Accordion>
            )}
          </div>

          <Divider style={{ marginTop: "8px", marginBottom: "32px" }} />

          <h2 className="heading-sans-serif mt-1">{t("summary")}</h2>
          {selectedMealPlan !== null &&
            selectedMealPlanName !== "" &&
            selectedMealPlanName !== "a-la-carte" &&
            calculateOrderLineItems({
              lineItems: cartItems,
              deliveryType: order.delivery.type,
              deliveryTime: order.delivery.time,
              selectedWeek,
              selectedMealPlan,
              selectedMealPlanName,
            }).savings <
              calculateOrderLineItems({
                lineItems: cartItems,
                deliveryType: order.delivery.type,
                deliveryTime: order.delivery.time,
                selectedWeek,
                selectedMealPlan,
                selectedMealPlanName,
              }).subTotal && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <p
                  className="offscreen-menu__content__section-heading mt-0 fw-400--family color-primary-dark"
                  style={{ marginBottom: "5px" }}
                >
                  Savings
                </p>
                <p
                  className="offscreen-menu__content__section-heading fw-400--family mt-0"
                  style={{ marginBottom: "5px" }}
                >
                  <span className="color-primary-dark">
                    -
                    {i18n.language === "fr"
                      ? currency(
                          calculateOrderLineItems({
                            lineItems: cartItems,
                            deliveryType: order.delivery.type,
                            deliveryTime: order.delivery.time,
                            selectedWeek,
                            selectedMealPlan,
                            selectedMealPlanName,
                          }).savings,
                          {
                            pattern: "# !",
                            separator: " ",
                            decimal: ",",
                          }
                        ).format()
                      : currency(
                          calculateOrderLineItems({
                            lineItems: cartItems,
                            deliveryType: order.delivery.type,
                            deliveryTime: order.delivery.time,
                            selectedWeek,
                            selectedMealPlan,
                            selectedMealPlanName,
                          }).savings
                        ).format()}
                  </span>
                </p>
              </Box>
            )}

          {getExtrasTotalOnWholeOrder(cartItems) > 0 && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <p
                className="offscreen-menu__content__section-heading mt-0 fw-400--family"
                style={{ marginBottom: "5px" }}
              >
                Extras
              </p>
              <p
                className="offscreen-menu__content__section-heading fw-400--family mt-0"
                style={{ marginBottom: "5px" }}
              >
                {currency(getExtrasTotalOnWholeOrder(cartItems)).format()}
              </p>
            </Box>
          )}

          <Box display="flex" flexDirection="column">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <p
                className="offscreen-menu__content__section-heading mt-0 fw-400--family"
                style={{ marginBottom: "5px" }}
              >
                {t("deliveryFee")}
              </p>
              <p
                className="offscreen-menu__content__section-heading fw-400--family mt-0"
                style={{ marginBottom: "5px" }}
              >
                {/* {countDeleveryFee()} */}
                {cartTotal < 100 ? "$10.00" : "Free"}
              </p>
            </Box>

            {cartTotal < 100 && !cartContainsMeals(cartItems) && (
              <>
                <LinearProgress
                  className="delivery-line-item"
                  style={{ marginTop: "0.5em" }}
                  variant="determinate"
                  value={cartTotal}
                />
                <p
                  style={{
                    marginTop: "6px",
                    marginBottom: 0,
                    fontSize: "14px",
                    color: "rgb(30, 70, 32)",
                  }}
                >
                  {renderFreeDeliveryOnMinOrder()}
                </p>
              </>
            )}
          </Box>

          <Divider style={{ marginTop: "16px", marginBottom: "0" }} />

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <p className="offscreen-menu__content__section-heading fw-400">
              {t("subTotal")}
            </p>
            <p className="offscreen-menu__content__section-heading">
              $
              {cartTotal < 100
                ? (cartTotal + 10).toFixed(2)
                : cartTotal.toFixed(2)}
              {/* {i18n.language === "fr"
                ? currency(
                    calculateOrderLineItems({
                      lineItems: cartItems,
                      deliveryType: order.delivery.type,
                      deliveryTime: order.delivery.time,
                      selectedWeek,
                      selectedMealPlan,
                      selectedMealPlanName,
                    }).subTotal,
                    {
                      pattern: "# !",
                      separator: " ",
                      decimal: ",",
                    }
                  ).format()
                : currency(
                    calculateOrderLineItems({
                      lineItems: cartItems,
                      deliveryType: order.delivery.type,
                      deliveryTime: order.delivery.time,
                      selectedWeek,
                      selectedMealPlan,
                      selectedMealPlanName,
                    }).subTotal
                  ).format()} */}
            </p>
          </Box>

          <PrimaryButton
            style={{ width: "100%", marginTop: ".5em" }}
            onClick={() => {
              // if (validateCartItems()) {
              if (!isAuthenticated) {
                dispatch(setCurrentCartTab("signup-options"));
              } else {
                dispatch(setCurrentCartTab("checkout"));
              }
              // }
            }}
          >
            {t("checkout")}
          </PrimaryButton>

          <p
            className="text-center color-secondary-2"
            style={{ marginTop: "40px", fontSize: "14px" }}
          >
            {t("taxDisclaimer")}{" "}
            <Link
              style={{ textDecoration: "underline" }}
              className="link color-secondary-2 link--to-black"
              to="/terms"
            >
              {t("terms")}
            </Link>
            .
          </p>
        </>
      )}

      <Dialog
        open={weekChangeDialogOpen}
        keepMounted
        onClose={() => setWeekChangeDialogOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle style={{ marginTop: "20px" }}>
          Changing weeks will affect items in your cart
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Changing the current week affects your delivery. Cut-off to order
            for the week of July 19, 2021 is 4 days away.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            justifyContent="space-between"
            paddingX={2}
            paddingTop={2}
            paddingBottom={2}
          >
            <MaterialUILink
              variant="body1"
              color="textSecondary"
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => {
                window.localStorage.setItem("doNotRemindWeekChange", "yes");
                dispatch(setSelectedWeek(selectedWeekChangeTo));
                setWeekChangeDialogOpen(false);
              }}
            >
              Do not remind me again
            </MaterialUILink>

            <Box>
              <Button
                color="secondary"
                onClick={() => setWeekChangeDialogOpen(false)}
                style={{
                  marginRight: "8px",
                  boxShadow: "none",
                  padding: "6px 16px",
                }}
              >
                Cancel
              </Button>
              <PrimaryButton
                style={{ boxShadow: "none", padding: "6px 16px" }}
                onClick={() => {
                  dispatch(setSelectedWeek(selectedWeekChangeTo));
                  setWeekChangeDialogOpen(false);
                }}
              >
                Continue
              </PrimaryButton>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}
