import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Button, Divider, Box, Radio, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { CircularProgress } from '@mui/material';

import PrimaryButton from '../PrimaryButton/PrimaryButton';

import './GuestReceiptOffScreen.scss';

import ButtonLoader from '../ButtonLoader/ButtonLoader';
import { cancelOrder, updateOrderInfo } from '../../slices/orderSlice';
import {
  setCurrentCartTab,
  setCurrentGuestReceiptTab,
} from '../../slices/authNavReducer';
import { open } from '../../slices/snackbarReducer';
import { useAppDispatch, useAppSelector } from '../../hooks';

export default function AccountNavOffScreen(props: any) {
  const dispatch = useAppDispatch();
  const { t, i18n }: any = useTranslation();

  const viaCart: boolean = useAppSelector((state) => state.order.viaCart);

  const orderLoading: boolean = useAppSelector(
    (state) => state.order.getExistingOrderLoading
  );

  const order: any = useAppSelector((state) => state.order.existingOrder);

  if (orderLoading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Formik
        initialValues={{
          cancelReason: '',
          note: '',
        }}
        validationSchema={Yup.object().shape({
          cancelReason: Yup.string().required('Cancel reason is required'),
          note: Yup.string().max(
            250,
            'Comment should be less than 250 characters'
          ),
        })}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          setSubmitting(true);

          dispatch(
            cancelOrder(order._id, values.note, values.cancelReason, () => {
              setSubmitting(false);
              if (viaCart) {
                dispatch(setCurrentCartTab('receipt'));
              } else {
                dispatch(setCurrentGuestReceiptTab('receipt'));
              }
              dispatch(
                open({
                  message: 'Your order has been cancelled',
                  severity: 'success',
                })
              );
            })
          );
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} className="form-login">
            <h2 className="heading-sans-serif">
              We want to hear your feedback before cancelling your order.
            </h2>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              marginBottom={2}
              width="100%"
            >
              <p
                className="offscreen-menu__content__section-heading fw-400 mb-0"
                style={{
                  marginTop: '0',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingRight: '36px',
                }}
                onClick={() =>
                  setFieldValue('cancelReason', 'I changed my mind')
                }
              >
                <span>I changed my mind</span>
              </p>
              <Radio
                color="primary"
                name="cancelReason"
                onClick={() =>
                  setFieldValue('cancelReason', 'I changed my mind')
                }
                checked={values.cancelReason === 'I changed my mind'}
              />
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              marginBottom={2}
              width="100%"
            >
              <p
                className="offscreen-menu__content__section-heading fw-400 mb-0"
                style={{
                  marginTop: '0',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingRight: '36px',
                }}
                onClick={() =>
                  setFieldValue('cancelReason', 'I bought the wrong item(s)')
                }
              >
                <span>I bought the wrong item(s)</span>
              </p>
              <Radio
                color="primary"
                name="cancelReason"
                checked={values.cancelReason === 'I bought the wrong item(s)'}
                onClick={() =>
                  setFieldValue('cancelReason', 'I bought the wrong item(s)')
                }
              />
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              marginBottom={2}
              width="100%"
            >
              <p
                className="offscreen-menu__content__section-heading fw-400 mb-0"
                style={{
                  marginTop: '0',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingRight: '36px',
                }}
                onClick={() =>
                  setFieldValue('cancelReason', 'I found a cheaper alternative')
                }
              >
                <span>I found a cheaper alternative</span>
              </p>
              <Radio
                color="primary"
                name="cancelReason"
                onClick={() =>
                  setFieldValue('cancelReason', 'I found a cheaper alternative')
                }
                checked={
                  values.cancelReason === 'I found a cheaper alternative'
                }
              />
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              marginBottom={2}
              width="100%"
              onClick={() =>
                setFieldValue(
                  'cancelReason',
                  'The delivery or pickup options are too long'
                )
              }
            >
              <p
                className="offscreen-menu__content__section-heading fw-400 mb-0"
                style={{
                  marginTop: '0',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingRight: '36px',
                }}
              >
                <span>The delivery or pickup options are too long</span>
              </p>
              <Radio
                color="primary"
                name="cancelReason"
                checked={
                  values.cancelReason ===
                  'The delivery or pickup options are too long'
                }
              />
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              marginBottom={2}
              width="100%"
            >
              <p
                className="offscreen-menu__content__section-heading fw-400 mb-0"
                style={{
                  marginTop: '0',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingRight: '36px',
                }}
                onClick={() =>
                  setFieldValue('cancelReason', 'I prefer not to say')
                }
              >
                <span>I prefer not to say</span>
              </p>
              <Radio
                color="primary"
                name="cancelReason"
                onClick={() =>
                  setFieldValue('cancelReason', 'I prefer not to say')
                }
                checked={values.cancelReason === 'I prefer not to say'}
              />
            </Box>

            <Box mt={4}></Box>

            <Divider />

            <Box mt={4}></Box>

            <p className="offscreen-menu__content__section-heading">Comments</p>

            <TextField
              color="secondary"
              label="Note"
              name="note"
              fullWidth
              multiline
              variant="outlined"
              value={values.note}
              onBlur={handleBlur}
              onChange={handleChange}
              error={Boolean(errors.note && touched.note)}
              style={{ marginBottom: '32px' }}
              // helperText={errors.notes && touched.notes && errors.notes}
            />

            <PrimaryButton
              style={{ width: '100%', marginTop: '2em' }}
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <ButtonLoader />
              ) : (
                <>
                  <CloseIcon style={{ marginRight: '8px' }} /> Cancel order
                </>
              )}
            </PrimaryButton>

            <p
              className="text-center color-secondary-2"
              style={{ marginTop: '40px', fontSize: '14px' }}
            >
              Once your order is cancelled, your payment method will be
              refunded.
            </p>
          </form>
        )}
      </Formik>
    </>
  );
}
