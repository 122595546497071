import { createSlice } from "@reduxjs/toolkit";
import feathers from "../feathers";

import ProductsService from "../api/products";

import { AppDispatch, AppThunk } from "../store";

const initialState = {
  meals: [],
  groceries: [],
  productsLoading: true,
  product: null,
  productLoading: true,
};

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    getProductsSuccess: (state, action) => {
      state.groceries = action.payload[0].groceries;
      state.meals = action.payload[0].meals;
      state.productsLoading = false;
    },
    setProductsLoading: (state, action) => {
      state.productsLoading = action.payload;
    },
    getProductSuccess: (state, action) => {
      // state.groceries = action.payload[0].groceries;
      // state.meals = action.payload[0].meals;
      state.product = action.payload;
      state.productLoading = false;
    },
    setProductLoading: (state, action) => {
      state.productLoading = action.payload;
    },
  },
});

export const {
  getProductsSuccess,
  setProductsLoading,
  getProductSuccess,
  setProductLoading,
} = productsSlice.actions;

export const getProducts =
  (query: object | undefined): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setProductsLoading(true));
      const products = await ProductsService.find(query);
      dispatch(getProductsSuccess(products));
      dispatch(setProductsLoading(false));
    } catch (error) {
      console.log(error);
    }
  };

export const getProduct =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setProductLoading(true));
      const product = await ProductsService.get(id);
      dispatch(getProductSuccess(product));
      dispatch(setProductLoading(false));
    } catch (error) {
      console.log(error);
    }
  };
export default productsSlice.reducer;
