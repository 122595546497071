import React from "react";
import { Formik } from "formik";
import { Grid, TextField } from "@mui/material";
import * as Yup from "yup";

import PhoneInput from "react-phone-input-2";

import PrimaryButton from "../PrimaryButton/PrimaryButton";
import "react-phone-input-2/lib/material.css";
import { updateOrder, updateOrderInfo } from "../../slices/orderSlice";
import { setCurrentCartTab } from "../../slices/authNavReducer";
import ButtonLoader from "../ButtonLoader/ButtonLoader";
import { useAppDispatch, useAppSelector } from "../../hooks";

export default function Profile(props: any) {
  const dispatch = useAppDispatch();

  const order: any = useAppSelector((state) => state.order.order);

  const orderInfoUpdating = useAppSelector(
    (state) => state.order.orderInfoUpdating
  );

  return (
    <Formik
      initialValues={{
        firstName: order.profile.firstName || "",
        lastName: order.profile.lastName || "",
        emailAddress: order.profile.emailAddress || "",
        phone: order.profile.phone || "",
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required("First name is required"),
        lastName: Yup.string().required("Last name is required"),
        emailAddress: Yup.string()
          .email("Enter a valid email address")
          .required("Email is required"),
        phone: Yup.string().required("Phone number is required"),
      })}
      onSubmit={(values, { setSubmitting }) => {
        console.log(values);
        setSubmitting(true);
        dispatch(
          updateOrderInfo(values, "profile", () => {
            setSubmitting(false);
            dispatch(setCurrentCartTab("checkout"));
          })
        );
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit} className="form-login">
          <p className="offscreen-menu__content__section-heading">Contact</p>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                color="secondary"
                label="First name"
                name="firstName"
                fullWidth
                value={values.firstName}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(errors.firstName && touched.firstName)}
                // helperText={
                //   errors.firstName && touched.firstName && errors.firstName
                // }
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                color="secondary"
                label="Last name"
                name="lastName"
                fullWidth
                value={values.lastName}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(errors.lastName && touched.lastName)}
                // helperText={
                //   errors.lastName && touched.lastName && errors.lastName
                // }
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                color="secondary"
                label="Email address"
                name="emailAddress"
                fullWidth
                value={values.emailAddress}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(errors.emailAddress && touched.emailAddress)}
                // helperText={
                //   errors.emailAddress &&
                //   touched.emailAddress &&
                //   errors.emailAddress
                // }
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={12}>
              <PhoneInput
                country="ca"
                onChange={(
                  v: any,
                  country: any,
                  e: any,
                  formattedValue: any
                ) => {
                  console.log(v);
                  console.log(country);
                  console.log(e);
                  console.log(formattedValue);
                  setFieldValue("phone", formattedValue);
                }}
                inputProps={{
                  name: "phone",
                  onBlur: handleBlur,
                  value: values.phone,
                }}
                inputClass={"MuiInputBase-input MuiInput-input"}
                containerClass={
                  "MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-colorSecondary MuiInput-colorSecondary MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl"
                }
              />
            </Grid>
          </Grid>

          <PrimaryButton
            style={{ width: "100%", marginTop: "2em" }}
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? <ButtonLoader /> : "Add profile"}
          </PrimaryButton>
        </form>
      )}
    </Formik>
  );
}
