import { combineReducers } from "redux";
import snackbarReducer from "./slices/snackbarReducer";
import userReducer from "./slices/userReducer";
import authReducer from "./slices/authReducer";
import authNavReducer from "./slices/authNavReducer";
import subscriptionReducer from "./slices/subscriptionReducer";
import cartReducer from "./slices/cartReducer";
import organizationSlice from "./slices/organizationSlice";
import ingredientsSlice from "./slices/ingredientsSlice";
import productsSlice from "./slices/productsSlice";
import categoriesSlice from "./slices/categoriesReducer";
import orderSlice from "./slices/orderSlice";
import giftCardReducer from "./slices/giftCardSlice";

const rootReducer = combineReducers({
  cart: cartReducer,
  user: userReducer,
  subscription: subscriptionReducer,
  giftCards: giftCardReducer,
  auth: authReducer,
  authNav: authNavReducer,
  snackbar: snackbarReducer,
  organization: organizationSlice,
  products: productsSlice,
  ingredients: ingredientsSlice,
  categories: categoriesSlice,
  order: orderSlice,
});

export default rootReducer;
