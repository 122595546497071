/* eslint-disable import/no-anonymous-default-export */
import React, { SyntheticEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import currency from "currency.js";
import moment, { MomentInput } from "moment";

import {
  Button,
  Divider,
  Box,
  Tooltip,
  CircularProgress,
  InputAdornment,
  IconButton,
  Grid,
  LinearProgress,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HomeIcon from "@mui/icons-material/Home";
import TodayIcon from "@mui/icons-material/Today";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import PrintIcon from "@mui/icons-material/Print";
import EmailIcon from "@mui/icons-material/Email";
import BusinessIcon from "@mui/icons-material/Business";
import WorkIcon from "@mui/icons-material/Work";
import FeedbackIcon from "@mui/icons-material/Feedback";
import CloseIcon from "@mui/icons-material/Close";
import SmsIcon from "@mui/icons-material/Sms";
import CheckIcon from "@mui/icons-material/Check";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import ButtonSecondary from "../ButtonSecondary/ButtonSecondary";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import CardBrandImage from "../CardBrandImage/CardBrandImage";
import ButtonLoader from "../ButtonLoader/ButtonLoader";

import feathers from "../../feathers";

import {
  setCurrentCartTab,
  setCurrentGuestReceiptTab,
} from "../../slices/authNavReducer";
import { emaiLReceipt, fetchOrder } from "../../slices/orderSlice";
import { open } from "../../slices/snackbarReducer";
import calculateDeliveryFee from "../../modules/calculateDeliveryFee";
import cartContainsMeals from "../../modules/cartContainsMeals";
import calculateOrderLineItems from "../../modules/calculateOrderLineItems";
import getProductPlatingDate from "../../utils/getProductPlatingDate";
import { useAppDispatch, useAppSelector } from "../../hooks";

interface ReceiptProps {
  placedOrder?: boolean;
}

export default (props: ReceiptProps) => {
  const dispatch = useAppDispatch();
  const { t, i18n }: any = useTranslation();

  const order: any = useAppSelector((state) =>
    props.placedOrder ? state.order.order : state.order.existingOrder
  );

  const viaCart: boolean = useAppSelector((state) => state.order.viaCart);

  const selectedMealPlan = useAppSelector(
    (state) => state.cart.selectedMealPlan
  );

  const selectedMealPlanName = useAppSelector(
    (state) => state.cart.selectedMealPlanName
  );

  const [submittingEmailReceipt, setSubmittingEmailReceipt] =
    React.useState(false);

  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [paymentIntentLoading, setPaymentIntentLoading] = React.useState(false);
  const [paymentIntentLoaded, setPaymentIntentLoaded] = React.useState(false);
  const [cardLast4, setCardLast4] = React.useState("");
  const [cardBrand, setCardBrand] = React.useState("");

  const orderLoading: boolean = props.placedOrder
    ? false
    : useAppSelector((state) => state.order.existingOrderLoading);

  async function fetchAndloadPaymentIntentData(paymentIntentId: string) {
    console.log("get====");
    const paymentIntent = await feathers
      .service("/stripe/payment-intent")
      .get(paymentIntentId);

    console.log("going PI");
    console.log(paymentIntent);

    // setCardLast4(
    //   //@ts-ignore
    //   paymentIntent?.charges.data[0].payment_method_details.card.last4
    // );

    // setCardBrand(
    //   //@ts-ignore
    //   paymentIntent?.charges.data[0].payment_method_details.card.brand
    // );

    setPaymentIntentLoaded(true);
    setPaymentIntentLoading(false);
  }

  useEffect(() => {
    //@ts-ignore
    if (document.querySelector(".offscreen-menu__content"))
      //@ts-ignore
      document.querySelector(".offscreen-menu__content").scrollTop = 0;
  }, []);

  useEffect(() => {
    if (!orderLoading) {
      if (
        order.type !== "mealPlan" &&
        order.paymentType === "card" &&
        !paymentIntentLoaded
      ) {
        console.log("before2", order);
        fetchAndloadPaymentIntentData(order.paymentIntentId);
      }
    }
  }, [orderLoading]);

  const handleSendEmailReceipt = (e: SyntheticEvent) => {
    e.preventDefault();

    setSubmittingEmailReceipt(true);
    dispatch(
      emaiLReceipt(order._id, () => {
        setSubmittingEmailReceipt(false);

        dispatch(
          open({
            severity: "success",
            message: "Receipt sent by email.",
          })
        );
      })
    );
  };

  const renderAddress = (address: any) => {
    return (
      <>
        <p
          className="font-weight-500 font-weight-medium"
          style={{
            fontFamily: "Inter-Regular, sans-serif",
            margin: 0,
          }}
        >
          {address.organization.length !== 0 && (
            <>
              {address.organization} <br />
            </>
          )}
          {address.Line1}, {address.Line2}
          <br />
          {address.City}, {address.Province} {address.PostalCode}
          {address.buzzer !== "" && (
            <>
              <span>
                <br />
                Buzzer: {address.buzzer}
              </span>
            </>
          )}
        </p>
        {/* {address.primary && (
          <p
            style={{
              marginTop: 0,
              marginBottom: 0,
              fontSize: '14px',
              color: '#b9b2b6',
            }}
          >
            Primary
          </p>
        )} */}
      </>
    );
  };

  const ifMealsArePresentForPlatingDay = (day: MomentInput) => {
    return (
      order.lineItems.filter((e: any) => {
        return (
          e.type === "Meals" &&
          moment(
            getProductPlatingDate(order.selectedWeek, e.availability, "date")
          ).isoWeekday() === moment(day).isoWeekday()
        );
      }).length > 0
    );
  };

  const renderAddedMealsForPlatingDay = (day: MomentInput) => {
    return order.lineItems
      .filter((e: any) => {
        return e.type === "Meals";
      })
      .filter((e: any) => {
        return (
          moment(
            getProductPlatingDate(
              order.selectedWeek,
              e.availability || [],
              "date"
            )
          ).isoWeekday() === moment(day).isoWeekday()
        );
      })
      .map((e: any) => {
        const cartItemIndex = order.lineItems.findIndex(
          (a: any) => a._id === e._id
        );
        return renderCartLineItem(e, cartItemIndex);
      });
  };

  const renderCartLineItem = (item: any, index: number) => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          className="cart-line-item cart-line-item--no-hover"
          flexDirection="column"
        >
          <Box display="flex" alignItems="flex-start" width="100%">
            <img
              width="64px"
              src={
                item.variants[0].thumbnailURL
                  ? `https://s3.amazonaws.com/vittle-new/${item.variants[0].thumbnailURL}`
                  : "https://via.placeholder.com/64x64/ccc"
              }
              alt=""
            />

            <Box display="flex" width="100%" justifyContent="space-between">
              <Box display="flex" flexDirection="column" pr={2} width="60%">
                <p className="offscreen-menu__content__section-heading fw-400--family mt-0 mb-0">
                  {item.title}
                </p>
                <p
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    fontSize: "14px",
                    color: "#b9b2b6",
                  }}
                >
                  {item.selectedVariant.name ||
                    item.selectedVariant.unit +
                      " " +
                      item.selectedVariant.value}
                </p>
              </Box>

              <Box
                display="flex"
                width="40%"
                justifyContent="flex-end"
                alignItems="flex-start"
                mr={"-8px"}
              >
                <Box
                  display="flex"
                  alignItems="flex-end"
                  flexDirection="column"
                  pr={1}
                >
                  <p className="offscreen-menu__content__section-heading fw-400--family mt-0 mb-0">
                    {i18n.language === "fr"
                      ? currency(item.price, {
                          pattern: "# !",
                          separator: " ",
                          decimal: ",",
                        })
                          .multiply(item.quantity)
                          .format()
                      : currency(item.price).multiply(item.quantity).format()}
                  </p>
                  <p
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      fontSize: "14px",
                      color: "#b9b2b6",
                    }}
                  >
                    Qty: {item.quantity}
                  </p>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* <MuiAlert
          style={{ width: '100%' }}
          //@ts-ignore
          severity="success"
          icon={<LocalOfferIcon />}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Typography>Promotion: 2 for $15 </Typography>
            <Button>Apply</Button>
          </Box>
        </MuiAlert> */}
        </Box>
        {/* <Divider style={{ width: '100%', margin: '16px 24px 0' }} /> */}
      </>
    );
  };

  const getOrderSubTotal = (type: string = "total") => {
    if (type === "total") {
      return order.lineItems.reduce(
        (acc: any, curr: any) => acc + curr.quantity * curr.price,
        0
      );
    }

    if (type === "grocery") {
      return order.lineItems
        .filter((e: any) => e.type === "Grocery")
        .reduce((acc: any, curr: any) => acc + curr.quantity * curr.price, 0);
    }

    if (type === "meals") {
      return order.lineItems
        .filter((e: any) => e.type === "Meals")
        .reduce((acc: any, curr: any) => acc + curr.quantity * curr.price, 0);
    }
  };

  if (!order) {
    return null;
  }

  return (
    <>
      <h2 className="heading-sans-serif">Order details</h2>

      <h3 className="heading heading-sans-serif mb0 mb-0 mt-0">
        Your order {order.status === "placed" && "has been placed."}
        {order.status === "processing" && "is being processed."}
        {order.status === "cancelled" && "has been cancelled."}
        {order.status === "refunded" && "has been refunded."}
      </h3>

      {order.status === "placed" && (
        <p>
          Thank you for your order. We have received your order and are
          currently in the process of reviewing it. You will be contacted by
          email once your order is ready.
        </p>
      )}

      {order.status === "processing" && (
        <p>
          Thank you for your order. We've received your order and will notify
          you when your products are out for delivery by our local courier.
        </p>
      )}

      {order.status === "cancelled" && (
        <p>
          We cancelled your order. You will not be charged for any portion of
          your order if a temporary hold was placed on your payment method. This
          is not a charge and should disappear from your bank statement shortly.
        </p>
      )}

      <p
        className="font-weight-500 font-weight-medium"
        style={{
          fontFamily: "Inter-Regular, sans-serif",
          marginTop: "24px",
        }}
      >
        <span style={{ fontWeight: "bold" }}>Order number: </span>
        <span style={{ textTransform: "uppercase" }}>{order._id}</span>
        <br />
        <span
          style={{ fontWeight: "bold", marginTop: "10px", display: "block" }}
        >
          Order date:{" "}
        </span>{" "}
        {moment(order.createdAt).format("MMMM D, YYYY [at] HH:mm a")}
      </p>

      <p
        className="font-weight-500 font-weight-medium"
        style={{
          fontFamily: "Inter-Regular, sans-serif",
          margin: "0px",
        }}
      ></p>

      <Box className="order-status-progress-container" mt={3} mb={2}>
        <LinearProgress
          className={`order-status-progress active-2-of-4 ${
            order.status === "cancelled" ? "order-status--cancelled" : ""
          }`}
          style={{
            height: "8px",
          }}
          variant="determinate"
          value={
            order.status === "placed"
              ? 6.25
              : order.status === "processing"
              ? 37.5
              : order.status === "ready-for-delivery"
              ? 75
              : 100
          }
        />
        {/* AIzaSyBwEDfpa_Da16FvH9LFIvNqNiI7sBtg_qw */}

        <Box
          position="relative"
          width="100%"
          className="order-status-progress-labels"
        >
          {order.status !== "cancelled" && (
            <>
              <Typography className={order.status === "placed" ? "active" : ""}>
                Order
                <br />
                placed
              </Typography>
              <Typography
                className={order.status === "processing" ? "active" : ""}
              >
                Processing
              </Typography>
              <Typography
                className={
                  order.status === "ready-for-delivery" ? "active" : ""
                }
              >
                Ready for <br />
                delivery
              </Typography>
              <Typography
                className={order.status === "delivered" ? "active" : ""}
              >
                Delivered
              </Typography>
            </>
          )}

          {order.status == "cancelled" && (
            <Typography color="error">Cancelled</Typography>
          )}
        </Box>
      </Box>

      <Divider style={{ marginTop: "32px", marginBottom: "32px" }} />

      <h2 className="heading-sans-serif">
        {order.delivery.type === "pickup" ? "Pickup" : "Delivery"}
      </h2>

      {order.delivery.type === "pickup" ? (
        <img
          style={{ maxWidth: "100%" }}
          src={`https://maps.googleapis.com/maps/api/staticmap?size=500x300&scale=3&format=PNG&center=${encodeURIComponent(
            "1160 Heron Road Ottawa, ON, Canada K1H 7Z3"
          )}&markers=${encodeURIComponent(
            "1160 Heron Road Ottawa, ON, Canada K1H 7Z3"
          )}&key=AIzaSyBVJzk-BCm6eiiOso1yuJ73Fn75JUtDRwE`}
        />
      ) : (
        <img
          style={{ maxWidth: "100%" }}
          src={`https://maps.googleapis.com/maps/api/staticmap?size=500x300&scale=3&format=PNG&center=${encodeURIComponent(
            order.address.Label.replace("\n", ",")
          )}&markers=${encodeURIComponent(
            order.address.Label.replace("\n", ",")
          )}&key=AIzaSyBVJzk-BCm6eiiOso1yuJ73Fn75JUtDRwE`}
        />
      )}

      {order.delivery.type !== "pickup" && (
        <Box display="flex" alignItems="center" mt={2}>
          <Box display="flex" alignItems="center" paddingRight="8px">
            {order.address.addressType === "home" && (
              <HomeIcon
                style={{
                  width: "32px",
                  height: "32px",
                }}
                color="secondary"
              />
            )}

            {order.address.addressType === "apartment" && (
              <BusinessIcon
                style={{
                  width: "32px",
                  height: "32px",
                }}
                color="secondary"
              />
            )}

            {order.address.addressType === "business" && (
              <WorkIcon
                style={{
                  width: "32px",
                  height: "32px",
                }}
                color="secondary"
              />
            )}
          </Box>
          <Box>{renderAddress(order.address)}</Box>
        </Box>
      )}

      {order.delivery.type == "pickup" && (
        <Box display="flex" alignItems="center" mt={2}>
          <Box display="flex" alignItems="center" paddingRight="8px">
            <BusinessIcon
              style={{
                width: "32px",
                height: "32px",
              }}
              color="secondary"
            />
          </Box>
          <Box>
            {renderAddress({
              organization: "Vittle",
              Line1: "1160 Heron Road",
              Line2: "",
              City: "Ottawa",
              Province: "ON",
              PostalCode: "K1H 7Z3",
              buzzer: "",
            })}
          </Box>
        </Box>
      )}

      <Box display="flex" alignItems="center" mt={2}>
        <Box display="flex" alignItems="center" paddingRight="8px">
          <TodayIcon
            style={{
              width: "32px",
              height: "32px",
            }}
            color="secondary"
          />
        </Box>
        <Box>
          <p
            className="font-weight-500 font-weight-medium"
            style={{
              fontFamily: "Inter-Regular, sans-serif",
              margin: 0,
            }}
          >
            {moment(order.delivery.date).format("dddd[,] MMMM D[,] YYYY")}
          </p>
          <p
            style={{
              marginTop: 0,
              marginBottom: 0,
              fontSize: "14px",
              color: "#b9b2b6",
            }}
          >
            {order.delivery.type === "pickup"
              ? "2:30-4:00 p.m."
              : "2:30-6:30 p.m."}
          </p>
        </Box>
      </Box>
      {/* 
            <Box display="flex" alignItems="center" paddingRight="8px">
              <img
                style={{ marginRight: '8px', width: '32px' }}
                src="/card-icons/vittle-gift-card.svg"
              />
            </Box> */}

      <Divider style={{ marginTop: "32px", marginBottom: "32px" }} />

      <h2 className="heading-sans-serif">Summary</h2>

      <div className="cart-line-items-container cart-line-items-contaner--confirm-order">
        {order.lineItems.filter((e: any) => e.type === "Grocery").length >
          0 && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#000" }} />}
              className="background-filled"
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Box display="flex" alignItems="center">
                    <ShoppingCartIcon
                      style={{
                        marginRight: "8px",
                        fill: "#707e81",
                      }}
                    />{" "}
                    <Box>
                      <p className="offscreen-menu__content__section-heading fw-400 mb-0 mt-0">
                        Grocery
                      </p>
                      <p
                        style={{
                          marginTop: 0,
                          marginBottom: 0,
                          fontSize: "14px",
                          color: "#b9b2b6",
                        }}
                      >
                        {`${
                          order.lineItems.filter(
                            (e: any) => e.type === "Grocery"
                          ).length
                        } item${
                          order.lineItems.filter(
                            (e: any) => e.type === "Grocery"
                          ).length > 1
                            ? "s"
                            : ""
                        }`}
                      </p>
                    </Box>
                  </Box>

                  <p
                    className="offscreen-menu__content__section-heading fw-400--family"
                    style={{ paddingRight: "8px" }}
                  >
                    {currency(getOrderSubTotal("grocery")).format()}
                  </p>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails style={{ flexDirection: "column" }}>
              {order.lineItems
                .filter((e: any) => e.type === "Grocery")
                .map((e: any, index: number) => renderCartLineItem(e, index))}
            </AccordionDetails>
          </Accordion>
        )}

        {order.lineItems.filter((e: any) => e.type === "Meals").length > 0 && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#000" }} />}
              className="background-filled"
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Box display="flex" alignItems="center">
                    <RestaurantMenuIcon
                      style={{
                        marginRight: "8px",
                        fill: "#707e81",
                      }}
                    />{" "}
                    <Box>
                      <p className="offscreen-menu__content__section-heading fw-400 mb-0 mt-0">
                        Prepared meals
                      </p>
                      <p
                        style={{
                          marginTop: 0,
                          marginBottom: 0,
                          fontSize: "14px",
                          color: "#b9b2b6",
                        }}
                      >
                        {`${
                          order.lineItems.filter((e: any) => e.type === "Meals")
                            .length
                        } item${
                          order.lineItems.filter((e: any) => e.type === "Meals")
                            .length > 1
                            ? "s"
                            : ""
                        }`}
                      </p>
                    </Box>
                  </Box>

                  <p
                    className="offscreen-menu__content__section-heading fw-400--family"
                    style={{ paddingRight: "8px" }}
                  >
                    {currency(getOrderSubTotal("meals")).format()}
                  </p>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails style={{ flexDirection: "column" }}>
              {[0, 1, 2, 3, 4].map((e: any) => {
                let day = moment(order.createdAt)
                  .add(order.selectedWeek, "w")
                  .startOf("isoWeek")
                  .add(e, "d");

                return ifMealsArePresentForPlatingDay(day) ? (
                  <>
                    <p style={{ fontWeight: "bold", padding: "0 24px" }}>
                      {moment(order.createdAt)
                        .add(order.selectedWeek, "w")
                        .startOf("isoWeek")
                        .add(e, "d")
                        .format("dddd, MMMM D")}
                    </p>

                    {renderAddedMealsForPlatingDay(
                      moment(order.createdAt)
                        .add(order.selectedWeek, "w")
                        .startOf("isoWeek")
                        .add(e, "d")
                    )}
                  </>
                ) : null;
              })}
            </AccordionDetails>
          </Accordion>
        )}
      </div>

      <Divider />

      <Box display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <p className="offscreen-menu__content__section-heading mb-0 fw-400--family">
            Subtotal
          </p>
          <p className="offscreen-menu__content__section-heading fw-400--family mb-0">
            {currency(getOrderSubTotal()).format()}
          </p>
        </Box>

        {/* <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt={1.5}
            >
              <p
                className="offscreen-menu__content__section-heading mb-0 fw-400--family mt-0"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                BLACKFRIDAY
                <br />
                <span
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    fontSize: '14px',
                    color: '#b9b2b6',
                    display: 'block',
                  }}
                >
                  Get $50 off
                </span>
              </p>
              <p className="offscreen-menu__content__section-heading color-primary-dark fw-400 fw-400--family mb-0 mt-0">
                -$50.00
              </p>
            </Box> */}

        {selectedMealPlan !== null &&
          selectedMealPlanName !== "" &&
          selectedMealPlanName !== "a-la-carte" &&
          calculateOrderLineItems({
            lineItems: order.lineItems,
            deliveryType: order.delivery.type,
            deliveryTime: order.delivery.time,
            selectedWeek: order.selectedWeek,
            selectedMealPlan: viaCart
              ? selectedMealPlan
              : order.selectedMealPlan,
            selectedMealPlanName: viaCart
              ? selectedMealPlanName
              : order.selectedMealPlanName,
          }).savings > 0 && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <p
                className="offscreen-menu__content__section-heading fw-400--family color-primary-dark"
                style={{ marginBottom: 0, marginTop: "10px" }}
              >
                Savings
              </p>
              <p
                className="offscreen-menu__content__section-heading fw-400--family"
                style={{ marginBottom: 0, marginTop: "10px" }}
              >
                <span className="color-primary-dark">
                  -
                  {i18n.language === "fr"
                    ? currency(
                        calculateOrderLineItems({
                          lineItems: order.lineItems,
                          deliveryType: order.delivery.type,
                          deliveryTime: order.delivery.time,
                          selectedWeek: order.selectedWeek,
                          selectedMealPlan: viaCart
                            ? selectedMealPlan
                            : order.selectedMealPlan,
                          selectedMealPlanName: viaCart
                            ? selectedMealPlanName
                            : order.selectedMealPlanName,
                        }).savings,
                        {
                          pattern: "# !",
                          separator: " ",
                          decimal: ",",
                        }
                      ).format()
                    : currency(
                        calculateOrderLineItems({
                          lineItems: order.lineItems,
                          deliveryType: order.delivery.type,
                          deliveryTime: order.delivery.time,
                          selectedWeek: order.selectedWeek,
                          selectedMealPlan: viaCart
                            ? selectedMealPlan
                            : order.selectedMealPlan,
                          selectedMealPlanName: viaCart
                            ? selectedMealPlanName
                            : order.selectedMealPlanName,
                        }).savings
                      ).format()}
                </span>
              </p>
            </Box>
          )}

        {order.delivery.type !== "pickup" && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <p
              className="offscreen-menu__content__section-heading mb-0 mt-1 fw-400--family"
              style={{ marginTop: "10px" }}
            >
              Delivery fee
            </p>
            <p
              className="offscreen-menu__content__section-heading fw-400--family mb-0 mt-1"
              style={{ marginTop: "10px" }}
            >
              {cartContainsMeals(order.lineItems) ? (
                <>
                  {calculateDeliveryFee(
                    order.lineItems,
                    order.selectedWeek,
                    order.delivery.time,
                    selectedMealPlanName,
                    selectedMealPlan
                  ) > 0 ? (
                    <span>
                      {currency(
                        calculateDeliveryFee(
                          order.lineItems,
                          order.selectedWeek,
                          order.delivery.time,
                          selectedMealPlanName,
                          selectedMealPlan
                        )
                      ).format()}
                    </span>
                  ) : (
                    <span className="color-primary-dark">Free</span>
                  )}
                </>
              ) : (
                <>
                  {getOrderSubTotal() > 100 ? (
                    <span className="color-primary-dark">Free</span>
                  ) : (
                    "$3.50"
                  )}
                </>
              )}
            </p>
          </Box>
        )}

        {/*<Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              style={{ marginTop: '0' }}
            >
            Remove mt-0 class if there's no gift card or promo code applied above 
              <p className="offscreen-menu__content__section-heading mb-0 fw-400--family mt-0">
                Discount total
              </p>
              <span className="color-primary-dark">$50.00</span>
            </Box> */}

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ marginTop: "10px" }}
        >
          {/* Remove mt-0 class if there's no gift card or promo code applied above */}
          <p className="offscreen-menu__content__section-heading mb-0 fw-400--family mt-0">
            Tax
          </p>
          <p className="offscreen-menu__content__section-heading  fw-400--family mb-0 mt-0">
            {getOrderSubTotal() > 100
              ? currency(getOrderSubTotal()).multiply(0.13).format()
              : currency(getOrderSubTotal()).add(5).multiply(0.13).format()}
          </p>
        </Box>

        <Divider style={{ marginTop: "16px", marginBottom: "8px" }} />

        {/* <Box display="flex" alignItems="center" paddingY={1}>
              <Box display="flex" alignItems="center" paddingRight="8px">
                <img
                  style={{ marginRight: '8px', width: '32px' }}
                  src="/card-icons/vittle-gift-card.svg"
                />
              </Box>
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <p
                  className="font-weight-500 font-weight-medium"
                  style={{
                    fontFamily: 'Inter-Regular, sans-serif',
                    margin: 0,
                  }}
                >
                  •••29AS
                </p>
                <p
                  className="font-weight-500 font-weight-medium"
                  style={{
                    fontFamily: 'Inter-Regular, sans-serif',
                    margin: 0,
                  }}
                >
                  $15.00
                </p>
              </Box>
            </Box> */}

        {order.paymentType === "card" &&
          !paymentIntentLoading &&
          paymentIntentLoaded && (
            <Box display="flex" alignItems="center" paddingY={1}>
              <Box display="flex" alignItems="center" paddingRight="8px">
                <CardBrandImage brandName={cardBrand} />
              </Box>
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <p
                  className="font-weight-500 font-weight-medium"
                  style={{
                    fontFamily: "Inter-Regular, sans-serif",
                    margin: 0,
                  }}
                >
                  •••{cardLast4}
                </p>
                <p
                  className="font-weight-500 font-weight-medium"
                  style={{
                    fontFamily: "Inter-Regular, sans-serif",
                    margin: 0,
                  }}
                >
                  {getOrderSubTotal() > 100
                    ? currency(getOrderSubTotal()).multiply(1.13).format()
                    : currency(getOrderSubTotal())
                        .add(3.5)
                        .multiply(1.13)
                        .format()}
                </p>
              </Box>
            </Box>
          )}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <p className="offscreen-menu__content__section-heading">
            Order total
          </p>
          <p className="offscreen-menu__content__section-heading">
            {getOrderSubTotal() > 100
              ? currency(getOrderSubTotal()).multiply(1.13).format()
              : currency(getOrderSubTotal()).add(5).multiply(1.13).format()}
          </p>
        </Box>

        {/* <Box
          display="flex"
          mb={3}
          p={3}
          flexDirection="column"
          // style={{ backgroundColor: '#d5f5f0' }}
          style={{ backgroundColor: '#f8faf9' }}
        >
          <h3 className="heading-sans-serif mb-0">
            Get order updates by text message.
          </h3>
          <p className="">
            Subscribe with your cell phone number to get status updates.
          </p>

          <PhoneInput
            country={'ca'}
            specialLabel=""
            autoFormat={true}
            inputProps={{
              name: 'phoneNumber',
              style: {
                width: '100%',
              },
              // class:
            }}
            onChange={(v: any, country: any, e: any, formattedValue: any) => {
              console.log(formattedValue);
            }}
            inputClass={'MuiInputBase-input MuiInput-input'}
            containerClass={
              'react-tel-input--order-phone-subscribe MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-colorSecondary MuiInput-colorSecondary MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl'
            }
          />

          <Box mt={3}></Box>

          <PrimaryButton
            variant="contained"
            color="secondary"
            style={{
              boxShadow: 'none',
              borderRadius: '0',
              marginTop: '8px',
              padding: '12px 32px',
              textTransform: 'none',
              fontSize: '1em',
              fontFamily: 'Inter-SemiBold, sans-serif',
              transition: 'all .25s ease',
              color: '#000',
            }}
            disabled={submittingEmailReceipt}
            onClick={handleSendEmailReceipt}
          >
            {submittingEmailReceipt ? (
              <ButtonLoader />
            ) : (
              <>
                {' '}
                <SmsIcon style={{ marginRight: '8px' }} />
                Subscribe
              </>
            )}
          </PrimaryButton>
        </Box> */}

        {/* <Box
          display="flex"
          mb={3}
          p={3}
          flexDirection="column"
          // style={{ backgroundColor: '#d5f5f0' }}
          style={{ backgroundColor: '#f8faf9' }}
        >
          <h3 className="heading-sans-serif mb-0">
            Get order updates by text message.
          </h3>
          <p className="">
            Subscribe with your cell phone number to get status updates.
          </p>

          <Box
            mt={3}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="row"
            textAlign="center"
          >
            <CheckIcon style={{ marginRight: '8px' }} />
            <p>+91-76661-37467</p>
          </Box>
          <Box textAlign="center">
            <p
              style={{
                fontSize: '.9em',
                marginTop: 0,
                textTransform: 'uppercase',
              }}
            >
              Subscribed
            </p>
          </Box>
        </Box> */}

        {/* <Box
          display="flex"
          mb={3}
          p={3}
          flexDirection="column"
          // style={{ backgroundColor: '#d5f5f0' }}
          style={{ backgroundColor: '#f8faf9' }}
        >
          <h3 className="heading-sans-serif mb-0">Complete your account</h3>
          <p className="">
            Enter a password to save your information next time you order.
          </p>

          <TextField
            label="Password"
            name="password"
            type={passwordVisible ? 'text' : 'password'}
            fullWidth
            style={{
              paddingRight: '6px',
            }}
            InputProps={{
              endAdornment: passwordVisible ? (
                <IconButton
                  style={{
                    position: 'absolute',
                    right: 0,
                  }}
                  size="small"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  <VisibilityIcon
                    style={{ cursor: 'pointer', color: '#b2b9b6' }}
                  />
                </IconButton>
              ) : (
                <IconButton
                  style={{
                    position: 'absolute',
                    right: 0,
                  }}
                  size="small"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  <VisibilityOffIcon
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    style={{ cursor: 'pointer', color: '#b2b9b6' }}
                  />
                </IconButton>
              ),
            }}
          />

          <Box mt={3}></Box>

          <PrimaryButton
            variant="contained"
            color="secondary"
            style={{
              boxShadow: 'none',
              borderRadius: '0',
              marginTop: '8px',
              padding: '12px 32px',
              textTransform: 'none',
              fontSize: '1em',
              fontFamily: 'Inter-SemiBold, sans-serif',
              transition: 'all .25s ease',
              color: '#000',
            }}
            disabled={submittingEmailReceipt}
            onClick={handleSendEmailReceipt}
          >
            {submittingEmailReceipt ? (
              <ButtonLoader />
            ) : (
              <>
                {' '}
                <LockIcon style={{ marginRight: '8px' }} />
                Create account
              </>
            )}
          </PrimaryButton>
        </Box> */}

        <Button
          variant="contained"
          color="secondary"
          style={{
            boxShadow: "none",
            borderRadius: "0",
            marginTop: "8px",
            padding: "12px 32px",
            textTransform: "none",
            fontSize: "1em",
            fontFamily: "Inter-SemiBold, sans-serif",
            transition: "all .25s ease",
            background: "rgba(0, 0, 0, 0.12)",
            // border: '2px solid rgba(0, 0, 0, 0.12)',
            color: "#000",
          }}
          disabled={submittingEmailReceipt}
          onClick={handleSendEmailReceipt}
        >
          {submittingEmailReceipt ? (
            <ButtonLoader />
          ) : (
            <>
              <EmailIcon style={{ marginRight: "8px" }} />
              Email receipt
            </>
          )}
        </Button>

        <Button
          variant="contained"
          color="secondary"
          style={{
            boxShadow: "none",
            borderRadius: "0",
            marginTop: "8px",
            padding: "12px 32px",
            textTransform: "none",
            fontSize: "1em",
            fontFamily: "Inter-SemiBold, sans-serif",
            transition: "all .25s ease",
            background: "rgba(0, 0, 0, 0.12)",
            // border: '2px solid rgba(0, 0, 0, 0.12)',
            color: "#000",
          }}
          onClick={() => {
            console.log("cancel order");
            if (props.placedOrder) {
              dispatch(setCurrentCartTab("cancel-order"));
            } else {
              dispatch(setCurrentGuestReceiptTab("cancel-order"));
            }
          }}
        >
          <CloseIcon style={{ marginRight: "8px" }} /> Cancel order
        </Button>

        <a
          style={{
            textAlign: "center",
            marginTop: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="link color-secondary-2"
          href="#"
          onClick={(e: any) => {
            if (viaCart) {
              dispatch(setCurrentCartTab("report-a-problem"));
            } else {
              dispatch(setCurrentGuestReceiptTab("report-a-problem"));
            }
            e.preventDefault();
          }}
        >
          <FeedbackIcon style={{ marginRight: "8px" }} /> Report a problem
        </a>
      </Box>
    </>
  );
};
