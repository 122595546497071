export default function (
  cartItems: Array<object>,
  itemId: string,
  extraId: string
) {
  const itemIndex = cartItems.findIndex((e: any) => e.id === itemId);

  if (itemIndex == -1) {
    return 0;
  }

  //@ts-ignore
  const extraIndex = cartItems[itemIndex].selectedExtras.findIndex(
    (e: any) => e._id == extraId
  );

  if (extraIndex == -1) {
    return 0;
  }

  //@ts-ignore
  return cartItems[itemIndex].selectedExtras[extraIndex].quantity;
}
