/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import currency from "currency.js";
import * as Yup from "yup";
import moment, { MomentInput } from "moment";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Switch,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TodayIcon from "@mui/icons-material/Today";
import HomeIcon from "@mui/icons-material/Home";
import BusinessIcon from "@mui/icons-material/Business";
import WorkIcon from "@mui/icons-material/Work";
import AutorenewIcon from "@mui/icons-material/Autorenew";

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import feathers from "../../feathers";

import { open as openSnackbar } from "../../slices/snackbarReducer";

import SideNavItem from "../SideNavItem/SideNavItem";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";
import { createOrder, resetOrder, updateOrder } from "../../slices/orderSlice";
import { resetCart } from "../../slices/cartReducer";
import { setCurrentCartTab, setCurrentTab } from "../../slices/authNavReducer";
import calculateOrderLineItems from "../../modules/calculateOrderLineItems";
import cartContainsMeals from "../../modules/cartContainsMeals";
import CartItem from "../CartItem/CartItem";
import getExtrasTotalOnWholeOrder from "../../modules/getExtrasTotalOnWholeOrder";
import { useAppDispatch, useAppSelector } from "../../hooks";
import CardBrandImage from "../CardBrandImage/CardBrandImage";

import axios from "axios";
import { StripeCardElement } from "@stripe/stripe-js";
import { addPaymentMethodSuccess } from "../../slices/subscriptionReducer";

const stripeP = require("stripe")("sk_test_z5R5jzFu07WDOnzuvzgTLyqD");

export default function () {
  const dispatch = useAppDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const { t, i18n }: any = useTranslation();

  const cardStyle: any = {
    style: {
      base: {
        marginTop: "16px",
        fontSmoothing: "antialiased",
        fontSize: "18px",
        padding: "8px",
        fontWeight: "normal",
        lineHeight: "26px",
        fontFamily: "Calibre, Tahoma, sans-serif",
        ":placeholder": {
          color: "#4a4a4a",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const [autoRenewConfirmDialogOpen, setAutoRenewConfirmDialogOpen] =
    React.useState(false);
  const [autoRenewStatus, setAutoRenewStatus] = React.useState(true);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [disabled, setDisabled] = React.useState(true);
  const [cardInfo, setCardInfo] = React.useState(null);

  useEffect(() => {
    console.log("CardInfo", cardInfo);
  }, [cardInfo]);

  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  const subscription: any = useAppSelector(
    (state) => state.subscription.subscription
  );

  const order: any = useAppSelector((state) => state.order.order);

  const paymentMethods: any = useAppSelector(
    (state) => state.subscription.paymentMethods
  );

  const selectedWeek = useAppSelector(
    (state) => state.organization.selectedWeek
  );

  const selectedMealPlan: null | any = useAppSelector(
    (state) => state.cart.selectedMealPlan
  );

  const selectedMealPlanName = useAppSelector(
    (state) => state.cart.selectedMealPlanName
  );

  const cartItems = useAppSelector((state) => state.cart.items);

  const cartTotal = useAppSelector((state) =>
    state.cart.items.length === 0
      ? 0
      : state.cart.items.reduce(
          (acc, curr) => acc + curr.quantity * curr.price,
          0
        )
  );

  const cartGroceryTotal = useAppSelector((state) =>
    state.cart.items.filter((e: any) => e.type === "Grocery").length === 0
      ? 0
      : state.cart.items
          .filter((e: any) => e.type === "Grocery")
          .reduce((acc, curr) => acc + curr.quantity * curr.price, 0)
  );

  const user: any = useAppSelector(
    (state) => state.auth.user
    // state.auth.isAuthenticated ? state.auth.user : state.order.order.profile
  );

  const handleChange = async (event: any) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const completePayment = async () => {
    setSubmitting(true);

    const currentOrder = { ...order };
    const currentUser = { ...user };

    let orderToCreate = {
      address: currentOrder.address,
      delivery: currentOrder.delivery,
      profile: currentOrder.profile,
    };

    if (isAuthenticated) {
      orderToCreate.address = currentUser.address[0];
      orderToCreate.profile = {
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        emailAddress: currentUser.email,
        phone: currentUser.phone,
      };
    }

    const orderSchema = Yup.object().shape({
      address: Yup.object({
        canadaPostId: Yup.string().required(),
        addressType: Yup.string().required(),
        deliveryOption: Yup.string().required("Delivery option is required"),
        notes: Yup.string(),
        deliveryAddress: Yup.string(),
        buzzer: Yup.string(),
        organization: Yup.string(),
      }).nullable(),
      profile: Yup.object({
        firstName: Yup.string().required("First name is required"),
        lastName: Yup.string().required("Last name is required"),
        emailAddress: Yup.string()
          .email("Enter a valid email address")
          .required("Email is required"),
        phone: Yup.string().required("Phone number is required"),
      }),
      delivery: Yup.object({
        date: Yup.date().required("Delivery date is required"),
        type: Yup.string().required("Delivery type is required"),
      }),
    });

    console.log("orderSchema", orderSchema);

    const isOrderValid = await orderSchema.isValid(orderToCreate);

    console.log("Order is valid", isOrderValid);
    console.log(isOrderValid);

    if (!isOrderValid) {
      try {
        const validation = await orderSchema.validate(orderToCreate);
        console.log(validation);
        console.log(orderToCreate);
      } catch (error) {
        console.log(error);
      }

      dispatch(
        openSnackbar({
          message: "Complete all required fields",
          severity: "error",
        })
      );

      // dispatch()/
      setSubmitting(false);
      console.log("Order is not valid");
      return;
    }

    const orderLineItems = calculateOrderLineItems({
      lineItems: cartItems,
      deliveryType: order.delivery.type,
      deliveryTime: order.delivery.time,
      selectedWeek,
      selectedMealPlanName,
      selectedMealPlan,
    });

    console.log(
      "order",
      orderLineItems,
      cardInfo,
      cartContainsMeals(cartItems),
      selectedMealPlan,
      selectedMealPlanName
    );

    let payload: any = null;

    let paymentMethod: any = null;

    if (
      !cardInfo &&
      cartContainsMeals(cartItems) &&
      selectedMealPlanName !== "" &&
      selectedMealPlanName !== "a-la-carte"
    ) {
      console.log("AAAAAA");
      if (!stripe || !elements) {
        return;
      }

      const cardElement: StripeCardElement | any =
        elements.getElement(CardElement);

      console.log("Subscripot", subscription.stripe_customer_id);

      // if (!subscription.stripe_customer_id) {
      //   const customer = await stripeP.customers.create({
      //     metadata: {
      //       customerId: subscription.customerId,
      //       subscriptionId: subscription._id,
      //     },
      // name: user.firstName + " " + user.lastName,
      //         email: user.email,
      //         phone: user.phone,
      //     email: props.email,
      //     name: props.firstName + " " + props.lastName,
      //     phone: props.phone,
      //   });

      //   console.log("customer", customer);

      //   await axios.post(
      //     `${process.env.REACT_APP_PROD_API_URL}/addCustomer`,
      //     {
      //       customer_id: subscription._id,
      //       customerId: customer?.id,
      //     }
      //   );
      // }

      //@ts-ignore
      paymentMethod = await stripe.createPaymentMethod({
        type: "card",
        //@ts-ignore
        card: cardElement,
      });

      console.log("subsc", subscription, paymentMethod.paymentMethod?.id);

      try {
        const log = await axios.post(
          `${process.env.REACT_APP_PROD_API_URL}/addCard`,
          {
            customer_id: subscription._id,
            pyamentId: paymentMethod.paymentMethod?.id,
          }
        );
        console.log("log", log);

        dispatch(addPaymentMethodSuccess(paymentMethod.paymentMethod));
      } catch (error) {
        console.log("Payment error", error);
      }

      // const setupIntent = await feathers
      //   .service("/stripe/setup-intent")
      //   .create({
      //     customer: subscription.stripe_customer_id,
      //     payment_method_types: ["card"],
      //     usage: "off_session",
      //   });

      // // @ts-ignore
      // const cardSetupResult = await stripe.confirmCardSetup(
      //   //@ts-ignore
      //   setupIntent.client_secret,
      //   //@ts-ignore
      //   {
      //     payment_method: {
      //       //@ts-ignore
      //       card: elements.getElement(CardElement),
      //       billing_details: {
      //         name: user.firstName + " " + user.lastName,
      //         email: user.email,
      //         phone: user.phone,
      //       },
      //     },
      //   }
      // );

      // if (cardSetupResult.error) {
      //   // enqueueSnackbar('There was a problem adding card', {
      //   //   variant: 'error',
      //   // });
      //   console.log(cardSetupResult.error);

      //   dispatch(
      //     openSnackbar({
      //       // message: cardSetupResult.error.message || cardSetupResult.error,
      //       severity: "error",
      //     })
      //   );

      //   // Display cardSetupResult.error.message in your UI.
      // }

      // console.log(cardSetupResult);
      //@ts-ignore
      // console.log(cardSetupResult.setupIntent.payment_method);
    } else {
      // payload = await feathers.service("/stripe/payment-intent").create({
      //   amount: currency(orderLineItems.total).intValue,
      //   currency: "CAD",
      //   customer: {},
      //   capture_method: "automatic",
      // });
      //@ts-ignore
      // payload = await stripe.confirmCardPayment(
      //   paymentIntent.client_secret,
      //   //@ts-ignore
      //   {
      //     setup_future_usage: "off_session",
      //     //@ts-ignore
      //     payment_method: {
      //       //@ts-ignore
      //       card: elements.getElement(CardElement),
      //       billing_details: {
      //         name: user.firstName + " " + user.lastName,
      //         email: user.email,
      //         phone: user.phone,
      //       },
      //     },
      //   }
      // );
      //@ts-ignore
      // if (payload.hasOwnProperty("error")) {
      //   dispatch(
      //     openSnackbar({
      //       //@ts-ignore
      //       message: payload.error.message,
      //       severity: "error",
      //     })
      //   );
      //   setSubmitting(false);
      //   return;
      // }
    }

    console.log("iiiiiii");

    payload = await stripeP.paymentIntents.create({
      amount: currency(orderLineItems.total).intValue,
      currency: "CAD",
      payment_method_types: ["card"],
      capture_method: "automatic",
    });

    console.log("payload", payload);

    try {
      let orderToCreate: any = {
        customer: {
          ...order.profile,
          language: i18n.language.toUpperCase(),
        },
        address: order.address,
        delivery: order.delivery,
        selectedWeek,

        autoRenew: false,

        total: orderLineItems.total,
        lineItems: cartItems,

        type: "grocery",
        guest: true,

        status: "placed",
        paymentStatus: "authorized",
        fulfillmentStatus: "unfulfilled",

        paymentType: "card",
      };

      // if (cartContainsMeals(cartItems)) {
      //   orderToCreate.type = "combined";
      // }

      // console.log("cartItem", cartItems, selectedMealPlan);

      if (
        cartItems.filter((e: any) => e.type === "Meals").length > 0 &&
        cartItems.filter((e: any) => e.type === "Grocery").length > 0
      ) {
        orderToCreate.type = "combined";
      }

      if (isAuthenticated) {
        orderToCreate.guest = false;
        orderToCreate.address = currentUser.address[0];
        orderToCreate.customer = {
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
          emailAddress: currentUser.email,
          phone: currentUser.phone,
          language: i18n.language.toUpperCase(),
        };
      }

      // if (
      //   !cartContainsMeals(cartItems) ||
      //   selectedMealPlanName === "a-la-carte"
      // ) {
      //   orderToCreate.paymentIntentId = payload.paymentIntent?.id;
      // }

      console.log("payload", payload, cardInfo);
      orderToCreate.paymentIntentId = payload?.id;
      if (cardInfo) {
        // @ts-ignore
        orderToCreate.paymentMethodId = cardInfo.id;
      } else {
        orderToCreate.paymentMethodId = paymentMethod.paymentMethod.id;
      }

      if (
        selectedMealPlan !== null &&
        selectedMealPlanName !== "" &&
        selectedMealPlanName !== "a-la-carte"
      ) {
        orderToCreate.type = "mealPlan";
        orderToCreate.selectedMealPlan = selectedMealPlan;
        orderToCreate.selectedMealPlanName = selectedMealPlanName;
        orderToCreate.autoRenew = autoRenewStatus;
      } else if (selectedMealPlanName === "a-la-carte") {
        orderToCreate.selectedMealPlan = selectedMealPlan;
        orderToCreate.selectedMealPlanName = selectedMealPlanName;
        orderToCreate.autoRenew = false;
      }

      console.log("create info", orderToCreate);

      dispatch(
        createOrder(orderToCreate, () => {
          dispatch(
            openSnackbar({
              message: "Order placed successfully.",
              severity: "success",
            })
          );
          // document.body.classList.toggle('cart--open');
          setSubmitting(false);
          // dispatch(resetOrder());
          dispatch(resetCart());
          dispatch(setCurrentCartTab("receipt"));
        })
      );
    } catch (error) {
      setSubmitting(false);
      console.log("IN CATCH");
      console.log(error);
    }
  };

  const renderAddress = (address: any) => {
    return (
      <>
        <p
          className="font-weight-500 font-weight-medium"
          style={{
            fontFamily: "Inter-Regular, sans-serif",
            margin: 0,
          }}
        >
          {address.organization.length !== 0 && (
            <>
              {address.organization} <br />
            </>
          )}
          {address.Line1}, {address.Line2}
          <br />
          {address.City}, {address.Province} {address.PostalCode}
          {address.buzzer !== "" && (
            <>
              <span>
                <br />
                Buzzer: {address.buzzer}
              </span>
            </>
          )}
        </p>
        {/* {address.primary && (
          <p
            style={{
              marginTop: 0,
              marginBottom: 0,
              fontSize: '14px',
              color: '#b9b2b6',
            }}
          >
            Primary
          </p>
        )} */}
      </>
    );
  };

  const lineItemData = calculateOrderLineItems({
    lineItems: cartItems,
    deliveryType: order.delivery.type,
    deliveryTime: order.delivery.time,
    selectedWeek,
    selectedMealPlanName,
    selectedMealPlan,
  });

  const checkCardExpiring = (month: String, year: String) => {
    const currentMonth = moment().format("M");
    const currentYear = moment().format("YYYY");

    if (year !== currentYear) {
      return "";
    }

    //@ts-ignore
    if (parseInt(month) < parseInt(currentMonth)) {
      return;
    }
    //@ts-ignore
    const monthDifference = parseInt(month) - parseInt(currentMonth);

    if (monthDifference === 1) {
      return (
        <Typography variant="body2" color="error">
          Expires next month
        </Typography>
      );
    } else if (monthDifference > 1 && monthDifference <= 3) {
      return (
        <Typography variant="body2" color="error">
          Expires in {monthDifference} months
        </Typography>
      );
    }
  };

  return (
    <div className="form-login">
      <h2 className="heading-sans-serif">{t("contact")}</h2>
      <p className="offscreen-menu__content__section-heading">{t("Profile")}</p>

      {isAuthenticated && user !== undefined && (
        <SideNavItem
          noBorder
          // onClick={() => dispatch(setCurrentTab("profile"))}
        >
          <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center">
              <Avatar
                style={{
                  marginRight: "10px",
                  width: "32px",
                  height: "32px",
                }}
                src={
                  //@ts-ignore
                  user !== null &&
                  (user.hasOwnProperty("facebookId") ? user.picture.url : "")
                }
              />
              <Box display="flex" flexDirection="column">
                <p
                  className="font-weight-500 font-weight-medium"
                  style={{
                    fontFamily: "Inter-Regular, sans-serif",
                    margin: 0,
                  }}
                >
                  {
                    //@ts-ignore
                    user !== null &&
                      //@ts-ignore
                      user.firstName &&
                      //@ts-ignore
                      `${user.firstName} ${user.lastName}`
                  }
                </p>
              </Box>
            </Box>
          </Box>
        </SideNavItem>
      )}

      {!isAuthenticated && (
        <SideNavItem
          noBorder
          onClick={() => dispatch(setCurrentCartTab("add-contact"))}
        >
          <Box display="flex" alignItems="center">
            {order.hasOwnProperty("profile") && (
              <Box display="flex" alignItems="center" paddingRight="8px">
                {order.profile.firstName === "" &&
                order.profile.lastName === "" ? (
                  <PersonAddIcon
                    style={{
                      width: "32px",
                      height: "32px",
                    }}
                    color="secondary"
                  />
                ) : (
                  <PersonIcon
                    style={{
                      width: "32px",
                      height: "32px",
                    }}
                    color="secondary"
                  />
                )}
              </Box>
            )}

            {order.hasOwnProperty("profile") && (
              <>
                {order.profile.firstName !== "" &&
                order.profile.lastName !== "" ? (
                  <>
                    <Box>
                      <p
                        className="font-weight-500 font-weight-medium"
                        style={{
                          fontFamily: "Inter-Regular, sans-serif",
                          margin: 0,
                        }}
                      >
                        {
                          //@ts-ignore
                          order.profile.firstName + " " + order.profile.lastName
                        }
                      </p>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box>
                      <p
                        className="font-weight-500 font-weight-medium"
                        style={{
                          fontFamily: "Inter-Regular, sans-serif",
                          margin: 0,
                        }}
                      >
                        + {t("addProfile")}
                      </p>
                    </Box>
                  </>
                )}
              </>
            )}
          </Box>
        </SideNavItem>
      )}

      <Box mt={4}>
        <p className="offscreen-menu__content__section-heading">
          {t("Delivery")}
        </p>
      </Box>

      <SideNavItem
        noBorder
        onClick={() => dispatch(setCurrentCartTab("select-delivery-date"))}
      >
        <Box display="flex" alignItems="center">
          <Box display="flex" alignItems="center" paddingRight="8px">
            <TodayIcon
              style={{
                width: "32px",
                height: "32px",
              }}
              color="secondary"
            />
          </Box>

          <Box>
            {order.delivery.type !== "" ? (
              <>
                <p
                  className="font-weight-500 font-weight-medium"
                  style={{
                    fontFamily: "Inter-Regular, sans-serif",
                    margin: 0,
                  }}
                >
                  {moment(order.delivery.date).format("dddd, MMMM Do") ||
                    moment().format("dddd, MMMM Do")}
                </p>

                <p
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    fontSize: "14px",
                    color: "#b9b2b6",
                  }}
                >
                  {order.delivery.type === "delivery"
                    ? "2:30-6:30 p.m."
                    : "Pickup 2:30-4:00 p.m."}
                </p>
              </>
            ) : (
              <p
                className="font-weight-500 font-weight-medium"
                style={{
                  fontFamily: "Inter-Regular, sans-serif",
                  margin: 0,
                }}
              >
                + Select a delivery or pickup date
              </p>
            )}
          </Box>
        </Box>
      </SideNavItem>

      {isAuthenticated && user !== null && order.type !== "pickup" && (
        <>
          <SideNavItem
            onClick={() => {
              // dispatch(setSelectedAddressIndex(index));
              // dispatch(setCurrentCartTab("edit-address"));
            }}
          >
            <Box display="flex" alignItems="center">
              <Box display="flex" alignItems="center" paddingRight="8px">
                {user.address[0].addressType === "home" && (
                  <HomeIcon
                    style={{
                      width: "32px",
                      height: "32px",
                    }}
                    color="secondary"
                  />
                )}

                {user.address[0].addressType === "apartment" && (
                  <BusinessIcon
                    style={{
                      width: "32px",
                      height: "32px",
                    }}
                    color="secondary"
                  />
                )}

                {user.address[0].addressType === "business" && (
                  <WorkIcon
                    style={{
                      width: "32px",
                      height: "32px",
                    }}
                    color="secondary"
                  />
                )}
              </Box>
              <Box>{renderAddress(user.address[0])}</Box>
            </Box>
          </SideNavItem>
        </>
      )}

      {/* {!isAuthenticated && order.delivery.type !== "pickup" && (
        <SideNavItem
          noBorder
          onClick={() => dispatch(setCurrentCartTab("add-address"))}
        >
          <Box display="flex" alignItems="center">
            {order.address !== null ? (
              <>
                <Box display="flex" alignItems="center" paddingRight="8px">
                  {order.address.addressType === "home" && (
                    <HomeIcon
                      style={{
                        width: "32px",
                        height: "32px",
                      }}
                      color="secondary"
                    />
                  )}

                  {order.address.addressType === "apartment" && (
                    <BusinessIcon
                      style={{
                        width: "32px",
                        height: "32px",
                      }}
                      color="secondary"
                    />
                  )}

                  {order.address.addressType === "business" && (
                    <WorkIcon
                      style={{
                        width: "32px",
                        height: "32px",
                      }}
                      color="secondary"
                    />
                  )}
                </Box>
                <Box>{renderAddress(order.address)}</Box>
              </>
            ) : (
              <>
                <Box display="flex" alignItems="center" paddingRight="8px">
                  <LocalShippingIcon
                    style={{
                      width: "32px",
                      height: "32px",
                    }}
                    color="secondary"
                  />
                </Box>
                <Box>
                  <p
                    className="font-weight-500 font-weight-medium"
                    style={{
                      fontFamily: "Inter-Regular, sans-serif",
                      margin: 0,
                    }}
                  >
                    + {t("addDeliveryAddress")}
                  </p>
                </Box>
              </>
            )}
          </Box>
        </SideNavItem>
      )} */}

      <Box mt={4}></Box>

      <Divider />

      <h2 className="heading-sans-serif">{t("Payment")}</h2>

      {/* <Box mt={4} mb={2}>
        <div className="stripe-form-wrapper">
          <CardElement
            id="card-element"
            options={cardStyle}
            onChange={handleChange}
          />

          {error !== "" && <Typography color="error">{error}</Typography>}
        </div>
      </Box> */}

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "#000" }} />}
          className="background-filled"
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box width={"100%"}>
            <div className="stripe-form-wrapper">
              <CardElement
                id="card-element"
                options={cardStyle}
                onChange={handleChange}
              />
              {error !== "" && <Typography color="error">{error}</Typography>}
            </div>
          </Box>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: "column" }}>
          {paymentMethods &&
            paymentMethods.map((card: any, index: number) => (
              <SideNavItem
                noBorder
                key={index}
                onClick={() => setCardInfo(card)}
              >
                {console.log("id----", index)}
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" alignItems="center">
                    <CardBrandImage brandName={card.card.brand} />
                    <Box>
                      <p
                        className="font-weight-500 font-weight-medium"
                        style={{
                          fontFamily: "Inter-Regular, sans-serif",
                          margin: 0,
                        }}
                      >
                        •••{card.card.last4}
                      </p>
                      <p
                        style={{
                          marginTop: 0,
                          marginBottom: 0,
                          fontSize: "14px",
                          color: "#b9b2b6",
                        }}
                      >
                        Expires{" "}
                        {card.card.exp_month > 10
                          ? card.card.exp_month
                          : `0${card.card.exp_month}`}
                        /{card.card.exp_year}
                      </p>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="row">
                    {checkCardExpiring(card.card.exp_month, card.card.exp_year)}
                  </Box>
                </Box>
              </SideNavItem>
            ))}
        </AccordionDetails>
      </Accordion>

      {/* <SideNavItem
        noBorder
        onClick={() => setCurrentTab("select-delivery-date")}
      >
        <Box display="flex" alignItems="center" paddingY={1}>
          <Box display="flex" alignItems="center" paddingRight="8px">
            <img
              style={{ marginRight: "8px", width: "32px" }}
              src="/card-icons/vittle-gift-card.svg"
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <p
              className="font-weight-500 font-weight-medium"
              style={{
                fontFamily: "Inter-Regular, sans-serif",
                margin: 0,
              }}
            >
              •••29AS
            </p>
            <p
              className="font-weight-500 font-weight-medium"
              style={{
                fontFamily: "Inter-Regular, sans-serif",
                margin: 0,
                color: "#999",
              }}
            >
              Balance: $500.00
            </p>
          </Box>
        </Box>
      </SideNavItem> */}

      {/* <SideNavItem
        noBorder
        onClick={() => setCurrentTab("select-delivery-date")}
      >
        <Box display="flex" alignItems="center" paddingY={1}>
          <Box display="flex" alignItems="center" paddingRight="8px">
            <img
              style={{ marginRight: "8px", width: "32px" }}
              src="/card-icons/vittle-gift-card.svg"
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <p
              className="font-weight-500 font-weight-medium"
              style={{
                fontFamily: "Inter-Regular, sans-serif",
                margin: 0,
              }}
            >
              BLACKFRIDAY
            </p>
            <p
              className="font-weight-500 font-weight-medium"
              style={{
                fontFamily: "Inter-Regular, sans-serif",
                margin: 0,
                color: "#999",
              }}
            >
              $10.00 off
            </p>
          </Box>
        </Box>
      </SideNavItem> */}

      {/* <SideNavItem
          noBorder
          onClick={() => setCurrentTab("add-gift-card-or-discount-code")}
        >
          <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center" paddingRight="8px">
              <RedeemIcon
                style={{
                  width: "32px",
                  height: "32px",
                }}
                color="secondary"
              />
            </Box>
            <Box>
              <p
                className="font-weight-500 font-weight-medium"
                style={{
                  fontFamily: "Inter-Regular, sans-serif",
                  margin: 0,
                }}
              >
                + Add gift card or discount code
              </p>
            </Box>
          </Box>
        </SideNavItem> */}

      {/* {isAuthenticated &&
        user !== null &&
        subscription.paymentMethod === "" && (
          <SideNavItem noBorder>
            <Box display="flex" alignItems="center">
              <Box display="flex" alignItems="center" paddingRight="8px">
                <CreditCardIcon
                  style={{
                    width: "32px",
                    height: "32px",
                  }}
                  color="secondary"
                />
              </Box>
              <Box>
                <p
                  className="font-weight-500 font-weight-medium"
                  style={{
                    fontFamily: "Inter-Regular, sans-serif",
                    margin: 0,
                  }}
                >
                  + Add a payment method
                </p>
              </Box>
            </Box>
          </SideNavItem>
        )} */}

      {/* <p
        className="text-center color-secondary-2"
        style={{ marginTop: "20px", fontSize: "14px" }}
      >
        Have a gift card or a promo code?{" "}
        <a
          href="#"
          // onClick={handleSignUpLink}
          className="link color-primary text-center"
        >
          {" "}
          Apply
        </a>
      </p>
      <Grid container>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" width="100%">
            <>
              <TextField
                style={{ width: "90%" }}
                variant="outlined"
                margin="dense"
                label="Enter a gift card or a promo code"
                color="secondary"
              />{" "}
              <Button
                size="medium"
                style={{
                  position: "relative",
                  top: "-4px",
                  boxShadow: "none",
                  background: "rgba(0,0,0,.09)",
                  marginLeft: "4px",
                  lineHeight: "2",
                }}
                variant="contained"
              >
                Apply
              </Button>
            </>
          </Box>
        </Grid>
      </Grid> */}

      <Box mt={3}></Box>

      <Divider />

      <h2 className="heading-sans-serif">Summary</h2>

      <div className="cart-line-items-container cart-line-items-contaner--confirm-order">
        {cartItems.filter((e: any) => e.type === "Meals").length > 0 && (
          <Accordion defaultExpanded={true} onClick={() => null}>
            {/* <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#000" }} />}
              className="background-filled"
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Box
                    display="flex"
                    width="100%"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box display="flex" alignItems="center" width="100%">
                      <RestaurantMenuIcon
                        style={{
                          marginRight: "8px",
                          fill: "#707e81",
                        }}
                      />{" "}
                      <Box>
                        <p className="offscreen-menu__content__section-heading fw-400 mb-0 mt-0">
                          {selectedMealPlanName === "a-la-carte"
                            ? "Prepared Meals"
                            : selectedMealPlanName}
                        </p>
                        {selectedMealPlanName !== "a-la-carte" && (
                          <p
                            style={{
                              marginTop: 0,
                              marginBottom: 0,
                              fontSize: "14px",
                              color: "#b9b2b6",
                            }}
                          >
                            {
                              cartItems.filter((e: any) => e.type === "Meals")
                                .length
                            }{" "}
                            {i18n.language === "fr" ? "produit" : "item"}
                            {cartItems.filter((e: any) => e.type === "Meals")
                              .length > 1
                              ? "s"
                              : ""}
                            {
                              cartItems.filter((e: any) => e.type === "Meals")
                                .length
                            }{" "}
                            of{" "}
                            {selectedMealPlanName === "1 meal per day"
                              ? "5"
                              : selectedMealPlanName === "2 meals per day"
                              ? "10"
                              : selectedMealPlanName === "3 meals per day"
                              ? "15"
                              : ""}{" "}
                            items
                          </p>
                        )}
                      </Box>
                    </Box>
                  </Box>

                  <Box display="flex" alignItems="center">
                    <p
                      className="offscreen-menu__content__section-heading fw-400"
                      style={{ paddingRight: "8px" }}
                    >
                      {renderPresetMealPlanAmount()}
                    </p>
                  </Box>
                </Box>
              </Box>
            </AccordionSummary> */}
            <AccordionDetails style={{ flexDirection: "column" }}>
              <p
                style={{
                  fontWeight: "bold",
                  padding: "0 24px",
                  marginBottom: 0,
                }}
              >
                {moment(order.delivery.date).format("dddd, MMMM Do") ||
                  moment().format("dddd, MMMM Do")}
              </p>

              {cartItems
                .filter((e: any) => e.type === "Meals")
                .map((e: any, index: number) => (
                  <CartItem item={e} index={index} />
                ))}
            </AccordionDetails>
          </Accordion>
        )}

        {cartItems.filter((e: any) => e.type === "Grocery").length > 0 && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#000" }} />}
              className="background-filled"
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Box display="flex" alignItems="center">
                    <ShoppingCartIcon
                      style={{
                        marginRight: "8px",
                        fill: "#707e81",
                      }}
                    />{" "}
                    <Box>
                      <p className="offscreen-menu__content__section-heading fw-400 mb-0 mt-0">
                        Grocery
                      </p>
                      <p
                        style={{
                          marginTop: 0,
                          marginBottom: 0,
                          fontSize: "14px",
                          color: "#b9b2b6",
                        }}
                      >
                        {" "}
                        {
                          cartItems.filter((e: any) => e.type === "Grocery")
                            .length
                        }{" "}
                        items
                      </p>
                    </Box>
                  </Box>

                  <p
                    className="offscreen-menu__content__section-heading fw-400"
                    style={{ paddingRight: "8px" }}
                  >
                    {currency(cartGroceryTotal).format()}
                  </p>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails style={{ flexDirection: "column" }}>
              {cartItems
                .filter((e: any) => e.type === "Grocery")
                .map((e: any, index: number) => (
                  <CartItem discountPrice={10} item={e} index={index} />
                ))}
            </AccordionDetails>
          </Accordion>
        )}
      </div>

      <Divider />

      <Box display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <p className="offscreen-menu__content__section-heading mb-0 fw-400--family">
            Subtotal
          </p>
          <p className="offscreen-menu__content__section-heading fw-400--family mb-0">
            {i18n.language === "fr"
              ? currency(
                  calculateOrderLineItems({
                    lineItems: cartItems,
                    deliveryType: order.delivery.type,
                    deliveryTime: order.delivery.time,
                    selectedWeek,
                    selectedMealPlan,
                    selectedMealPlanName,
                  }).subTotalAcutal,
                  {
                    pattern: "# !",
                    separator: " ",
                    decimal: ",",
                  }
                ).format()
              : currency(
                  calculateOrderLineItems({
                    lineItems: cartItems,
                    deliveryType: order.delivery.type,
                    deliveryTime: order.delivery.time,
                    selectedWeek,
                    selectedMealPlan,
                    selectedMealPlanName,
                  }).subTotalAcutal
                ).format()}
          </p>
        </Box>

        {selectedMealPlan !== null &&
          selectedMealPlanName !== "" &&
          selectedMealPlanName !== "a-la-carte" &&
          calculateOrderLineItems({
            lineItems: cartItems,
            deliveryType: order.delivery.type,
            deliveryTime: order.delivery.time,
            selectedWeek,
            selectedMealPlan,
            selectedMealPlanName,
          }).savings <
            calculateOrderLineItems({
              lineItems: cartItems,
              deliveryType: order.delivery.type,
              deliveryTime: order.delivery.time,
              selectedWeek,
              selectedMealPlan,
              selectedMealPlanName,
            }).subTotal && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <p
                className="offscreen-menu__content__section-heading mt-0 mb-0 fw-400--family color-primary-dark"
                style={{ marginTop: "10px" }}
              >
                Savings
              </p>
              <p
                className="offscreen-menu__content__section-heading fw-400--family mt-0 mb-0"
                style={{ marginTop: "10px" }}
              >
                <span className="color-primary-dark">
                  -
                  {i18n.language === "fr"
                    ? currency(
                        calculateOrderLineItems({
                          lineItems: cartItems,
                          deliveryType: order.delivery.type,
                          deliveryTime: order.delivery.time,
                          selectedWeek,
                          selectedMealPlan,
                          selectedMealPlanName,
                        }).savings,
                        {
                          pattern: "# !",
                          separator: " ",
                          decimal: ",",
                        }
                      ).format()
                    : currency(
                        calculateOrderLineItems({
                          lineItems: cartItems,
                          deliveryType: order.delivery.type,
                          deliveryTime: order.delivery.time,
                          selectedWeek,
                          selectedMealPlan,
                          selectedMealPlanName,
                        }).savings
                      ).format()}
                </span>
              </p>
            </Box>
          )}

        {getExtrasTotalOnWholeOrder(cartItems) > 0 && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <p
              className="offscreen-menu__content__section-heading mb-0 mt-1 fw-400--family"
              style={{ marginTop: "10px" }}
            >
              Extras
            </p>
            <p
              className="offscreen-menu__content__section-heading mb-0 mt-1 fw-400--family"
              style={{ marginTop: "10px" }}
            >
              {currency(getExtrasTotalOnWholeOrder(cartItems)).format()}
            </p>
          </Box>
        )}

        {/* <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={1.5}
        >
          <p
            className="offscreen-menu__content__section-heading mb-0 fw-400--family mt-0"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            Discount
          </p>
          <p className="offscreen-menu__content__section-heading color-primary-dark fw-400 fw-400--family mb-0 mt-0">
            -$50.00
          </p>
        </Box> */}

        {order.delivery.type !== "pickup" && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <p
              className="offscreen-menu__content__section-heading mb-0 mt-1 fw-400--family"
              style={{ marginTop: "10px" }}
            >
              Delivery fee
            </p>

            {cartContainsMeals(cartItems) ? (
              <p
                className="offscreen-menu__content__section-heading fw-400--family mb-0 mt-1"
                style={{ marginTop: "10px" }}
              >
                {cartTotal < 100 ? "$10.00" : "Free"}
                {/* {calculateDeliveryFee(
                  cartItems,
                  selectedWeek,
                  order.delivery.time,
                  selectedMealPlanName,
                  selectedMealPlan
                ) > 0 ? (
                  <span>
                    {currency(
                      calculateDeliveryFee(
                        cartItems,
                        selectedWeek,
                        order.delivery.time,
                        selectedMealPlanName,
                        selectedMealPlan
                      )
                    ).format()}
                  </span>
                ) : (
                  <span className="color-primary-dark">Free</span>
                )} */}
              </p>
            ) : (
              <p
                className="offscreen-menu__content__section-heading fw-400--family mb-0 mt-1"
                style={{ marginTop: "10px" }}
              >
                {cartTotal > 100 ? (
                  <span className="color-primary-dark">Free</span>
                ) : (
                  "$3.50"
                )}
              </p>
            )}
          </Box>
        )}

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ marginTop: "10px" }}
        >
          {/* Remove mt-0 class if there's no gift card or promo code applied above */}
          <p className="offscreen-menu__content__section-heading mb-0 fw-400--family mt-0">
            Tax
          </p>
          <p className="offscreen-menu__content__section-heading  fw-400--family mb-0 mt-0">
            {currency(lineItemData.tax).format()}
          </p>
        </Box>
        {/* 
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ marginTop: '10px' }}
        >
          <p className="offscreen-menu__content__section-heading fw-400--family mb-0 mt-0">
            Gift card
          </p>
          <p className="offscreen-menu__content__section-heading fw-400--family color-primary-dark mb-0 mt-0">
            -$15.00
          </p>
        </Box> */}

        <Divider style={{ marginTop: "16px", marginBottom: "0" }} />

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <p className="offscreen-menu__content__section-heading">
            Order total
          </p>
          <p className="offscreen-menu__content__section-heading">
            {currency(lineItemData.total).format()}

            {/* {cartTotal > 100 && order.delivery.type === 'delivery'
              ? currency(cartTotal).multiply(1.13).format()
              : cartTotal < 100 &&
                (order.delivery.type === 'delivery' ||
                  order.delivery.type === '')
              ? currency(cartTotal).add(5).multiply(1.13).format()
              : currency(cartTotal).multiply(1.13).format()} */}
          </p>
        </Box>

        {selectedMealPlan !== null &&
          selectedMealPlanName !== "a-la-carte" &&
          selectedMealPlanName !== "" && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              my={3}
            >
              <Box display="flex" alignItems="center">
                <AutorenewIcon
                  color="secondary"
                  style={{ marginRight: "8px" }}
                />
                <Box display="flex" flexDirection="column">
                  <p
                    className="offscreen-menu__content__section-heading"
                    style={{
                      fontFamily: "Inter-Regular, sans-serif",
                      marginTop: 0,
                      marginBottom: "4px",
                    }}
                  >
                    Auto-renew ({autoRenewStatus ? "On" : "Off"})
                  </p>
                  <p
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      fontSize: "14px",
                      color: "#b9b2b6",
                    }}
                  >
                    Meal plan automatically renews every Thursday until
                    cancelled.
                  </p>
                </Box>
              </Box>
              <Box>
                <Switch
                  onClick={() => {
                    setAutoRenewConfirmDialogOpen(true);
                  }}
                  checked={autoRenewStatus}
                />
              </Box>
            </Box>
          )}

        <PrimaryButton
          style={{ width: "100%", marginTop: ".5em" }}
          onClick={completePayment}
          disabled={isSubmitting}
        >
          {isSubmitting ? <ButtonLoader /> : "Confirm order"}
        </PrimaryButton>

        <p
          className="text-center color-secondary-2"
          style={{ marginTop: "40px", fontSize: "14px" }}
        >
          By clicking confirm order you agree to our{" "}
          <Link
            style={{ textDecoration: "underline" }}
            className="link color-secondary-2 link--to-black"
            to="/terms"
          >
            {t("terms")}
          </Link>{" "}
          and consent to our{" "}
          <Link
            style={{ textDecoration: "underline" }}
            className="link color-secondary-2 link--to-black"
            to="/terms"
          >
            {t("privacyPolicy")}
          </Link>
        </p>

        <p
          className="text-center color-secondary-2"
          style={{ marginTop: "20px", fontSize: "14px" }}
        >
          If enabled, meal plan subscriptions auto-renew every Thursday from
          initial enrolment at the then-current weekly rate. You can cancel your
          subscription anytime without fees or penalties prior to renewal.
        </p>
      </Box>

      {/* <p>Password</p>

            <TextField
              color="secondary"
              label="Password"
              name="password"
              type={passwordVisible ? 'text' : 'password'}
              fullWidth
              // value={values.password}
              // onBlur={handleBlur}
              // onChange={handleChange}
              // error={Boolean(errors.password && touched.password)}
              // helperText={
              //   errors.password && touched.password && errors.password
              // }
              InputProps={{
                endAdornment: passwordVisible ? (
                  <VisibilityIcon
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    style={{ cursor: 'pointer', color: '#b2b9b6' }}
                    fontSize="small"
                  />
                ) : (
                  <VisibilityOffIcon
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    style={{ cursor: 'pointer', color: '#b2b9b6' }}
                    fontSize="small"
                  />
                ),
              }}
            />*/}

      <Dialog
        open={autoRenewConfirmDialogOpen}
        keepMounted
        onClose={() => setAutoRenewConfirmDialogOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <img
          src={`https://via.placeholder.com/720x360`}
          style={{ width: "100%" }}
          alt=""
        />

        <DialogTitle style={{ marginTop: "20px" }}>Auto-renew</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Meal plan subscriptions will auto-renew every Thursday at midnight
            from initial enrolment at the then-current weekly rate. You can
            cancel your subscription anytime without fees or penalties prior to
            renewal.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            justifyContent="space-between"
            paddingX={2}
            paddingTop={2}
            paddingBottom={2}
          >
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Button
                color="secondary"
                onClick={() => {
                  setAutoRenewStatus(false);
                  setAutoRenewConfirmDialogOpen(false);
                }}
                style={{
                  marginRight: "8px",
                  boxShadow: "none",
                  padding: "6px 16px",
                }}
              >
                Cancel
              </Button>
              <PrimaryButton
                style={{ boxShadow: "none", padding: "6px 16px" }}
                onClick={() => {
                  setAutoRenewStatus(true);
                  setAutoRenewConfirmDialogOpen(false);
                }}
              >
                I agree
              </PrimaryButton>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
