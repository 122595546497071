/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { Box, Button, Paper, Radio } from "@mui/material";

import DateRangeIcon from "@mui/icons-material/DateRange";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

import { setCurrentCartTab } from "../../slices/authNavReducer";
import { open } from "../../slices/snackbarReducer";
import {
  addItemToCart,
  setSelectedMealPlan,
  setTemporaryCartItem,
} from "../../slices/cartReducer";

import PrimaryButton from "../PrimaryButton/PrimaryButton";
import { useAppDispatch, useAppSelector } from "../../hooks";

export default (props: any) => {
  console.log("MealPlan");
  const dispatch = useAppDispatch();

  const selectedMealPlanName = useAppSelector(
    (state) => state.cart.selectedMealPlanName
  );

  const selectedMealPlan = useAppSelector(
    (state) => state.cart.selectedMealPlan
  );

  const temporaryCartItem = useAppSelector(
    (state) => state.cart.temporaryCartItem
  );

  const mealPlans = [
    {
      name: "1 meal per day",
      pricePerMeal: 8,
      totalPrice: 40,
      minimumDishPerDay: 1,
      deliveryFee: 0,
      freeDelivery: true,
    },
    {
      name: "2 meals per day",
      pricePerMeal: 10,
      totalPrice: 100,
      minimumDishPerDay: 2,
      deliveryFee: 0,
      freeDelivery: true,
    },
    {
      name: "3 meals per day",
      pricePerMeal: 9.66,
      totalPrice: 145,
      minimumDishPerDay: 3,
      deliveryFee: 0,
      freeDelivery: true,
    },
  ];

  return (
    <>
      <h2 className="heading-sans-serif">Meal plans</h2>
      <p>
        Meal plans include a menu that changes weekly. Choose between a variety
        of meat, fish, and plant-based offerings.
      </p>

      <Paper
        elevation={0}
        className="recent-order-notification"
        onClick={() => {
          dispatch(setSelectedMealPlan(mealPlans[0]));
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          px={2}
          pt={2}
        >
          <Box display="flex" flexDirection="column">
            <h1 className="heading heading-sans-serif mb0 mb-0 mt-0 text-center">
              1
            </h1>
            <h3 className="heading heading-sans-serif mb0 mb-1 mt-0 text-center">
              meal per day
            </h3>
            <p
              className="mb0 mb-0 mt-0 text-center"
              style={{ color: "#33474C" }}
            >
              Starting at $40.00/week
            </p>
            <p
              className="mb0 mb-0 mt-1 text-center"
              style={{ color: "#33474C" }}
            >
              $8.00/meal
            </p>

            <Box padding={2} display="flex" width="100%" flexDirection="column">
              <Box display="flex" alignItems="center">
                <DateRangeIcon
                  color="secondary"
                  style={{ marginRight: "8px" }}
                />
                <p
                  style={{
                    color: "#33474C",
                    marginBottom: 0,
                    marginTop: "4px",
                  }}
                >
                  Weekday, Monday to Friday
                </p>
              </Box>

              <Box display="flex" alignItems="center" mt={1}>
                <LocalDiningIcon
                  color="secondary"
                  style={{ marginRight: "8px" }}
                />
                <p
                  style={{
                    color: "#33474C",
                    marginBottom: 0,
                    marginTop: "4px",
                  }}
                >
                  Includes 5 entrees
                </p>
              </Box>

              <Box display="flex" alignItems="center" mt={1}>
                <LocalShippingIcon
                  color="secondary"
                  style={{ marginRight: "8px" }}
                />
                <p
                  style={{
                    color: "#33474C",
                    marginBottom: 0,
                    marginTop: "4px",
                  }}
                >
                  Free daily delivery
                </p>
              </Box>
            </Box>
          </Box>
          <Radio
            style={{ marginTop: "-8px" }}
            color="primary"
            checked={selectedMealPlanName === "1 meal per day"}
          />
        </Box>
      </Paper>
      <Box mt={5}></Box>
      <Paper
        elevation={0}
        className="recent-order-notification"
        onClick={() => {
          dispatch(setSelectedMealPlan(mealPlans[1]));
        }}
      >
        <Box style={{ backgroundColor: "rgb(51, 71, 76)", color: "#fff" }}>
          <h4
            className="mt-0 mb-0 offscreen-menu__top-bar__heading"
            style={{ color: "#fff", padding: ".6em 0" }}
          >
            Most Popular
          </h4>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          px={2}
          pt={2}
        >
          <Box display="flex" flexDirection="column">
            <h1 className="heading heading-sans-serif mb0 mb-0 mt-0 text-center">
              2
            </h1>
            <h3 className="heading heading-sans-serif mb0 mb-1 mt-0 text-center">
              meals per day
            </h3>
            <p
              className="mb0 mb-0 mt-0 text-center"
              style={{ color: "#33474C" }}
            >
              Starting at $100.00/week
            </p>
            <p
              className="mb0 mb-0 mt-1 text-center"
              style={{ color: "#33474C" }}
            >
              $10.00/meal
            </p>

            <Box padding={2} display="flex" width="100%" flexDirection="column">
              <Box display="flex" alignItems="center">
                <DateRangeIcon
                  color="secondary"
                  style={{ marginRight: "8px" }}
                />
                <p
                  style={{
                    color: "#33474C",
                    marginBottom: 0,
                    marginTop: "4px",
                  }}
                >
                  Weekday, Monday to Friday
                </p>
              </Box>

              <Box display="flex" alignItems="center" mt={1}>
                <LocalDiningIcon
                  color="secondary"
                  style={{ marginRight: "8px" }}
                />
                <p
                  style={{
                    color: "#33474C",
                    marginBottom: 0,
                    marginTop: "4px",
                  }}
                >
                  Includes entrees
                </p>
              </Box>

              <Box display="flex" alignItems="center" mt={1}>
                <LocalShippingIcon
                  color="secondary"
                  style={{ marginRight: "8px" }}
                />
                <p
                  style={{
                    color: "#33474C",
                    marginBottom: 0,
                    marginTop: "4px",
                  }}
                >
                  Free daily delivery
                </p>
              </Box>
            </Box>
          </Box>
          <Radio
            style={{ marginTop: "-8px" }}
            color="primary"
            checked={selectedMealPlanName === "2 meals per day"}
          />
        </Box>
      </Paper>
      <Paper
        elevation={0}
        className="recent-order-notification"
        onClick={() => {
          dispatch(setSelectedMealPlan(mealPlans[2]));
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          px={2}
          pt={2}
        >
          <Box display="flex" flexDirection="column">
            <h1 className="heading heading-sans-serif mb0 mb-0 mt-0 text-center">
              3
            </h1>
            <h3 className="heading heading-sans-serif mb0 mb-1 mt-0 text-center">
              meals per day
            </h3>
            <p
              className="mb0 mb-0 mt-0 text-center"
              style={{ color: "#33474C" }}
            >
              Starting at $145.00/week
            </p>
            <p
              className="mb0 mb-1 mt-1 text-center"
              style={{ color: "#33474C" }}
            >
              $9.66/meal
            </p>

            <Box padding={2} display="flex" width="100%" flexDirection="column">
              <Box display="flex" alignItems="center">
                <DateRangeIcon
                  color="secondary"
                  style={{ marginRight: "8px" }}
                />
                <p
                  style={{
                    color: "#33474C",
                    marginBottom: 0,
                    marginTop: "4px",
                  }}
                >
                  Weekday, Monday to Friday
                </p>
              </Box>

              <Box display="flex" alignItems="center" mt={1}>
                <LocalDiningIcon
                  color="secondary"
                  style={{ marginRight: "8px" }}
                />
                <p
                  style={{
                    color: "#33474C",
                    marginBottom: 0,
                    marginTop: "4px",
                  }}
                >
                  Includes breakfast and entrees
                </p>
              </Box>

              <Box display="flex" alignItems="center" mt={1}>
                <LocalShippingIcon
                  color="secondary"
                  style={{ marginRight: "8px" }}
                />
                <p
                  style={{
                    color: "#33474C",
                    marginBottom: 0,
                    marginTop: "4px",
                  }}
                >
                  Free daily delivery
                </p>
              </Box>
            </Box>
          </Box>
          <Radio
            style={{ marginTop: "-8px" }}
            color="primary"
            checked={selectedMealPlanName === "3 meals per day"}
          />
        </Box>
      </Paper>

      <Box mt={4}></Box>

      <PrimaryButton
        disabled={selectedMealPlanName === ""}
        style={{ width: "100%" }}
        onClick={() => {
          dispatch(
            open({
              //@ts-ignore
              message: `${selectedMealPlan.name} plan selected`,
              severity: "success",
            })
          );

          if (temporaryCartItem !== null) {
            dispatch(addItemToCart(temporaryCartItem));

            // dispatch(
            //   open({
            //     message: temporaryCartItem.title + ' added to cart',
            //     severity: 'success',
            //   })
            // );

            dispatch(setTemporaryCartItem(null));
          }

          dispatch(
            open({
              //@ts-ignore
              message: `${selectedMealPlan.name} selected`,
              severity: "success",
            })
          );

          dispatch(setCurrentCartTab("cart"));
        }}
      >
        Choose this plan
      </PrimaryButton>
      <Button
        variant="contained"
        color="secondary"
        style={{
          width: "100%",
          boxShadow: "none",
          borderRadius: "0",
          marginTop: "8px",
          padding: "12px 32px",
          textTransform: "none",
          fontSize: "1em",
          fontFamily: "Inter-SemiBold, sans-serif",
          transition: "all .25s ease",
          background: "rgba(0, 0, 0, 0.12)",
          // border: '2px solid rgba(0, 0, 0, 0.12)',
          color: "#000",
        }}
        onClick={() => {
          dispatch(
            setSelectedMealPlan({
              name: "a-la-carte",
            })
          );

          if (temporaryCartItem !== null) {
            dispatch(addItemToCart(temporaryCartItem));

            // dispatch(
            //   open({
            //     message: temporaryCartItem.title + ' added to cart',
            //     severity: 'success',
            //   })
            // );
          }

          dispatch(
            open({
              message: "À la carte selected",
              severity: "success",
            })
          );

          dispatch(setCurrentCartTab("cart"));

          dispatch(setTemporaryCartItem(null));
        }}
      >
        Choose meals à la carte
      </Button>

      <p
        style={{ fontSize: "14px", marginTop: "40px" }}
        className="text-center color-secondary-2"
      >
        Price does not include modifications. Plus applicable taxes. Meal plan
        subscriptions will auto-renew every Thursday at midnight from initial
        enrolment at the then-current weekly rate. You can cancel your
        subscription anytime without fees or penalties prior to renewal.
      </p>
    </>
  );
};
