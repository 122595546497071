import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  // currentTab: 'profile-delivery-preferences',
  currentTab: 'account',
  // currentTab: 'profile-preferences',
  currentTabNonAuth: 'signin',
  currentCartTab: 'cart',
  currentGuestReceiptTab: 'receipt',
  redirectToCart: false,
  currentlyViewingOrderId: '',
};

const authSlice = createSlice({
  name: 'authNav',
  initialState,
  reducers: {
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },

    setRedirectToCart: (state, action) => {
      state.redirectToCart = action.payload;
    },

    setCurrentTabNonAuth: (state, action) => {
      state.currentTabNonAuth = action.payload;
    },

    setCurrentCartTab: (state, action) => {
      state.currentCartTab = action.payload;
    },

    setCurrentGuestReceiptTab: (state, action) => {
      console.log(action.payload);
      state.currentGuestReceiptTab = action.payload;
    },

    setCurrentlyViewingOrderId: (state, action) => {
      state.currentlyViewingOrderId = action.payload;
    },
  },
});

export const {
  setCurrentTab,
  setCurrentTabNonAuth,
  setCurrentCartTab,
  setCurrentGuestReceiptTab,
  setRedirectToCart,
  setCurrentlyViewingOrderId,
} = authSlice.actions;

export default authSlice.reducer;
