/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { Box, Divider, Radio, Badge, TextField } from "@mui/material";
import currency from "currency.js";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";

import { updateOrderInfo } from "../../slices/orderSlice";
import { setCurrentCartTab } from "../../slices/authNavReducer";

import cartContainsMeals from "../../modules/cartContainsMeals";
import calculateDeliveryFee from "../../modules/calculateDeliveryFee";
import renderSelectedWeek from "../../modules/renderSelectedWeek";

import PrimaryButton from "../PrimaryButton/PrimaryButton";
import getMealCountForADay from "../../utils/getMealCountForADay";
import { useAppSelector, useAppDispatch } from "../../hooks";

export default function () {
  const dispatch = useAppDispatch();

  const state = useAppSelector((state) => state);

  console.log("Check delivery---------->>>", state);

  const [value, setValue] = useState();

  useEffect(() => {
    console.log("value---------", value, moment().isoWeekday());
  }, [value]);

  const handleDateChange = (e: any) => {
    //@ts-ignore
    setValue(moment(e).format("YYYY-MM-DD"));
  };

  const [deliveryType, setDeliveryType] = React.useState("delivery");
  const [deliveryTime, setDeliveryTime] = React.useState("nightBefore");

  const selectedWeek = useAppSelector(
    (state) => state.organization.selectedWeek
  );

  const selectedMealPlan = useAppSelector(
    (state) => state.cart.selectedMealPlan
  );

  const selectedMealPlanName = useAppSelector(
    (state) => state.cart.selectedMealPlanName
  );

  const cartItems = useAppSelector((state) => state.cart.items);
  console.log("cartItems", cartItems, cartContainsMeals(cartItems));

  const handleSubmit = () => {
    dispatch(
      updateOrderInfo(
        {
          //@ts-ignore
          date: new Date(value).toISOString(),
          type: deliveryType,
          time: deliveryTime,
        },
        "delivery",
        () => {
          dispatch(setCurrentCartTab("checkout"));
        }
      )
    );
  };

  const renderWrappedWeekDay = (
    date: any,
    selectedDate: any,
    dayComponent: any
  ) => {
    // console.log(moment(date).isoWeekday());

    const dateInSelectedWeek = moment(date).isBetween(
      moment().add(selectedWeek, "weeks").startOf("isoWeek"),
      moment().add(selectedWeek, "weeks").startOf("isoWeek").add(4, "d"),
      "d",
      "[]"
    );

    const dayHasMeals =
      getMealCountForADay(cartItems, selectedWeek, moment(date)) >= 1;

    const dayHasGroceries =
      moment(date).isoWeekday() === 2 &&
      cartItems.filter((e: any) => e.type === "Grocery").length > 0;

    if (dateInSelectedWeek && (dayHasMeals || dayHasGroceries)) {
      return (
        <Badge
          key={date.toString()}
          variant="dot"
          // overlap="circle"
          color="primary"
          anchorOrigin={{
            horizontal: "left",
            vertical: "top",
          }}
        >
          {dayComponent}
        </Badge>
      );
    }

    return dayComponent;
  };

  const renderDeliveryFee = (deliveryTimeToShow: string) => {
    const deliveryFee = calculateDeliveryFee(
      cartItems,
      selectedWeek,
      deliveryTimeToShow,
      selectedMealPlanName,
      selectedMealPlan
    );

    if (deliveryFee > 0) {
      return (
        <Box>
          <span>{currency(deliveryFee).format()}</span>
          <Radio
            color="secondary"
            checked={deliveryTime === deliveryTimeToShow}
          />
        </Box>
      );
    } else {
      return (
        <Box>
          <span className="color-primary-dark">Free</span>
          <Radio
            style={{ color: "#4caf50" }}
            color="primary"
            checked={deliveryTime === deliveryTimeToShow}
          />
        </Box>
      );
    }
  };

  // Problems.........

  console.log("cartContainer---------", cartContainsMeals(cartItems));

  return (
    <div className="form-login">
      <>
        <p className="offscreen-menu__content__section-heading">
          Delivery date
        </p>

        {cartContainsMeals(cartItems) && (
          <div className="MuiPicker-meals">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              {/* <StaticDatePicker
                displayStaticWrapperAs="desktop"
                // shouldDisableDate={(day: any) =>
                //   moment(day).isBefore(
                //     moment().add(selectedWeek, "weeks").startOf("isoWeek")
                //   ) ||
                //   moment(day).isAfter(
                //     moment().add(selectedWeek, "weeks").endOf("isoWeek")
                //   )
                // }
                // onChange={(newValue: any) => {
                //   handleDateChange(newValue);
                // }}
                // value={moment()}
                // minDate={moment().add(selectedWeek, "weeks").startOf("isoWeek")}
                renderDay={renderWrappedWeekDay}
                // maxDate={moment()
                //   .add(selectedWeek, "weeks")
                //   .startOf("isoWeek")
                //   .add(4, "d")}
                renderInput={(params) => <TextField {...params} />}
              /> */}
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                // shouldDisableDate={(day: any) => moment(day).day() !== 2}
                value={value}
                onChange={(newValue: any) => {
                  handleDateChange(newValue);
                }}
                // minDate={
                //   moment().isoWeekday() > 2
                //     ? moment().add(1, "w").isoWeekday(2)
                //     : moment().isoWeekday(2)
                // }
                // maxDate={moment().endOf("week")}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
        )}

        {!cartContainsMeals(cartItems) && (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              // shouldDisableDate={(day: any) => moment(day).day() !== 2}
              value={value}
              onChange={(newValue: any) => {
                handleDateChange(newValue);
              }}
              // minDate={
              //   moment().isoWeekday() > 2
              //     ? moment().add(1, "w").isoWeekday(2)
              //     : moment().isoWeekday(2)
              // }
              // maxDate={moment().endOf("week")}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        )}

        <Divider />

        <PrimaryButton
          onClick={handleSubmit}
          style={{ width: "100%", marginTop: "2em" }}
          type="submit"
        >
          Add delivery date
        </PrimaryButton>
      </>
    </div>
  );
}
