import { createSlice } from "@reduxjs/toolkit";

import GiftCardsService from "../api/gift-cards";

import { AppDispatch, AppThunk } from "../store";

const initialState = {
  giftCards: {
    data: [],
    total: 0,
  },
  giftCardsLoading: true,
};

const giftCard = createSlice({
  name: "giftCard",
  initialState,
  reducers: {
    getGiftCardsSuccess: (state, action) => {
      state.giftCards = action.payload;
      state.giftCardsLoading = false;
    },
    setGiftCardsLoading: (state, action) => {
      state.giftCardsLoading = action.payload;
    },
  },
});

export const { getGiftCardsSuccess, setGiftCardsLoading } = giftCard.actions;

export const getGiftCards =
  (query?: object | undefined): AppThunk =>
  async (dispatch) => {
    try {
      console.log("in get gift card");

      dispatch(setGiftCardsLoading(true));
      const giftCards = await GiftCardsService.find(query);
      console.log(giftCards);
      dispatch(getGiftCardsSuccess(giftCards));
      dispatch(setGiftCardsLoading(false));
    } catch (error) {
      console.log(error);
    }
  };

export const addGiftCardToSubscription =
  (subId: string, code: string, successFunc: Function): AppThunk =>
  async (dispatch) => {
    console.log("in giftcard sub");
    try {
      const giftCardAdd = await GiftCardsService.addGiftCardCode(subId, code);

      successFunc();
    } catch (error) {
      console.log(error);
    }
  };
export default giftCard.reducer;
