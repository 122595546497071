import React from 'react';
import { Link } from 'react-router-dom';

import './LinkWithArrow.scss';

export default (props: any) => {
  return (
    //@ts-ignore
    <Link to={props.to} className={`link`} label={props.label}>
      {props.text}{' '}
      <span className={`linkArrow`}> {props.downArrow ? '↓' : '-->'}</span>
    </Link>
  );
};
