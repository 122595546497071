import React, { useEffect } from 'react';
import { close } from '../../slices/snackbarReducer';

import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Grow from '@mui/material/Grow';

import { Snackbar } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks';

import './SnackbarContainer.scss';

export default () => {
  const dispatch = useAppDispatch();

  const snackbar = useAppSelector((state) => state.snackbar);
  const isSnackbarOpen = useAppSelector((state) => state.snackbar.open);
  const snackbarMessage = useAppSelector((state) => state.snackbar.message);

  return (
    <Snackbar
      style={{
        minWidth: '382px',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={isSnackbarOpen}
      autoHideDuration={4000}
      onClose={() => {
        dispatch(close());
      }}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={() => {
          dispatch(close());
        }}
        //@ts-ignore
        severity={snackbar.severity}
      >
        {snackbarMessage}
      </MuiAlert>
    </Snackbar>
  );
};
