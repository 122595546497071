import { createSlice } from "@reduxjs/toolkit";
import UserService from "../api/user";
import ActivityService from "../api/activities";
import { AppThunk } from "../store";
import {
  login,
  changeLanguageSuccess,
  updateCustomerAddressSuccess,
  logoutSuccess,
} from "./authReducer";
import { open } from "./snackbarReducer";
import AuthService from "../api/auth";
import { setCurrentTab } from "./authNavReducer";
const initialState = {
  isAuthenticated: false,
  user: null,
  isError: false,

  getSecondaryAccountsLoading: false,
  secondaryAccounts: [],

  changePasswordSubmitting: false,
  changePasswordError: null,

  currentProfileFormSubmitting: false,
  currentProfileError: null,

  profileLanguageFormSubmitting: false,
  profileLanguageFormError: null,

  profileNotificationsFormSubmitting: false,
  profileNotificationsFormError: null,

  addAddressFormSubmitting: false,
  addAddressFormSubmittingError: null,

  addCreditCardFormSubmitting: false,
  addCreditCardFormSubmittingError: null,

  addProfileFormSubmitting: false,
  addProfileFormSubmittingError: null,

  editAddressFormSubmitting: false,
  editAddressFormError: null,
  currentlyEditingAddress: null,

  selectedAddressIndex: 0,
  selectedCard: null,
  selectedProfile: -1,

  registerCustomerLoading: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    registerCustomerLoading: (state, action) => {
      state.registerCustomerLoading = action.payload;
    },

    getSecondaryAccountsLoading: (state, action) => {
      state.getSecondaryAccountsLoading = action.payload;
    },

    getSecondaryAccountsSuccess: (state, action) => {
      state.secondaryAccounts = action.payload;
    },

    registerCustomerSuccess: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
      state.isError = false;
    },

    registerCustomerFailed: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      state.isError = true;
    },

    changePasswordSuccess: (state) => {
      state.changePasswordError = null;
    },

    changePasswordFailed: (state, action) => {
      state.changePasswordError = action.payload;
    },

    changePasswordSubmitting: (state, action) => {
      state.changePasswordSubmitting = action.payload;
    },

    changeLanguageSubmitting: (state, action) => {
      state.profileLanguageFormSubmitting = action.payload;
    },

    changeNotificationsSubmitting: (state, action) => {
      state.profileNotificationsFormSubmitting = action.payload;
    },

    addCreditCardSubmitting: (state, action) => {
      state.addCreditCardFormSubmitting = action.payload;
    },

    setSelectedAddressIndex: (state, action) => {
      state.selectedAddressIndex = action.payload;
    },
    setCurrentlyEditingAddress: (state, action) => {
      state.currentlyEditingAddress = action.payload;
    },

    setSelectedCard: (state, action) => {
      state.selectedCard = action.payload;
    },

    setSelectedSecondaryProfile: (state, action) => {
      state.selectedProfile = action.payload;
    },
  },
});

export const {
  registerCustomerLoading,
  registerCustomerSuccess,
  registerCustomerFailed,
  changePasswordSuccess,
  changePasswordFailed,
  changePasswordSubmitting,
  changeLanguageSubmitting,
  changeNotificationsSubmitting,
  setSelectedAddressIndex,
  setCurrentlyEditingAddress,
  addCreditCardSubmitting,
  getSecondaryAccountsLoading,
  getSecondaryAccountsSuccess,
  setSelectedCard,
  setSelectedSecondaryProfile,
} = userSlice.actions;

export default userSlice.reducer;

export const registerCustomer =
  (customer: any): AppThunk =>
  async (dispatch) => {
    console.log("Calling register customer");
    try {
      const createdCustomer = UserService.register(customer);
      dispatch(registerCustomerSuccess(createdCustomer));
    } catch (error) {
      console.log(error);
    }
  };

export const getSecondaryAccounts =
  (primaryAccountId: string): AppThunk =>
  async (dispatch) => {
    dispatch(getSecondaryAccountsLoading(true));
    try {
      const secondaryAccounts = await UserService.getSecondaryAccounts(
        primaryAccountId
      );

      console.log(secondaryAccounts);

      dispatch(getSecondaryAccountsSuccess(secondaryAccounts.data));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(getSecondaryAccountsLoading(false));
    }
  };

export const changePassword =
  (
    id: string,
    newPassword: string,
    setSubmitting: Function,
    successAction: Function | null | undefined = undefined
  ): AppThunk =>
  async (dispatch) => {
    console.log("Calling change password");
    try {
      await UserService.changePassword(id, newPassword);
      dispatch(changePasswordSuccess());
      if (successAction) {
        successAction();
      }
    } catch (error) {
      console.log(error);
      dispatch(changePasswordFailed(error));
    } finally {
      setSubmitting(false);
      dispatch(changePasswordSubmitting(false));
    }
  };

export const deleteAccount =
  (id: string, authFlag: boolean): AppThunk =>
  async (dispatch) => {
    //console.log("delete Account id --->", id)
    try {
      const res = await UserService.deleteAccount(id);

      if (authFlag && res) {
        await AuthService.logout();
        dispatch(logoutSuccess());
      } else {
        dispatch(setCurrentTab("account"));
      }
      // console.log("deleted account result", res)
      //dispatch(changePasswordSuccess());
      // if (successAction) {
      //   successAction();
      // }
    } catch (error) {
      // console.log(error);
      // dispatch(changePasswordFailed(error));
    } finally {
      // setSubmitting(false);
      // dispatch(changePasswordSubmitting(false));
    }
  };

export const updateProfile =
  (
    id: string,
    data: any,
    setSubmitting: Function,
    successAction: Function | null | undefined = undefined
  ): AppThunk =>
  async (dispatch) => {
    console.log("Calling update profile data", setSubmitting);
    try {
      await UserService.updateProfile(id, data);
      dispatch(changePasswordSuccess());
      if (successAction) {
        successAction();
        dispatch(
          open({
            message: "Update your profile successfully!",
            severity: "success",
          })
        );
      }
    } catch (error) {
      console.log(error);
      dispatch(changePasswordFailed(error));
    } finally {
      setSubmitting(false);
      dispatch(changePasswordSubmitting(false));
    }
  };

export const updateLanguage =
  (
    id: string,
    language: string,
    setSubmitting: Function,
    successAction: Function | null | undefined = undefined
  ): AppThunk =>
  async (dispatch) => {
    console.log("Calling change password");
    try {
      await UserService.updateLanguage(id, language);
      dispatch(changeLanguageSuccess(language));

      setSubmitting(false);
      dispatch(changeLanguageSubmitting(false));

      if (successAction) {
        successAction();
      }
    } catch (error) {
      console.log(error);
      setSubmitting(false);
      dispatch(changeLanguageSubmitting(false));
    }
  };

export const updateNotifications =
  (
    id: string,
    notifications: object,
    setSubmitting: Function,
    successAction: Function | null | undefined = undefined
  ): AppThunk =>
  async (dispatch) => {
    console.log("Calling change password");
    try {
      await UserService.updateNotifications(id, notifications);
      // dispatch(changeLanguageSuccess(language));

      setSubmitting(false);
      dispatch(changeNotificationsSubmitting(false));

      if (successAction) {
        successAction();
      }
    } catch (error) {
      console.log(error);
      setSubmitting(false);
      dispatch(changeNotificationsSubmitting(false));
    }
  };

export const updateCustomerAddress =
  (
    id: string,
    address: [Object] | any[],
    type: object,
    setSubmitting: Function,
    successAction: Function | null | undefined = undefined
  ): AppThunk =>
  async (dispatch) => {
    console.log("Calling change password");
    try {
      console.log(address);
      await UserService.updateCustomerAddress(id, address, type);
      // dispatch(changePasswordSuccess());
      if (successAction) {
        setSubmitting(false);
        dispatch(changeLanguageSubmitting(false));
        dispatch(updateCustomerAddressSuccess(address));
        successAction();
      }
    } catch (error) {
      setSubmitting(false);
      dispatch(changeLanguageSubmitting(false));
      console.log(error);
      dispatch(
        open({
          message: error.message || "There was a problem updating address",
          severity: "error",
        })
      );
      // dispatch(changePasswordFailed(error));
    }
  };

export const registerUser =
  (
    customer: any,
    history: any,
    toggleSignUp: Function,
    setSubmitting: Function
  ): AppThunk =>
  async (dispatch) => {
    let createdCustomer;

    try {
      createdCustomer = await UserService.register(customer);
      console.log(createdCustomer);
      await ActivityService.create({
        actionOn: createdCustomer._id,
        actionBy: createdCustomer.firstName,
        action: "registerd",
        data: {
          firstName: createdCustomer.firstName,
          lastName: createdCustomer.lastName || "",
          type: 1,
        },
      });

      dispatch(registerCustomerLoading(false));

      dispatch(
        login(
          customer.email,
          customer.password,
          false,
          toggleSignUp,
          setSubmitting
        )
      );
    } catch (err) {
      console.log("ADD CUSTOMER FAILED");
      console.log(err);

      if (err.name === "Conflict") {
        if (err.errors.hasOwnProperty("email")) {
          if (err.errors.email === "value") {
            dispatch(
              open({
                message: "Your entered email is already registered.",
                severity: "error",
              })
            );
          }
        }
      } else {
        dispatch(
          open({
            message: err.message,
            severity: "error",
          })
        );
      }

      dispatch(registerCustomerLoading(false));
    }
  };

export const addSecondaryProfile =
  (customer: any, successAction: Function): AppThunk =>
  async (dispatch) => {
    let createdCustomer;

    try {
      createdCustomer = await UserService.register(customer);
      console.log(createdCustomer);
      //@ts-ignore

      await ActivityService.create({
        actionOn: createdCustomer._id,
        actionBy: createdCustomer.firstName,
        action: "add-secondary-contact",
        data: {
          firstName: createdCustomer.firstName,
          lastName: createdCustomer.lastName || "",
        },
      });

      if (successAction) {
        successAction();
      }

      dispatch(registerCustomerLoading(false));

      // dispatch(login(customer.email, customer.password, toggleSignUp));
    } catch (err) {
      console.log("ADD CUSTOMER FAILED");
      console.log(err);
      dispatch(
        open({
          message: err.message,
          severity: "error",
        })
      );
      dispatch(registerCustomerLoading(false));
    }
  };
