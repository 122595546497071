import feathers from '@feathersjs/client';
import io from 'socket.io-client';
import socketio from '@feathersjs/socketio-client';
import auth from '@feathersjs/authentication-client';
//@ts-ignore
// import authManagementService from 'feathers-authentication-management';

const socket = io(
  //@ts-ignore
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_PROD_API_URL
    : process.env.REACT_APP_LOCAL_API_URL,
  {
    transports: ['websocket'],
    forceNew: true,
  }
);

//@ts-ignore
const feathersClient = feathers();

feathersClient.configure(
  socketio(socket, {
    timeout: 10000,
  })
);
feathersClient.configure(auth());
// feathersClient.configure(authManagementService({}));

export default feathersClient;
