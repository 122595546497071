import { createSlice } from '@reduxjs/toolkit';

import AuthService from '../api/auth';
import { getSecondaryAccounts } from './userReducer';
import { getSubscription } from './subscriptionReducer';
import { open } from './snackbarReducer';

import { AppThunk, AppDispatch } from '../store';

const initialState = {
  isAuthenticated: false,
  user: {},
  loginError: false,
};

const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginStart: (state) => {
      state.user = {};
      state.isAuthenticated = false;
      state.loginError = false;
    },
    loginSuccess: (state, action) => {
      state.user = action.payload.user;
      state.isAuthenticated = true;
      state.loginError = false;
    },
    loginFailed: (state) => {
      state.user = {};
      state.loginError = true;
      state.isAuthenticated = false;
    },
    logoutSuccess: (state) => {
      state.user = {};
      state.loginError = false;
      state.isAuthenticated = false;
    },
    alreadyLoggedIn: (state, action) => {
      state.user = action.payload;
      state.loginError = false;
      state.isAuthenticated = true;
    },

    changeLanguageSuccess: (state, action) => {
      //@ts-ignore
      state.user.settings.language = action.payload;
    },
    updateCustomerAddressSuccess: (state, action) => {
      //@ts-ignore
      state.user.address = action.payload;
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailed,
  logoutSuccess,
  alreadyLoggedIn,
  changeLanguageSuccess,
  updateCustomerAddressSuccess,
} = authSlice.actions;

export default authSlice.reducer;

export const login =
  (
    email: string,
    password: string,
    deleted: boolean,
    toggleAccountMenu: Function,
    setSubmitting: Function
  ): AppThunk =>
  async (dispatch) => {
    console.log('Calling');

    dispatch(loginStart());

    try {
      const user = await AuthService.login({
        email,
        password,
      });
      if(!user.user.deleted){
        console.log("returned data =----->", user.user.deleted);
        dispatch(loginSuccess(user));
      }else{
        dispatch(
          open({
            message:'Invalid username or password',
            severity: 'error',
          })
        );
        dispatch(loginFailed());
      }

      console.log("returned data =----->", user);



      if (user.user.secondaryAccounts.length > 0) {
        dispatch(getSecondaryAccounts(user._id));
      }

      dispatch(getSubscription(user.user.subscriptionId));

      if (toggleAccountMenu) {
        toggleAccountMenu();
      }

      setSubmitting(false);
    } catch (error) {
      dispatch(
        open({
          message:
            error?.message === 'Invalid login'
              ? 'Invalid username or password'
              : 'There was a problem authenticating your account. Please log in again',
          severity: 'error',
        })
      );
      dispatch(loginFailed());
    }

    console.log('yes');
  };

export const logout = (): AppThunk => async (dispatch) => {
  console.log('Calling logout');
  try {
    await AuthService.logout();
    dispatch(logoutSuccess());
  } catch (error) {
    console.log(error);
  }
};
