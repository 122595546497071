import React, { SyntheticEvent } from "react";

import { Box, CircularProgress } from "@mui/material";
import currency from "currency.js";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import TodayIcon from "@mui/icons-material/Today";

import PrimaryButton from "../PrimaryButton/PrimaryButton";

import {
  addItemToCart,
  increaseItemQuantity,
  setProductToEdit,
  setTemporaryCartItem,
} from "../../slices/cartReducer";

import { open } from "../../slices/snackbarReducer";
import { setCurrentCartTab, setCurrentTab } from "../../slices/authNavReducer";
import getProductPlatingDate from "../../utils/getProductPlatingDate";
import { useAppDispatch, useAppSelector } from "../../hooks";

import "./ProductItem.scss";
import { fetchOrders } from "../../slices/orderSlice";
import {
  addItemToSubscriptionOrder,
  increaseSubscriptionOrderItemQuantity,
  setCurrentViewingSubscriptionWeekNumber,
} from "../../slices/subscriptionReducer";

interface ProductItemProps {
  id: string;
  title: string;
  titleFR: string;
  tag?: string;
  link: string;
  image?: string;
  offerChange?: string;
  platingDate?: string | Date;
  type: string;
  variants: Array<{
    _id: string;
    name?: string;
    price?: number;
  }>;
  extras?: Array<string>;
  extrasActual?: Array<Object>;
  description?: string;
  descriptionFR?: string;
  availability?: object[] | any;
  quantity: number;
}

export default function ProductItem(props: ProductItemProps) {
  const dispatch = useAppDispatch();

  const [addingProductToCart, setAddingProductTocart] = React.useState(false);

  const user: any = useAppSelector((state) => state.auth.user);

  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  const selectedMealPlanName: any = useAppSelector(
    (state) => state.cart.selectedMealPlanName
  );

  const selectedWeek = useAppSelector(
    (state) => state.organization.selectedWeek
  );

  const isProductAdded: any = useAppSelector(
    (state) =>
      state.cart.items.findIndex((e) => e.id === props.id) >= 0 ||
      state.subscription.currentViewingSubscriptionOrderItems.findIndex(
        (e: any) => e.id === props.id
      ) >= 0
  );

  const productAddedIndex: any = useAppSelector((state) => {
    return state.cart.items.findIndex((e) => e.id === props.id) === -1
      ? state.subscription.currentViewingSubscriptionOrderItems.findIndex(
          (e: any) => e.id === props.id
        )
      : state.cart.items.findIndex((e) => e.id === props.id);
  });

  const product: any = useAppSelector((state) => {
    //@ts-ignore
    if (state.cart.items.findIndex((e: any) => e.id === props.id) >= 0)
      return state.cart.items.find((e: any) => e.id === props.id);

    //@ts-ignore
    if (
      state.subscription.currentViewingSubscriptionOrderItems.findIndex(
        (e: any) => e.id === props.id
      ) >= 0
    )
      return state.subscription.currentViewingSubscriptionOrderItems.find(
        (e: any) => e.id === props.id
      );
  });

  const addToCart = (e: SyntheticEvent) => {
    setAddingProductTocart(true);

    //make a check when user has subscribed and chooses a la carte before adding a product

    setTimeout(() => {
      if (
        isAuthenticated &&
        user.hasSubscribedToMealPlanAtleastOnce &&
        props.type === "Meals"
      ) {
        if (isProductAdded) {
          dispatch(increaseSubscriptionOrderItemQuantity(productAddedIndex));
        } else {
          dispatch(
            addItemToSubscriptionOrder({
              id: props.id,
              type: props.type || "",
              title: props.title,
              titleFR: props.titleFR,
              description: props.description,
              descriptionFR: props.descriptionFR,
              availability: props.availability || [],
              variants: props.variants,
              extras: props.extras,
              extrasActual: props.extrasActual,
              selectedExtras: [],
              selectedVariant: props.variants[0],
              quantity: 1,
              price: Number(props.variants[0].price),
              total: Number(props.variants[0].price),
            })
          );

          dispatch(setCurrentViewingSubscriptionWeekNumber(selectedWeek));
          dispatch(setCurrentTab("profile-single-subscription"));
          document.body.classList.toggle("account-menu--open");

          dispatch(
            open({
              message: props.title + " added to subscription week",
              severity: "success",
            })
          );
        }

        setAddingProductTocart(false);
        return;
      }

      if (props.type === "Meals" && selectedMealPlanName === "") {
        console.log("second");
        dispatch(setCurrentCartTab("meal-plan"));
        dispatch(
          setTemporaryCartItem({
            id: props.id,
            type: props.type || "",
            title: props.title,
            titleFR: props.titleFR,
            description: props.description,
            descriptionFR: props.descriptionFR,
            availability: props.availability || [],
            variants: props.variants,
            extras: props.extras,
            extrasActual: props.extrasActual,
            selectedExtras: [],
            selectedVariant: props.variants[0],
            quantity: 1,
            price: Number(props.variants[0].price),
            total: Number(props.variants[0].price),
          })
        );
        document.body.classList.toggle("cart--open");
        setAddingProductTocart(false);
        return;
      }

      console.log("third");
      if (isProductAdded) {
        dispatch(increaseItemQuantity(productAddedIndex));
      } else {
        dispatch(
          addItemToCart({
            id: props.id,
            type: props.type || "",
            title: props.title,
            titleFR: props.titleFR,
            description: props.description,
            descriptionFR: props.descriptionFR,
            availability: props.availability || [],
            variants: props.variants,
            extras: props.extras,
            extrasActual: props.extrasActual,
            selectedExtras: [],
            selectedVariant: props.variants[0],
            quantity: 1,
            price: Number(props.variants[0].price),
            total: Number(props.variants[0].price),
          })
        );

        if (
          selectedMealPlanName !== "" &&
          selectedMealPlanName !== "a-la-carte"
        ) {
          document.body.classList.toggle("cart--open");
        }

        dispatch(
          open({
            message: props.title + " added to cart",
            severity: "success",
          })
        );
      }

      setAddingProductTocart(false);
    }, 500);

    e.stopPropagation();
  };

  return (
    <article
      className="products-area__item"
      itemType="plan"
      onClick={() => {
        dispatch(setProductToEdit({ ...props }));
        dispatch(setCurrentCartTab("edit-cart-item"));
        document.body.classList.toggle("cart--open");
      }}
    >
      <Box>
        <figure className="products-area__item__figure">
          <div>
            <a className="products-area__item__figure__link" href={props.link}>
              {props.tag && (
                <div className="products-area__item__figure__tag">
                  {props.tag}
                </div>
              )}
              <img src={props.image && props.image} alt="" />
            </a>
          </div>
        </figure>

        <Box className="products-area__item__info">
          {props.type === "Meals" && (
            <p className="small-heading">
              <>
                <TodayIcon />
                {getProductPlatingDate(selectedWeek, props.availability)}
              </>
            </p>
          )}

          <h3 className="products-area__item__info__title">{props.title}</h3>

          <Box className="products-area__item__info__pricing">
            <Box display="flex" alignItems="center">
              {isProductAdded && (
                <span className="products-area__item__info__pricing__count">
                  {product.quantity}
                </span>
              )}
              <p className="products-area__item__info__pricing__price">
                {isProductAdded && product.hasOwnProperty("offerChange") && (
                  <span className="pricing__price__offer pricing__price__offer--change">
                    Was{" "}
                    <span style={{ textDecoration: "line-through" }}>
                      ${product.offerChange}
                    </span>
                  </span>
                )}

                {props.variants.length > 1
                  ? "Starting from $" + props.variants[0].price
                  : //@ts-ignore
                    `${currency(props.variants[0].price)
                      .multiply(isProductAdded ? product.quantity : 1)
                      .format()}`}

                {/* {(isProductAdded && product.hasOwnProperty('offerChange') && (
                  <span className="pricing__price__offer pricing__price__offer--add">
                    2 for $15
                  </span>
                )} */}
              </p>
            </Box>

            <Box display="flex">
              <PrimaryButton
                className={`products-area__item__info__pricing__edit-btn ${
                  isProductAdded &&
                  "products-area__item__info__pricing__edit-btn--visible"
                }`}
              >
                <EditIcon />
              </PrimaryButton>
              <PrimaryButton
                className="products-area__item__info__pricing__add-btn"
                onClick={addToCart}
                disabled={addingProductToCart}
              >
                <CircularProgress
                  color="secondary"
                  size={20}
                  style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    marginLeft: "-10px",
                    marginTop: "-10px",
                    visibility: addingProductToCart ? "visible" : "hidden",
                    pointerEvents: "none",
                  }}
                />

                <AddIcon
                  style={{
                    visibility: addingProductToCart ? "hidden" : "visible",
                  }}
                />
              </PrimaryButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </article>
  );
}
