/* eslint-disable import/no-anonymous-default-export */
import feathers from "../../feathers";

export default {
  get: (id: string) => {
    return feathers.service("products").get(id);
  },

  find: (query: object | undefined) => {
    if (query) {
      return feathers.service("products").find({
        query,
      });
    }

    return feathers.service("products").find();
  },
};
