import React from 'react';
import './SideNavItem.scss';

export default function SideNavItem(props: any) {
  let classNames = 'side-nav-item';

  if (props.noHover) {
    console.log('In there');
    classNames += ' no-hover';
  }

  return (
    <div className={classNames} onClick={props.onClick ? props.onClick : null}>
      <div>{props.children}</div>
    </div>
  );
}
