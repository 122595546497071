/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import {
  Box,
  Tooltip,
  IconButton,
  Link as MaterialUILink,
} from "@mui/material";

import { AddCircle } from "@mui/icons-material";

import { open } from "../../slices/snackbarReducer";
import { useAppDispatch } from "../../hooks";

import "./EmptyCartItem.scss";

export default function (props: any) {
  const dispatch = useAppDispatch();

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        className="cart-line-item cart-line-item--meal"
        flexDirection="column"
        onClick={() => {
          props.handleClick();
          dispatch(
            open({
              message: "Filter added",
              severity: "success",
            })
          );
        }}
      >
        <Box display="flex" alignItems="flex-start" width="100%">
          {props.emptyCartNoThumb ? (
            ""
          ) : (
            <div className="cart-line-item-meal-day__thumb"></div>
          )}
          <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            flex="1"
            alignItems={props.emptyCartNoThumb ? "center" : "flex-start"}
          >
            <Box display="flex" flexDirection="column" pr={2} width="60%">
              <p className="offscreen-menu__content__section-heading fw-400--family mt-0 mb-0">
                Add meal
              </p>
              {/* <p
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    fontSize: '14px',
                    color: '#b9b2b6',
                  }}
                >
                  {item.selectedVariant.name ||
                    item.selectedVariant.unit +
                      ' ' +
                      item.selectedVariant.value}
                </p> */}
            </Box>

            <Box
              display="flex"
              width="40%"
              justifyContent="flex-end"
              alignItems={props.emptyCartNoThumb ? "center" : "flex-start"}
              mr={"-8px"}
            >
              <Box
                display="flex"
                alignItems="flex-end"
                flexDirection="column"
                pr={1}
              >
                {/* <p className="offscreen-menu__content__section-heading fw-400--family mt-0 mb-0"></p>
                  <p
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      fontSize: '14px',
                      color: '#b9b2b6',
                    }}
                  >
                    {t('qty')}: {item.quantity}
                  </p> */}
              </Box>
              <Tooltip title={"Add"}>
                <IconButton
                  style={{
                    position: "relative",
                    top: props.emptyCartNoThumb ? "4px" : "8px",
                  }}
                >
                  <AddCircle />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
