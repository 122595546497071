import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import OrderService from "../api/orders";

import { AppDispatch, AppThunk } from "../store";
import { open } from "./snackbarReducer";

const initialState: any = {
  ordersLoading: true,
  orders: {
    data: [],
  },
  currentOrderBeingEdited: {
    selectedWeek: null,
    items: [],
  },
  viaCart: false,
  order: {
    address: null,
    // {
    //   canadaPostId: 'CA|CP|A|3509773',
    //   Label: 'T3-444 Stone Church Rd O\nHAMILTON ON L9B 1R1\nCANADA',
    //   SubBuilding: 'T3',
    //   BuildingNumber: '444',
    //   BuildingName: '',
    //   Street: 'Stone Church Rd O',
    //   City: 'Hamilton',
    //   Line1: 'T3-444 Stone Church Rd O',
    //   Line2: '',
    //   Line3: '',
    //   Province: 'ON',
    //   PostalCode: 'L9B 1R1',
    //   Type: 'Residential',
    //   buzzer: '',
    //   addressType: 'home',
    //   notes: 'test',
    //   deliveryOption: 'leaveAtDoor',
    //   organization: '',
    // },
    profile: {
      firstName: "",
      lastName: "",
      emaiLAddress: "",
      phone: "",
    },

    // {
    //   firstName: 'Jiv',
    //   lastName: 'Test',
    //   emailAddress: 'jivanyesh@gmail.com',
    //   phone: '+91 76661-37467',
    // },
    selectedWeek: [1, 2, 3].indexOf(moment().isoWeekday()) >= 0 ? 1 : 2,
    delivery: {
      date:
        moment().isoWeekday() > 2
          ? moment().add(1, "w").isoWeekday(2).toISOString()
          : moment().isoWeekday(2).toISOString(),
      // type: '',
      type: "delivery",
      time: "nightBefore",
    },
  },
  filtersApplied: [],

  orderInfoUpdating: false,
  createOrderLoading: true,

  existingOrder: null,
  existingOrderLoading: true,
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrdersLoading: (state, action) => {
      state.ordersLoading = action.payload;
    },

    fetchOrdersSuccess: (state, action) => {
      state.orders = action.payload;
    },

    createOrderSuccess: (state, action) => {
      state.order = action.payload;
      state.viaCart = true;
      state.createOrderLoading = false;
    },

    createOrderLoading: (state, action) => {
      state.createOrderLoading = action.payload;
    },

    existingOrderLoading: (state, action) => {
      state.existingOrderLoading = action.payload;
    },

    fetchExistingOrderSuccess: (state, action) => {
      state.existingOrder = action.payload;
      state.existingOrderLoading = false;
    },

    setExistingOrderLoading: (state, action) => {
      state.existingOrderLoading = action.payload;
    },

    updateOrder: (state, action) => {
      //@ts-ignore
      state.order[action.payload.type] = action.payload.data;
    },

    setOrderInfoUpdating: (state, action) => {
      state.orderInfoUpdating = action.payload;
    },

    resetOrder: (state) => {
      state.order = {
        address: null,
        profile: {
          firstName: "",
          lastName: "",
          emailAddress: "",
          phone: "",
        },
        selectedWeek: 0,
        delivery: {
          date:
            moment().isoWeekday() > 2
              ? moment().add(1, "w").isoWeekday(2).toISOString()
              : moment().isoWeekday(2).toISOString(),
          type: "",
          time: "nightBefore",
        },
      };
    },

    unloadExistingOrder: (state) => {
      state.existingOrder = null;
      state.existingOrderLoading = true;
    },

    unloadOrders: (state) => {
      state.orders = {
        data: [],
      };
      state.ordersLoading = true;
    },

    setExistingOrder: (state, action) => {
      state.existingOrder = action.payload;
      state.existingOrderLoading = false;
    },

    addFilter: (state, action) => {
      console.log(action.payload);
      console.log(action.payload);
      state.filtersApplied = action.payload;
    },

    removeFilterByIndex: (state) => {
      state.filtersApplied = [];
    },
  },
});

export const {
  setOrdersLoading,
  fetchOrdersSuccess,
  createOrderSuccess,
  createOrderLoading,
  setOrderInfoUpdating,
  updateOrder,
  resetOrder,
  fetchExistingOrderSuccess,
  existingOrderLoading,
  unloadExistingOrder,
  unloadOrders,
  setExistingOrder,
  setExistingOrderLoading,
  addFilter,
  removeFilterByIndex,
} = orderSlice.actions;

export const fetchOrder =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      const order = await OrderService.get(id);
      console.log("In order");

      console.log(order);
      dispatch(fetchExistingOrderSuccess(order));
    } catch (error) {
      console.log(error);
      dispatch(setExistingOrderLoading(false));
    }
  };

export const fetchOrders =
  (query: object | undefined = undefined): AppThunk =>
  async (dispatch) => {
    try {
      const orders = await OrderService.find(query);
      console.log("In order\n", orders);
      dispatch(setOrdersLoading(false));
      dispatch(fetchOrdersSuccess(orders));
    } catch (error) {
      console.log(error);
      dispatch(setOrdersLoading(false));

      dispatch(
        open({
          message: error.message || error,
          severity: "error",
        })
      );
    }
  };

export const createOrder =
  (data: any, successFunc: Function): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createOrderLoading(true));
      console.log("data", data);
      const order = await OrderService.create(data);
      console.log("order", order);
      dispatch(createOrderSuccess(order));
      dispatch(createOrderLoading(false));
      dispatch(setExistingOrder(order));
      successFunc();
    } catch (error) {
      console.log("featch Order Error", error);
      dispatch(
        open({
          message: error.message || error,
          severity: "error",
        })
      );
    }
  };

export const updateOrderInfo =
  (data: any, type: string, successFunc: Function): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setOrderInfoUpdating(true));

      dispatch(
        updateOrder({
          data,
          type,
        })
      );

      dispatch(setOrderInfoUpdating(false));
      successFunc();
    } catch (error) {
      console.log(error);
      dispatch(
        open({
          message: error.message || error,
          severity: "error",
        })
      );
    }
  };

export const getOrder =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setExistingOrderLoading(true));
      const order = await OrderService.get(id);
      console.log(order);
      dispatch(fetchExistingOrderSuccess(order));
      dispatch(setExistingOrderLoading(false));
    } catch (error) {
      console.log(error);
      dispatch(
        open({
          message: error.message || error,
          severity: "error",
        })
      );
    }
  };

export const emaiLReceipt =
  (id: string, successFunc: Function): AppThunk =>
  async (dispatch) => {
    try {
      await OrderService.patch(id, {
        emailReceipt: true,
      });

      if (successFunc) {
        successFunc();
      }
    } catch (error) {
      console.log(error);
      dispatch(
        open({
          message: error.message || error,
          severity: "error",
        })
      );
    }
  };

export const cancelOrder =
  (
    id: string,
    note: string,
    cancelReason: string,
    successFunc: Function
  ): AppThunk =>
  async (dispatch) => {
    try {
      const order = await OrderService.patch(id, {
        cancelNote: note,
        cancelReason,
      });
      console.log("inside cancel order");
      // console.log(order);

      if (successFunc) {
        successFunc();
      }
    } catch (error) {
      console.log(error);
      dispatch(
        open({
          message: error.message || error,
          severity: "error",
        })
      );
    }
  };

export const reportProblemOrder =
  (id: string, note: string, successFunc: any): AppThunk =>
  async (dispatch) => {
    try {
      const order = await OrderService.patch(id, {
        reportAProblemMessage: note,
        reportedAProblem: true,
      });
      console.log("inside reprot a problem order");
      console.log(order);

      if (successFunc) {
        successFunc();
      }
    } catch (error) {
      console.log(error);
      dispatch(
        open({
          message: error.message || error,
          severity: "error",
        })
      );
    }
  };

export default orderSlice.reducer;
