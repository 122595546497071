import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { open } from "./snackbarReducer";

export type AddItemToCartProps = {
  id: string;
  type: string;
  title: string;
  titleFR: string;
  description?: string;
  descriptionFR?: string;
  variants: any;
  price: number;
  quantity: number;
  total?: number;
  extras?: Array<any>;
  extrasActual?: Array<any>;
  selectedVariant?: object;
  selectedExtras?: Array<object>;
  taxExempt?: boolean;
  availability?: object[] | any;
};

const initialState: {
  items: Array<AddItemToCartProps>;
  productToEdit: object | null;
  productToEditIndex: number | null;
  selectedMealPlanName: string;
  selectedMealPlan: object | null;
  temporaryCartItem: AddItemToCartProps | null;
} = {
  selectedMealPlanName: "1 meal per day",
  // selectedMealPlan: {
  //   name: "1 meal per day",
  //   pricePerMeal: 8,
  //   totalPrice: 40,
  //   minimumDishPerDay: 1,
  //   deliveryFee: 0,
  //   freeDelivery: true,
  // },
  // selectedMealPlanName: 'a-la-carte',
  selectedMealPlan: null,
  temporaryCartItem: null,
  items: [],
  productToEditIndex: 0,
  productToEdit: null,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItemToCart: (state, action: PayloadAction<AddItemToCartProps>) => {
      console.log("Add Cart");
      // state.cart.push(action.payload);
      state.items.push(action.payload);
    },

    clearCart: (state) => {
      state.items = [];
    },

    removeItemFromCart: (state, action) => {
      const index = action.payload;

      state.items.splice(index, 1);
    },

    changeItemQuantityTo: (state, action) => {
      const index = action.payload.index;

      state.items[index].quantity = action.payload.quantity;
    },

    increaseItemQuantity: (state, action) => {
      const index = action.payload;
      console.log("Increate", index, state.items);
      if (!state.items[index]) return;

      state.items[index].quantity = state.items[index].quantity + 1;

      state.items[index].total =
        state.items[index].price * state.items[index].quantity;
    },

    decreaseItemQuantity: (state, action) => {
      const index = action.payload;

      if (!state.items[index]) return;

      if (state.items[index].quantity === 1) {
        state.items.splice(index, 1);
        return;
      }

      state.items[index].quantity = state.items[index].quantity - 1;

      state.items[index].total =
        state.items[index].price * state.items[index].quantity;
    },

    addExtraToProduct: (state, action) => {
      const extra = action.payload;

      //@ts-ignore
      if (state.items[state.productToEditIndex].selectedExtras.length > 0) {
        const extraPresentIndex = state.items[
          //@ts-ignore
          state.productToEditIndex
        ].selectedExtras.findIndex((e: any) => e._id === extra._id);
        if (
          //@ts-ignore
          extraPresentIndex >= 0
        ) {
          state.items[
            //@ts-ignore
            state.productToEditIndex
          ].selectedExtras[extraPresentIndex].quantity =
            state.items[
              //@ts-ignore
              state.productToEditIndex
            ].selectedExtras[extraPresentIndex].quantity + 1;

          state.items[
            //@ts-ignore
            state.productToEditIndex
          ].selectedExtras[extraPresentIndex].total =
            state.items[
              //@ts-ignore
              state.productToEditIndex
            ].selectedExtras[extraPresentIndex].quantity *
            state.items[
              //@ts-ignore
              state.productToEditIndex
            ].selectedExtras[extraPresentIndex].price;

          return;
        }
      }

      state.items[
        //@ts-ignore
        state.productToEditIndex
      ].selectedExtras.push(extra);
    },

    removeExtraFromProduct: (state, action) => {
      const index = action.payload.extraId;

      if (action.payload.editSlider) {
        const selectedExtraIndex = state.items[
          //@ts-ignore
          state.productToEditIndex
        ].selectedExtras.findIndex((e: any) => e._id === index);

        if (selectedExtraIndex !== -1 && state.items.length > 0) {
          if (
            //@ts-ignore
            state.items[state.productToEditIndex].selectedExtras[
              selectedExtraIndex
            ].quantity === 1
          ) {
            //@ts-ignore
            state.items[state.productToEditIndex].selectedExtras.splice(
              selectedExtraIndex,
              1
            );
          } else {
            //@ts-ignore
            state.items[state.productToEditIndex].selectedExtras[
              selectedExtraIndex
            ].quantity =
              //@ts-ignore
              state.items[state.productToEditIndex].selectedExtras[
                selectedExtraIndex
              ].quantity - 1;
            //@ts-ignore

            state.items[state.productToEditIndex].selectedExtras[
              selectedExtraIndex
            ].total =
              //@ts-ignore
              state.items[state.productToEditIndex].selectedExtras[
                selectedExtraIndex
              ].price *
              //@ts-ignore
              state.items[state.productToEditIndex].selectedExtras[
                selectedExtraIndex
              ].quantity;
          }
        }
      }
    },

    setProductToEdit: (state, action) => {
      console.log("Product To Edit", action, state);
      state.productToEdit = action.payload;
      state.productToEditIndex = state.items.findIndex(
        (e: any) => e.id === action.payload.id
      );
    },

    setProductToEditIndex: (state, action) => {
      state.productToEditIndex = action.payload;
    },

    resetCart: (state) => {
      state.items = [];
      state.productToEdit = null;
      state.productToEditIndex = null;
    },

    setTemporaryCartItem: (state, action) => {
      state.temporaryCartItem = action.payload;
    },

    setSelectedMealPlan: (state, action) => {
      if (setSelectedMealPlan.name === "a-la-carte") {
        state.selectedMealPlan = null;
        state.selectedMealPlanName = "a-la-carte";
      } else {
        console.log("Inside meal plan", action.payload);
        state.selectedMealPlan = action.payload;
        state.selectedMealPlanName = action.payload.name;
      }
    },
  },
});

export const {
  addItemToCart,
  clearCart,
  increaseItemQuantity,
  decreaseItemQuantity,
  removeItemFromCart,
  changeItemQuantityTo,
  setProductToEdit,
  setProductToEditIndex,
  resetCart,
  setTemporaryCartItem,
  setSelectedMealPlan,
  addExtraToProduct,
  removeExtraFromProduct,
} = cartSlice.actions;

export default cartSlice.reducer;
