import { Grid } from '@mui/material';
import React from 'react';
// import { Link } from "react-router-dom";

import './MenuItem.scss';

export default (props: any) => {
  return (
    <Grid item lg={4} md={4} sm={6} xs={6}>
      <div className={`MenuItem`}>
        <img src={props.image} className={`MenuItem__img`} />
        <div className={`MenuItem__info`}>
          <h4 className="heading-sans-serif smallHeading">{props.category}</h4>
          <h2 className="heading-sans-serif">{props.title}</h2>
          <p className="color-grey">{props.text}</p>
        </div>
      </div>
    </Grid>
  );
};
