import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  message: '',
  severity: 'success',
};

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    open: (state, action) => {
      state.message = action.payload.message;
      state.severity = action.payload.severity;
      state.open = true;
    },

    close: (state) => {
      state.open = false;
    },
  },
});

export const { open, close } = snackbarSlice.actions;

export default snackbarSlice.reducer;
