/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link as MaterialUILink,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DateRangeIcon from "@mui/icons-material/DateRange";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";

import InView from "react-intersection-observer";

import { setSelectedWeek } from "../../slices/organizationSlice";
import { getProducts } from "../../slices/productsSlice";

import ProductItem from "../../components/ProductItem/ProductItem";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";

import ProductsLoadingSkeleton from "./ProductsLoadingSkeleton";

import renderSelectedWeek from "../../modules/renderSelectedWeek";

import {
  setCurrentTab,
  setCurrentTabNonAuth,
} from "../../slices/authNavReducer";
import getProductPlatingDate from "../../utils/getProductPlatingDate";
import { fetchSubscriptionStatuses } from "../../slices/subscriptionReducer";
import { fetchOrders, unloadOrders } from "../../slices/orderSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";

import "./Home.scss";

export default function Home() {
  const dispatch = useAppDispatch();

  const { t, i18n }: any = useTranslation();
  const [category, setCurrentCategory] = React.useState("");
  const [subCategory, setCurrentSubCategory] = React.useState("");

  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  const selectedWeek = useAppSelector(
    (state) => state.organization.selectedWeek
  );

  const [selectedWeekChangeTo, setSelectedWeekChangeTo] = React.useState(0);
  const [weekChangeDialogOpen, setWeekChangeDialogOpen] = React.useState(false);

  const productsLoading: any = useAppSelector(
    (state) => state.products.productsLoading
  );

  // const organizationLoading: any = useAppSelector(
  //   (state) => state.organization.organizationLoading
  // );

  // const organization: any = useAppSelector(
  //   (state) => state.organization.organization
  // );

  const categoriesLoading: any = useAppSelector(
    (state) => state.categories.categoriesLoading
  );

  const categories: any = useAppSelector(
    (state) => state.categories.categories
  );

  const meals: Array<object> = useAppSelector((state) => state.products.meals);

  // const filtersApplied: any = useAppSelector(
  //   (state) => state.order.filtersApplied
  // );

  // const mealsSortedByDate: any = useAppSelector((state) =>
  //   state.products.meals
  //     ? Object.entries(state.products.meals[0])
  //         .map(([key, value]) => value)
  //         .sort((left: any, right: any) => {
  //           console.log(left.items);
  //           console.log(right.items);
  //           return moment
  //             .utc(
  //               getProductPlatingDate(
  //                 selectedWeek,
  //                 left.items.availability,
  //                 "date"
  //               )
  //             )
  //             .diff(
  //               moment.utc(
  //                 getProductPlatingDate(
  //                   selectedWeek,
  //                   right.items.availability,
  //                   "date"
  //                 )
  //               )
  //             );
  //         })
  //     : []
  // );

  const groceries: Array<object> = useAppSelector(
    (state) => state.products.groceries
  );

  useEffect(() => {
    dispatch(
      getProducts({
        selectedWeek: selectedWeek,
      })
    );

    dispatch(
      fetchOrders({
        $limit: 10,
        $sort: {
          createdAt: -1,
        },
      })
    );

    dispatch(fetchSubscriptionStatuses());

    return () => {
      dispatch(unloadOrders());
    };
  }, []);

  useEffect(() => {
    dispatch(
      getProducts({
        selectedWeek: selectedWeek,
      })
    );
  }, [selectedWeek]);

  const toggleSignUpMenu = () => {
    if (!isAuthenticated) {
      document.body.classList.toggle("signup-menu--open");
    }
  };

  const renderWeeks = () => {
    let loopOn = [1, 2, 3, 4];

    if ([1, 2, 3].indexOf(moment().isoWeekday()) === -1) {
      loopOn = [2, 3, 4, 5];
    }

    return loopOn.map((e: number) => {
      const weekMonth = moment().add(e, "w").format("MMMM");
      const weekStartDate = moment().add(e, "w").startOf("isoWeek").format("D");
      const weekEndDate = moment()
        .add(e, "w")
        .startOf("isoWeek")
        .add(4, "d")
        .format("D");

      return (
        <li
          key={moment(moment().add(e, "w").startOf("isoWeek")).unix()}
          onClick={() => {
            console.log(localStorage.getItem("doNotRemindWeekChange"));

            if (!window.localStorage.getItem("doNotRemindWeekChange")) {
              setWeekChangeDialogOpen(true);
              setSelectedWeekChangeTo(e);
            }

            dispatch(setSelectedWeek(e));
          }}
        >
          <a href="/#">{`Week of ${weekMonth} ${weekStartDate}-${weekEndDate}`}</a>
        </li>
      );
    });
  };

  return (
    <>
      <div className="page-home">
        <header className="home-banner">
          <Container>
            <Grid container justifyContent="center">
              <Grid item xs={10}>
                <Box display="flex" flexDirection="column">
                  <h1 className="heading-serif fw-400 heading-large text-center mt-0">
                    We cook, prepare every meal with care,
                    <br /> and deliver directly to you every day.
                  </h1>

                  <h2 className="heading-sans-serif text-center">
                    Subscribe to a meal plan and get $20 off your first order.
                  </h2>

                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className="home-banner--button-area"
                  >
                    <PrimaryButton
                      style={{
                        fontSize: "21px",
                      }}
                      onClick={() => {
                        dispatch(setCurrentTabNonAuth("signup-options"));
                        toggleSignUpMenu();
                      }}
                    >
                      Sign up{" "}
                      <span className="arrow" style={{ marginLeft: "8px" }}>
                        {"→"}
                      </span>
                    </PrimaryButton>
                    <Link className="link large-link" to="/how-it-works">
                      How it works
                    </Link>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </header>

        <main className="home-products-area">
          <div className="container products-container">
            <aside>
              <Box className="home-product-cats">
                <Accordion expanded={false} defaultExpanded={false}>
                  <AccordionSummary
                    // expandIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
                    className="background-filled"
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Box
                      width="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      onClick={() => {
                        // Button Action?
                        dispatch(setCurrentTab("profile-preferences"));
                        document.body.classList.toggle("account-menu--open");
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        <FavoriteIcon
                          style={{
                            fill: "#d24d57",
                            marginRight: "8px",
                          }}
                        />{" "}
                        Preferences
                      </Box>

                      {false && <span className="prefs-counter">0</span>}
                    </Box>
                  </AccordionSummary>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    className="background-filled"
                    expandIcon={<ExpandMoreIcon style={{ color: "#000" }} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Box
                      display="flex"
                      width="100%"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box display="flex" alignItems="center" width="100%">
                        <DateRangeIcon
                          style={{
                            marginRight: "8px",
                          }}
                        />{" "}
                        {renderSelectedWeek(selectedWeek)}
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className="prepared-meals-cats">
                      {renderWeeks()}
                      <li className="background-filled"></li>
                    </ul>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    className="background-filled"
                    expandIcon={<ExpandMoreIcon style={{ color: "#000" }} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Box
                      display="flex"
                      width="100%"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box display="flex" alignItems="center" width="100%">
                        <RestaurantMenuIcon
                          style={{
                            marginRight: "8px",
                          }}
                        />{" "}
                        <span>Prepared meals</span>
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className="prepared-meals-cats">
                      <li>
                        <a href="/#">Breakfast</a>
                      </li>
                      <li>
                        <a href="/#">Lunch and Dinner</a>
                      </li>
                      <li>
                        <a href="/#">Dessert</a>
                      </li>

                      <li className="background-filled"></li>
                    </ul>
                  </AccordionDetails>
                </Accordion>

                <Accordion defaultExpanded={false}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "#000" }} />}
                    className="background-filled"
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Box display="flex" alignItems="center" width="100%">
                      <ShoppingCartIcon
                        style={{
                          marginRight: "8px",
                        }}
                      />{" "}
                      {t("grocery")}
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className="prepared-meals-cats">
                      {!categoriesLoading && (
                        <>
                          {categories.data
                            .filter((e: any) => e.type === "Grocery")
                            .map((cat: any) => {
                              return (
                                <li>
                                  <a
                                    className={
                                      category === cat.name ? "selected" : ""
                                    }
                                    href="/#"
                                  >
                                    {cat.name}
                                  </a>
                                  {cat.subCats && (
                                    <ul>
                                      {cat.subCats.map((subcat: any) => (
                                        <li>
                                          <a
                                            className={
                                              subCategory === subcat.name
                                                ? "selected"
                                                : ""
                                            }
                                            href="/#"
                                          >
                                            {subcat.name}
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </li>
                              );
                            })}
                        </>
                      )}
                    </ul>
                  </AccordionDetails>
                </Accordion>

                <Box mt={4}></Box>

                {/* {filtersApplied.map((e: Array<string | any>, index: number) => {
                  // console.log(e);
                  //@ts-ignore
                  if (e !== "day") {
                    return (
                      <Chip
                        onDelete={() => {
                          dispatch(removeFilterByIndex());
                          dispatch(
                            open({
                              message: "Filter removed",
                              severity: "success",
                            })
                          );
                        }}
                        className="chip-default chip-filter chip-inverse chip-squared"
                        label={`Day: ${moment(e).format("dddd, MMMM Do")}`}
                      />
                    );
                  }
                })} */}
              </Box>
            </aside>

            <div
              className={`products-area-container ${
                productsLoading && "products-area--loading"
              }`}
            >
              <div>
                <h2
                  className="heading-large fw-400 mt-0"
                  style={{ marginTop: "12px" }}
                >
                  Meal plans
                </h2>
              </div>
              <div className="products-area">
                {/* <article
                className="products-area__item products-area__item--plan products-area__item--plan--a-la-carte"
                itemType="plan"
              >
                <Box>
                  <h3 className="heading-large fw-400 products-area__item--plan__name">
                    À la carte
                  </h3>
                  <Box className="products-area__item--plan__info">
                    <h3 className="heading-sans-serif products-area__item--plan__pricing">
                      Starting at $7.50
                    </h3>
                    <p className="products-area__item--plan__description">
                      Choose how often you’d like to eat and what you like to
                      eat — in any combination with no limitations.
                    </p>
                    <PrimaryButton className="products-area__item--plan__buy-btn">
                      Choose this plan <span className="arrow">{'→'}</span>
                    </PrimaryButton>
                  </Box>
                </Box>
              </article>
              <article
                className="products-area__item products-area__item--plan products-area__item--plan--lunch-dinner"
                itemType="plan"
              >
                <Box>
                  <h3 className="heading-large fw-400 products-area__item--plan__name">
                    Lunch & dinner
                  </h3>
                  <Box className="products-area__item--plan__info">
                    <h3 className="heading-sans-serif products-area__item--plan__pricing">
                      Starting at $100/week
                    </h3>
                    <p className="products-area__item--plan__description">
                      A meal plan that changes weekly. Choose between a
                      variety of meat, fish, and plant-based offerings.
                    </p>
                    <PrimaryButton className="products-area__item--plan__buy-btn">
                      Choose this plan <span className="arrow">{'→'}</span>
                    </PrimaryButton>
                  </Box>
                </Box>
              </article>
              <article
                className="products-area__item products-area__item--plan products-area__item--plan--bld"
                itemType="plan"
              >
                <h3 className="heading-large fw-400 products-area__item--plan__name">
                  Breakfast, lunch & dinner
                </h3>
                <Box className="products-area__item--plan__info">
                  <h3
                    className="heading-sans-serif products-area__item--plan__pricing"
                    style={{ marginBottom: '1em' }}
                  >
                    Starting at $145/week
                  </h3>
                  <PrimaryButton className="products-area__item--plan__buy-btn">
                    Choose this plan <span className="arrow">{'→'}</span>
                  </PrimaryButton>
                </Box>
              </article> */}
              </div>

              <Grid container spacing={2}>
                <Grid container item xs={12}>
                  <h2 className="heading-large fw-400 mt-0">
                    <span className="prepared-meals">{t("preparedMeals")}</span>
                  </h2>
                </Grid>
              </Grid>

              {!productsLoading &&
                meals.length > 0 &&
                [...Object.entries(meals[0])].map(
                  ([key, value], index: number) => (
                    <InView
                      as="div"
                      threshold={0.5}
                      onChange={(inView, entry) => {
                        if (inView) {
                          setCurrentCategory("categoryOne");
                          setCurrentSubCategory("subcategoryOne");
                        }
                      }}
                      id={`category-${index} category-${key}`}
                      style={{
                        width: "100%",
                      }}
                    >
                      <Grid
                        className="products-area__category"
                        container
                        spacing={3}
                      >
                        <Grid item xs={12}>
                          <Box
                            width="100%"
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <h1
                              className="heading-sans-serif fw-400 mt-0"
                              style={{ marginBottom: "0", marginTop: 0 }}
                            >
                              {key}
                            </h1>

                            <p
                              className="small-heading"
                              style={{
                                color: "#707E81",
                                marginTop: "4px",
                                marginBottom: "0",
                                textTransform: "uppercase",
                              }}
                            >
                              Showing {value.length} of {value.length} product
                              {value.length > 1 ? "s" : ""}
                            </p>
                          </Box>
                        </Grid>
                      </Grid>
                      <div
                        className={`products-area ${
                          productsLoading && "products-area--loading"
                        }`}
                        data-category={`'`}
                      >
                        {productsLoading && (
                          <>
                            <ProductsLoadingSkeleton />
                            <ProductsLoadingSkeleton />
                            <ProductsLoadingSkeleton />
                            <ProductsLoadingSkeleton />
                            <ProductsLoadingSkeleton />
                            <ProductsLoadingSkeleton />
                            <ProductsLoadingSkeleton />
                            <ProductsLoadingSkeleton />
                          </>
                        )}
                        {
                          //@ts-ignore
                          value
                            .slice()
                            .sort((left: any, right: any) => {
                              return moment
                                .utc(
                                  getProductPlatingDate(
                                    selectedWeek,
                                    left.items.availability,
                                    "date"
                                  )
                                )
                                .diff(
                                  moment.utc(
                                    getProductPlatingDate(
                                      selectedWeek,
                                      right.items.availability,
                                      "date"
                                    )
                                  )
                                );
                            })
                            .filter((e: any) => {
                              // if (filtersApplied.length !== 0) {
                              //   return (
                              //     moment(filtersApplied[1]).format("D") ===
                              //     moment(
                              //       getProductPlatingDate(
                              //         selectedWeek,
                              //         e.items.availability,
                              //         "date"
                              //       )
                              //     ).format("D")
                              //   );
                              // }

                              return true;
                            })
                            .map((e: any) => {
                              // console.log(e);
                              return (
                                <ProductItem
                                  key={e.items._id}
                                  image={
                                    e.items.variants[0].thumbnailURL
                                      ? `https://s3.amazonaws.com/vittle-new/${e.items.variants[0].thumbnailURL}`
                                      : "https://via.placeholder.com/720x720/ccc"
                                  }
                                  id={e.items._id}
                                  type={e.items.type}
                                  variants={e.items.variants}
                                  title={e.items.title}
                                  titleFR={e.items.titleFR || ""}
                                  description={e.items.description}
                                  descriptionFR={e.items.descriptionFR || ""}
                                  link={e.items.link}
                                  extras={e.items.extras || []}
                                  extrasActual={e.items.extrasActual}
                                  platingDate={new Date().toDateString()}
                                  quantity={0}
                                  availability={e.items.availability || []}
                                />
                              );
                            })
                        }
                      </div>
                    </InView>
                  )
                )}

              <Grid container spacing={2}>
                <Grid container item xs={12}>
                  <h2 className="heading-large fw-400 mt-0">
                    <span className="grocery">{t("grocery")}</span>
                  </h2>
                </Grid>
              </Grid>

              {!productsLoading &&
                groceries[0] &&
                Object.entries(groceries[0]).map(
                  ([key, value], parentIndex: number) => (
                    <InView
                      as="div"
                      threshold={0.5}
                      onChange={(inView, entry) => {
                        if (inView) {
                          setCurrentCategory("categoryOne");
                          setCurrentSubCategory("subcategoryOne");
                        }
                      }}
                      id={`category-${parentIndex} category-${key}`}
                      style={{
                        width: "100%",
                      }}
                    >
                      <Grid
                        className="products-area__category"
                        container
                        spacing={3}
                      >
                        <Grid item xs={12}>
                          <Box
                            width="100%"
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <h1
                              className="heading-sans-serif fw-400 mt-0"
                              style={{ marginBottom: "0", marginTop: 0 }}
                            >
                              <span className="uncategorized">
                                {key === "" ? "Uncategorized" : key}
                              </span>
                            </h1>

                            <p
                              className="small-heading"
                              style={{
                                color: "#707E81",
                                marginTop: "4px",
                                marginBottom: "0",
                                textTransform: "uppercase",
                              }}
                            >
                              Showing {value[0].items.items.length} of{" "}
                              {value[0].items.items.length} product
                              {value[0].items.items.length > 1 ? "s" : ""}
                            </p>
                          </Box>
                        </Grid>
                      </Grid>
                      <div
                        className={`products-area ${
                          productsLoading && "products-area--loading"
                        }`}
                        data-category={`'`}
                      >
                        {productsLoading && (
                          <>
                            <ProductsLoadingSkeleton />
                            <ProductsLoadingSkeleton />
                            <ProductsLoadingSkeleton />
                            <ProductsLoadingSkeleton />
                            <ProductsLoadingSkeleton />
                            <ProductsLoadingSkeleton />
                            <ProductsLoadingSkeleton />
                            <ProductsLoadingSkeleton />
                          </>
                        )}

                        {/* {console.log(value)} */}
                        {
                          //@ts-ignore
                          value[0].items.items.map((e: any) => {
                            // console.log(e);
                            return (
                              <ProductItem
                                key={e._id}
                                image={
                                  e.variants[0].thumbnailURL
                                    ? `https://s3.amazonaws.com/vittle-new/${e.variants[0].thumbnailURL}`
                                    : "https://via.placeholder.com/720x720/ccc"
                                }
                                id={e._id}
                                type={e.type}
                                variants={e.variants}
                                title={e.title}
                                titleFR={e.titleFR || ""}
                                extras={e.extras || []}
                                extrasActual={e.extrasActual || []}
                                description={e.description}
                                descriptionFR={e.descriptionFR || ""}
                                link={e.link}
                                platingDate={new Date()}
                                quantity={0}
                              />
                            );
                          })
                        }
                      </div>
                    </InView>
                  )
                )}
            </div>
          </div>
        </main>
        <Dialog
          open={weekChangeDialogOpen}
          keepMounted
          onClose={() => setWeekChangeDialogOpen(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle style={{ marginTop: "20px" }}>
            Changing weeks will affect items in your cart
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Changing the current week affects your delivery. Cut-off to order
              for the week of July 19, 2021 is 4 days away.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Box
              display="flex"
              alignItems="center"
              width="100%"
              justifyContent="space-between"
              paddingX={2}
              paddingTop={2}
              paddingBottom={2}
            >
              <MaterialUILink
                variant="body1"
                color="textSecondary"
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => {
                  window.localStorage.setItem("doNotRemindWeekChange", "yes");
                  dispatch(setSelectedWeek(selectedWeekChangeTo));
                  setWeekChangeDialogOpen(false);
                }}
              >
                Do not remind me again
              </MaterialUILink>

              <Box>
                <Button
                  color="secondary"
                  onClick={() => setWeekChangeDialogOpen(false)}
                  style={{
                    marginRight: "8px",
                    boxShadow: "none",
                    padding: "6px 16px",
                  }}
                >
                  Cancel
                </Button>
                <PrimaryButton
                  style={{ boxShadow: "none", padding: "6px 16px" }}
                  onClick={() => {
                    dispatch(setSelectedWeek(selectedWeekChangeTo));
                    setWeekChangeDialogOpen(false);
                  }}
                >
                  Continue
                </PrimaryButton>
              </Box>
            </Box>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
