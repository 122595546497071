import axios from "axios";

export function retrieveById(Id: String) {
  return axios({
    method: "get",
    url: "https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Retrieve/v2.11/json3.ws",
    params: {
      Id,
      Key: process.env.REACT_APP_ADDRESS_COMPLETE_KEY,
    },
  });
}

export function findPlace(lastId: String, searchTerm: String) {
  return axios({
    method: "get",
    url: "https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/v2.10/json3.ws",
    params: {
      LastId: lastId,
      SearchTerm: searchTerm,
      Key: process.env.REACT_APP_ADDRESS_COMPLETE_KEY,
      Country: "CAN",
    },
  });
}
