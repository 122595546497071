/* eslint-disable import/no-anonymous-default-export */
import feathers from "../../feathers";

export default {
  register: (customer: any) => {
    return feathers.service("users").create(customer);
  },

  addSecondaryProfile: (customer: any) => {
    return feathers.service("users").create(customer);
  },

  getSecondaryAccounts: (primaryAccountId: string) => {
    return feathers.service("users").find({
      query: {
        primaryAccountId: primaryAccountId,
        secondary: true,
      },
    });
  },

  changePassword: (id: string, password: string) => {
    return feathers.service("users").patch(id, {
      password: password,
    });
  },

  updateProfile: (id: string, data: any) => {
    console.log("here api", data, id);
    return feathers.service("users").patch(id, {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      birthdayDay: data.birthdayDay,
      birthdayMonth: data.birthdayMonth,
    });
  },

  deleteAccount: (id: string) => {
    return feathers.service("users").patch(id, {
      deleted: true,
    });
  },

  updateNotifications: (id: string, notifications: object) => {
    return feathers.service("users").patch(id, {
      "settings.notifications": notifications,
    });
  },

  updateLanguage: (id: string, language: string) => {
    return feathers.service("users").patch(id, {
      "settings.language": language,
    });
  },

  updateCustomerAddress: (
    id: string,
    address: [Object] | any[],
    queryData: object
  ) => {
    return feathers.service("users").patch(
      id,
      {
        address,
      },
      {
        query: {
          ...queryData,
        },
      }
    );
  },

  updateDeliveryPreferences: (
    id: string,
    deliveryTime: string,
    deliveryType: string
  ) => {
    return feathers.service("subscriptions").patch(id, {
      deliveryType,
      deliveryTime,
    });
  },
};
