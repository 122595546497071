import React from 'react';
import LinkWithArrow from '../LinkWithArrow/LinkWithArrow';

export default (props: any) => {
  let imageRow = [`ImageTextBlock`];

  if (props.rowReverse) {
    imageRow = [`ImageTextBlockReverse`];
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div
            className={`${
              props.rowReverse ? 'ImageTextBlockReverse' : 'ImageTextBlock'
            }`}
          >
            <div className={`ImageTextBlock__Image`}>
              <img alt={props.imageAlt} src={props.image} />
            </div>
            <div className={`ImageTextBlock__Text`}>
              <h2 className="heading-sans-serif">{props.heading}</h2>
              <p>{props.text}</p>
              {props.link && (
                <LinkWithArrow
                  to={props.link}
                  text={props.linkText}
                  label={props.linkLabel}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
