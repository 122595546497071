/* eslint-disable import/no-anonymous-default-export */
import feathers from "../../feathers";

export default {
  find: (query: object | undefined) => {
    if (query) {
      return feathers.service("orders").find({
        query: query,
      });
    }

    return feathers.service("orders").find();
  },
  get: (id: string) => {
    return feathers.service("orders").get(id);
  },

  create: (order: object) => {
    return feathers.service("orders").create(order);
  },

  patch: (id: string, data: object) => {
    return feathers.service("orders").patch(id, data);
  },
};
