import feathers from '../../feathers';

export default {
  get: (id: string) => {
    return feathers.service('gift-cards').get(id);
  },

  find: (query: object | undefined = undefined) => {
    if (query) {
      return feathers.service('gift-cards').find({
        query,
      });
    }

    return feathers.service('gift-cards').find();
  },

  addGiftCardCode: (
    subId: string,
    code: string,
  ) => {
    return feathers.service('subscriptions').patch(
      subId,
      {
        code,
        addGiftCard: true,
      },
    );
  },

};
