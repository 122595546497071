/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from "react";
import { useParams } from "react-router-dom";
// import TextField from "../components/TextField/TextField";
import * as Yup from "yup";

import TextField from "../components/TextField/TextField";
import feathers from "../feathers";
import Helmet from "react-helmet";

import { Formik } from "formik";

import { useTranslation } from "react-i18next";

import { Box, Container, Grid, IconButton } from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import PrimaryButton from "../components/PrimaryButton/PrimaryButton";
export default function () {
  const { token } = useParams();
  // const token = props.match.params.token;

  const [newPassword, setNewPassword] = useState("");
  const { t, i18n }: any = useTranslation();

  const [resetSuccessfully, setResetSuccessfully] = useState(false);
  const [alreadySent, setAlreadySent] = useState(false);
  const [passwordVisible, setPasswordVisible] = React.useState(false);

  return (
    <>
      <Helmet title="Catering Us" />
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={9} md={9}>
            {!alreadySent ? (
              <Box
                p={8}
                display="flex"
                alignItems="Center"
                flexDirection="column"
              >
                <Formik
                  initialValues={{ password: "" }}
                  validationSchema={Yup.object().shape({
                    password: Yup.string().required("Password is required"),
                  })}
                  onSubmit={(values, { setSubmitting }) => {
                    feathers
                      .service("authManagement")
                      .create({
                        action: "resetPwdLong",
                        value: {
                          token: token, // compares to .resetToken
                          password: values.password, // new password
                        },
                      })
                      .then((e: any) => {
                        console.log("success");
                        setNewPassword(values.password);
                        setResetSuccessfully(true);

                        console.log("success", e);
                        // setNewPassword()
                      })
                      .catch((err: any) => {
                        console.log(err);
                        setResetSuccessfully(false);
                      })
                      .finally(() => {
                        // @ts-ignore
                        setAlreadySent(true);
                      });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <form className="form-login" onSubmit={handleSubmit}>
                      <h2
                        className="heading-sans-serif text-center"
                        style={{ margin: 0, paddingBottom: "10px" }}
                      >
                        New Password :
                      </h2>
                      <TextField
                        color="secondary"
                        label={t("Password")}
                        name="password"
                        type={passwordVisible ? "text" : "password"}
                        fullWidth
                        value={values.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(errors.password && touched.password)}
                        style={{ paddingRight: "6px" }}
                        // helperText={
                        //   errors.password && touched.password && errors.password
                        // }
                        InputProps={{
                          endAdornment: passwordVisible ? (
                            <IconButton
                              style={{
                                position: "absolute",
                                right: 0,
                              }}
                              size="small"
                              onClick={() =>
                                setPasswordVisible(!passwordVisible)
                              }
                            >
                              <VisibilityIcon
                                style={{ cursor: "pointer", color: "#b2b9b6" }}
                              />
                            </IconButton>
                          ) : (
                            <IconButton
                              style={{
                                position: "absolute",
                                right: 0,
                              }}
                              size="small"
                              onClick={() =>
                                setPasswordVisible(!passwordVisible)
                              }
                            >
                              <VisibilityOffIcon
                                style={{ cursor: "pointer", color: "#b2b9b6" }}
                              />
                            </IconButton>
                          ),
                        }}
                      />
                      <PrimaryButton
                        type="submit"
                        style={{ width: "100%", marginTop: "1em" }}
                      >
                        Send
                      </PrimaryButton>
                    </form>
                  )}
                </Formik>
              </Box>
            ) : resetSuccessfully ? (
              <Box
                p={8}
                display="flex"
                alignItems="Center"
                flexDirection="column"
              >
                <h1 className="heading-serif fw-400 heading-large text-center mt-0">
                  Reset Password Successfully!
                </h1>

                <h4>New Password : {newPassword}</h4>
              </Box>
            ) : (
              <Box
                p={8}
                display="flex"
                alignItems="Center"
                flexDirection="column"
              >
                <h1 className="heading-serif fw-400 heading-large text-center mt-0">
                  Token URL is not correct. <br />
                  Please try again
                </h1>
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
