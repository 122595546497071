import { retrieveById } from '.';

export default async function fetchFinalAddress(
  Id: String,
  setDescriptiveAddress: Function,
  returnAddressItem: Boolean = false
) {
  console.log("fetch ID", Id)
  const response = await retrieveById(Id);

  console.log("fetch final address response", response);
  if (response.data.hasOwnProperty('Items')) {
    if (response.data.Items.length > 0) {
      // setAddressCorrect(true);
      setDescriptiveAddress(
        response.data.Items[1] ? response.data.Items[1] : response.data.Items[0]
      );
    }
  }
}
