import React from 'react';
import { Box, Skeleton, Typography } from '@mui/material';

import '../../components/ProductItem/ProductItem.scss';

export default (props: any) => {
  return (
    <article className="products-area__item" key={props.index || null}>
      <Skeleton animation="wave" variant="rectangular" height={310} />
      <Box p={3} height={200}>
        <Box width="100%" mt={2}>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" width="60%" />
        </Box>
        <Box
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          marginTop={3}
        >
          <Box width="45%">
            <Skeleton animation="wave" />
            <Skeleton animation="wave" width="60%" />
          </Box>
          <Box
            width="45%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box width="40%">
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </Box>

            <Box width="40%">
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </Box>
          </Box>
        </Box>
      </Box>
    </article>
  );
};
