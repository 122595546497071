/* eslint-disable import/no-anonymous-default-export */
import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import feathers from "../feathers";

export default function () {
  const { token } = useParams();

  const [checkingVerify, setCheckingVerify] = useState(true);
  const [verifiedSuccessfully, setVerifiedSuccessfully] = useState(false);
  const [verifiedError, setVerifiedError] = useState("");

  console.log(token);

  useEffect(() => {
    feathers
      .service("authManagement")
      .create({
        action: "verifySignupLong",
        value: token,
      })
      .then((e: any) => {
        console.log("success");
        console.log(e);
        setVerifiedSuccessfully(true);
      })
      .catch((err: any) => {
        console.log(err);
        setVerifiedError("Could not verify this token");
      })
      .finally(() => {
        setCheckingVerify(false);
      });
  }, []);

  if (!checkingVerify && verifiedError !== "") {
    return (
      <>
        <div style={{ textAlign: "center", marginTop: "40px" }}>
          <h1>There was error verifying this email.</h1>
        </div>
      </>
    );
  }

  if (!checkingVerify && verifiedSuccessfully) {
    return (
      <>
        <div style={{ textAlign: "center", marginTop: "40px" }}>
          <h1>Email verified successfully</h1>
        </div>
      </>
    );
  }

  if (checkingVerify) {
    return (
      <>
        <div style={{ textAlign: "center", marginTop: "40px" }}>
          <CircularProgress size={45} />
          <h1>Verifying your email</h1>
          <p>Hold on while while we verify your email.</p>
        </div>
      </>
    );
  }

  return (
    <>
      <h4>{token}</h4>
    </>
  );
}
