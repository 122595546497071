/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Divider,
  Grid,
  Select,
  MenuItem,
} from "@mui/material";
import currency from "currency.js";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import {
  addItemToCart,
  decreaseItemQuantity,
  increaseItemQuantity,
  addExtraToProduct,
  removeExtraFromProduct,
  setProductToEdit,
} from "../../slices/cartReducer";
import { open } from "../../slices/snackbarReducer";
import itemHasExtra from "../../modules/itemHasExtra";
import getExtrasTotalOnWholeOrder from "../../modules/getExtrasTotalOnWholeOrder";
import getSelectedExtrasOnItem from "../../modules/getSelectedExtrasOnItem";
import getSingleExtraTotalOnItem from "../../modules/getSingleExtraTotalOnItem";
import getSingleExtraCountOnItem from "../../modules/getSingleExtraCountOnItem";
import calculateTotalItemPrice from "../../modules/calculateTotalItemPrice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  addItemToSubscriptionOrder,
  decreaseSubscriptionOrderItemQuantity,
  increaseSubscriptionOrderItemQuantity,
} from "../../slices/subscriptionReducer";

export default function () {
  const dispatch = useAppDispatch();

  const [addingProductToCart, setAddingProductTocart] = React.useState(false);

  const productToEditFromCart: any = useAppSelector((state) => {
    if (state.subscription.viewingProductFromSubscripionWeek) {
      return state.subscription.currentViewingSubscriptionOrderItems.find(
        (e: any, index: number) =>
          index === state.subscription.subscriptionOrderItemProductToEditIndex
      );
    }
    return state.cart.items.find(
      (e: any, index: number) => index === state.cart.productToEditIndex
    );
  });

  const cartItems: any = useAppSelector((state) =>
    state.subscription.viewingProductFromSubscripionWeek
      ? state.subscription.currentViewingSubscriptionOrderItems
      : state.cart.items
  );

  const viewingProductFromSubscripionWeek: boolean = useAppSelector(
    (state) => state.subscription.viewingProductFromSubscripionWeek
  );

  const productToEdit: any = useAppSelector((state) =>
    state.subscription.viewingProductFromSubscripionWeek
      ? state.subscription.subscriptionOrderItemProductToEdit
      : state.cart.productToEdit
  );

  const product: any = useAppSelector((state) => state.products.product);
  const productLoading: boolean = useAppSelector(
    (state) => state.products.productLoading
  );

  const isProductAdded: any = useAppSelector((state) =>
    state.subscription.viewingProductFromSubscripionWeek
      ? state.subscription.currentViewingSubscriptionOrderItems.findIndex(
          (e: any) => e.id === productToEdit.id
        ) >= 0
      : state.cart.items.findIndex((e) => e.id === productToEdit.id) >= 0
  );

  const productToEditIndex: any = useAppSelector((state) =>
    state.subscription.viewingProductFromSubscripionWeek
      ? state.subscription.subscriptionOrderItemProductToEditIndex
      : state.cart.productToEditIndex
  );

  const selectedMealPlan: null | any = useAppSelector((state) =>
    state.subscription.viewingProductFromSubscripionWeek
      ? state.subscription.currentlyViewingSubscriptionOrderMealPlan
      : state.cart.selectedMealPlan
  );

  const selectedMealPlanName = useAppSelector((state) =>
    state.subscription.viewingProductFromSubscripionWeek
      ? state.subscription.currentlyViewingSubscriptionOrderMealPlanName
      : state.cart.selectedMealPlanName
  );

  useEffect(() => {
    // //fetch product extras
    // if (state.subscription.viewingProductFromSubscripionWeek) {
    // } else {
    // dispatch(getProduct(productToEditFromCart._id));
    // }
  }, []);

  const addToCart = () => {
    setAddingProductTocart(true);
    setTimeout(() => {
      // change variant to selected variant once it's implemented

      if (viewingProductFromSubscripionWeek) {
        dispatch(
          addItemToSubscriptionOrder({
            id: productToEdit.id,
            type: productToEdit.type || "",
            title: productToEdit.title,
            titleFR: productToEdit.titleFR,
            description: productToEdit.description,
            descriptionFR: productToEdit.descriptionFR,
            availability: productToEdit.availability || [],
            variants: productToEdit.variants,
            extras: productToEdit.extras,
            extrasActual: productToEdit.extrasActual,
            selectedExtras: [],
            selectedVariant: productToEdit.variants[0],
            quantity: 1,
            price: Number(productToEdit.variants[0].price),
            total: Number(productToEdit.variants[0].price),
          })
        );
      }

      dispatch(
        addItemToCart({
          id: productToEdit.id,
          type: productToEdit.type,
          title: productToEdit.title,
          titleFR: productToEdit.titleFR,
          description: productToEdit.description,
          descriptionFR: productToEdit.descriptionFR,
          extras: productToEdit.extras,
          extrasActual: productToEdit.extrasActual,
          selectedExtras: [],
          variants: productToEdit.variants,
          selectedVariant: productToEdit.variants[0],
          quantity: 1,
          price: Number(productToEdit.variants[0].price),
          total: Number(productToEdit.variants[0].price),
        })
      );

      dispatch(
        setProductToEdit({
          id: productToEdit.id,
          type: productToEdit.type,
          title: productToEdit.title,
          titleFR: productToEdit.titleFR,
          description: productToEdit.description,
          descriptionFR: productToEdit.descriptionFR,
          extras: productToEdit.extras,
          extrasActual: productToEdit.extrasActual,
          selectedExtras: [],
          variants: productToEdit.variants,
          selectedVariant: productToEdit.variants[0],
          quantity: 1,
          price: Number(productToEdit.variants[0].price),
          total: Number(productToEdit.variants[0].price),
        })
      );

      dispatch(
        open({
          message: productToEdit.title + " added to cart",
          severity: "success",
        })
      );

      setAddingProductTocart(false);
    }, 500);
  };

  return (
    <>
      <div
        style={{
          margin: "-1rem -24px 2em",
        }}
      >
        <img
          style={{
            maxWidth: "100%",
          }}
          src={
            productToEdit.variants[0].thumbnailURL
              ? `https://s3.amazonaws.com/vittle-new/${productToEdit.variants[0].thumbnailURL}`
              : "https://via.placeholder.com/720x720/dedede"
          }
          alt=""
        />
      </div>
      <div className="form-login">
        <h2 className="heading-sans-serif">{productToEdit.title}</h2>
        <p>{productToEdit.description}</p>

        <Grid
          container
          spacing={2}
          style={{ marginTop: "10px" }}
          alignItems="center"
          justifyContent="space-between"
        >
          {productToEdit.variants.length > 1 && (
            <Grid item xs={12} md={12}>
              <Select
                placeholder="Select variant"
                fullWidth
                variant="outlined"
                value={productToEdit.variants[0]._id}
                label="Select variant"
                // onChange={(ev: any, value: any) => {
                //   console.log(ev.target.value);
                //   // dispatch(setSee)
                // }}
              >
                {productToEdit.variants.map((variant: any) => (
                  <MenuItem value={variant._id}>{variant.name}</MenuItem>
                ))}
              </Select>
            </Grid>
          )}
          <Grid item>
            <Divider />
          </Grid>

          <Grid item xs={12} md={12}>
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mt={2}
            >
              <Box className="products-area__item__info__pricing">
                <Box display="flex" alignItems="center">
                  {isProductAdded &&
                    productToEditFromCart?.hasOwnProperty("quantity") && (
                      <>
                        {productToEditFromCart.quantity !== 0 && (
                          <span className="products-area__item__info__pricing__count">
                            {productToEditFromCart.quantity}
                          </span>
                        )}
                      </>
                    )}

                  {isProductAdded && productToEdit.quantity > 0 ? (
                    <span className="products-area__item__info__pricing__price">
                      {currency(
                        calculateTotalItemPrice(
                          productToEditFromCart,
                          selectedMealPlan !== null
                            ? selectedMealPlan.pricePerMeal
                            : undefined
                        )
                      ).format()}
                    </span>
                  ) : (
                    <p className="products-area__item__info__pricing__price">
                      {isProductAdded
                        ? currency(productToEdit.variants[0].price).format()
                        : currency(productToEdit.variants[0].price).format()}
                    </p>
                  )}
                </Box>
              </Box>
              <Box>
                {isProductAdded && (
                  <PrimaryButton
                    className={`products-area__item__info__pricing__edit-btn products-area__item__info__pricing__edit-btn--visible`}
                    onClick={() => {
                      if (viewingProductFromSubscripionWeek) {
                        dispatch(
                          decreaseSubscriptionOrderItemQuantity(
                            productToEditIndex
                          )
                        );
                      } else {
                        dispatch(decreaseItemQuantity(productToEditIndex));
                      }
                    }}
                  >
                    <RemoveIcon />
                  </PrimaryButton>
                )}
                <PrimaryButton
                  className="products-area__item__info__pricing__add-btn"
                  onClick={() => {
                    if (isProductAdded) {
                      if (viewingProductFromSubscripionWeek) {
                        dispatch(
                          increaseSubscriptionOrderItemQuantity(
                            productToEditIndex
                          )
                        );
                      } else {
                        dispatch(increaseItemQuantity(productToEditIndex));
                      }
                    } else {
                      addToCart();
                    }
                  }}
                  disabled={addingProductToCart}
                >
                  <AddIcon />
                </PrimaryButton>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={12}>
            <div className="cart-line-items-container">
              {!productLoading && product && (
                <>
                  {product.extrasActual.length > 0 && (
                    <Accordion defaultExpanded={true}>
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon style={{ color: "#000" }} />
                        }
                        className="background-filled"
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Box
                          width="100%"
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            width="100%"
                          >
                            <Box display="flex" alignItems="center">
                              <Box display="flex" alignItems="center">
                                <p className="offscreen-menu__content__section-heading fw-400 mb-0 mt-0">
                                  Extras
                                </p>
                                {getExtrasTotalOnWholeOrder(cartItems) > 0 && (
                                  <span
                                    style={{ marginLeft: "8px" }}
                                    className="products-area__item__info__pricing__count"
                                  >
                                    {getSelectedExtrasOnItem(
                                      productToEditFromCart
                                    )}
                                  </span>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails
                        style={{
                          flexDirection: "column",
                          paddingLeft: "24px",
                          paddingRight: "24px",
                        }}
                      >
                        {productToEdit.extrasActual.map(
                          (e: any, index: number) => {
                            return (
                              <Box
                                width="100%"
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Box>
                                  <p className="mb-1">{e.title}</p>
                                  <p
                                    className="mt-0 color-secondary-2"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {currency(e.variants[0].price).format()}
                                  </p>
                                </Box>

                                <Box display="flex" alignItems="center">
                                  {getSingleExtraCountOnItem(
                                    cartItems,
                                    productToEdit.id,
                                    e._id
                                  ) > 0 && (
                                    <span
                                      style={{
                                        marginLeft: "8px",
                                        marginRight: "6px",
                                        width: "20px",
                                        height: "20px",
                                        fontSize: "12px",
                                      }}
                                      className="products-area__item__info__pricing__count"
                                    >
                                      {getSingleExtraCountOnItem(
                                        cartItems,
                                        productToEdit.id,
                                        e._id
                                      )}
                                    </span>
                                  )}

                                  <span style={{ marginRight: "10px" }}>
                                    {getSingleExtraTotalOnItem(
                                      cartItems,
                                      productToEdit.id,
                                      e._id
                                    ) !== 0
                                      ? getSingleExtraTotalOnItem(
                                          cartItems,
                                          productToEdit.id,
                                          e._id
                                        )
                                      : ""}
                                  </span>

                                  {itemHasExtra(
                                    e._id,
                                    cartItems,
                                    productToEdit.id
                                  ) ? (
                                    <PrimaryButton
                                      className={`products-area__item__info__pricing__edit-btn products-area__item__info__pricing__add-btn--extra products-area__item__info__pricing__edit-btn--visible`}
                                      onClick={() =>
                                        dispatch(
                                          removeExtraFromProduct({
                                            extraId: e._id,
                                            editSlider: true,
                                          })
                                        )
                                      }
                                    >
                                      <RemoveIcon />
                                    </PrimaryButton>
                                  ) : (
                                    ""
                                  )}
                                  <PrimaryButton
                                    className="products-area__item__info__pricing__add-btn products-area__item__info__pricing__add-btn--extra"
                                    onClick={() =>
                                      dispatch(
                                        addExtraToProduct({
                                          ...e,
                                          quantity: 1,
                                          price: Number(e.variants[0].price),
                                          total: Number(e.variants[0].price),
                                        })
                                      )
                                    }
                                  >
                                    <AddIcon />
                                  </PrimaryButton>
                                </Box>
                              </Box>
                            );
                          }
                        )}
                      </AccordionDetails>
                    </Accordion>
                  )}
                </>
              )}
              <Accordion defaultExpanded={false}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: "#000" }} />}
                  className="background-filled"
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Box display="flex" alignItems="center">
                        <Box>
                          <p className="offscreen-menu__content__section-heading fw-400 mb-0 mt-0">
                            Ingredients
                          </p>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails
                  style={{ flexDirection: "column" }}
                ></AccordionDetails>
              </Accordion>
              <Accordion defaultExpanded={false}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: "#000" }} />}
                  className="background-filled"
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Box display="flex" alignItems="center">
                        <Box>
                          <p className="offscreen-menu__content__section-heading fw-400 mb-0 mt-0">
                            Nutritional facts
                          </p>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails
                  style={{ flexDirection: "column" }}
                ></AccordionDetails>
              </Accordion>
            </div>
          </Grid>

          {/* <Grid item xs={12} md={2}>
                <Tooltip
                  title="Remove"
                  onClick={() =>
                    dispatch(removeItemFromCart(productToEditIndex))
                  }
                >
                  <IconButton>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Grid> */}
        </Grid>
      </div>
    </>
  );
}
