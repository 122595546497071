import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Autocomplete,
  Alert,
  Box,
  CircularProgress,
  Grid,
  Chip,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import HomeIcon from '@mui/icons-material/Home';
import BusinessIcon from '@mui/icons-material/Business';
import WorkIcon from '@mui/icons-material/Work';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import WarningIcon from '@mui/icons-material/Warning';

import { useAppDispatch, useAppSelector } from '../../hooks';

import fetchFinalAddress from '../../api/canada-post/fetchFinalAddress';
import fetchPlaces from '../../api/canada-post/fetchPlaces';

import { updateOrderInfo } from '../../slices/orderSlice';
import { setCurrentCartTab } from '../../slices/authNavReducer';
import { open as openSnackbar } from '../../slices/snackbarReducer';

import PrimaryButton from '../PrimaryButton/PrimaryButton';

import 'react-phone-input-2/lib/material.css';

export default function Profile(props: any) {
  const dispatch = useAppDispatch();
  const { t, i18n }: any = useTranslation();

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [lastId, setLastId] = React.useState('');
  const [descriptiveAddress, setDescriptiveAddress] = React.useState({} as any);
  const [finalAddressId, setFinalAddressId] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const order = useAppSelector((state) => state.order.order);

  const orderInfoUpdating = useAppSelector(
    (state) => state.order.orderInfoUpdating
  );

  React.useEffect(() => {
    if (searchTerm.length > 0 || lastId.length > 0) {
      fetchPlaces(setOptions, searchTerm, lastId, setLoading, setOpen);
    }
  }, [searchTerm, lastId]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleAutoCompleteSelected = (e: any, v: any) => {
    if (!v) {
      return;
    }

    if (!v.hasOwnProperty('Next')) {
      return;
    }

    if (v.Next === 'Find') {
      console.log('Going Find');
      setLastId(v.Id);
    }

    if (v.Next === 'Retrieve') {
      console.log('Going Retrieve');
      setLastId('');
      setSearchTerm('');
      setAddress(v.Text + ' ' + v.Description);
      setFinalAddressId(v.Id);
      fetchFinalAddress(v.Id, setDescriptiveAddress);
    }
  };

  const handleAutoCompleteBlur = () => {
    if (lastId.length > 0) {
      setLastId('');
      setSearchTerm('');
      setOpen(false);
    }
  };

  return (
    <Formik
      initialValues={{
        addressType: 'home',
        deliveryOption: 'leaveAtDoor',
        deliveryAddress: '',
        notes: '',
        buzzer: '',
        organization: '',
      }}
      validationSchema={Yup.object().shape({
        addressType: Yup.string().required('Address type is required'),
        deliveryOption: Yup.string().required('Delivery option is required'),
        notes: Yup.string(),
        deliveryAddress: Yup.string().required('Address is required'),
        buzzer: Yup.string(),
        organization: Yup.string(),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        console.log(values);

        if (!descriptiveAddress.hasOwnProperty('Id')) {
          dispatch(
            openSnackbar({
              message: 'Please select an address',
              severity: 'error',
            })
          );
          setSubmitting(false);

          return;
        }

        const address = {
          canadaPostId: descriptiveAddress.Id,
          Label: descriptiveAddress.Label,
          SubBuilding: descriptiveAddress.SubBuilding,
          BuildingNumber: descriptiveAddress.BuildingNumber,
          BuildingName: descriptiveAddress.BuildingName,
          Street: descriptiveAddress.Street,
          City: descriptiveAddress.City,
          Line1: descriptiveAddress.Line1,
          Line2: descriptiveAddress.Line2,
          Line3: descriptiveAddress.Line3,
          Province: descriptiveAddress.Province,
          PostalCode: descriptiveAddress.PostalCode,
          Type: descriptiveAddress.Type || '',
          buzzer: values.buzzer,
          addressType: values.addressType,
          notes: values.notes,
          deliveryOption: values.deliveryOption,
          organization: values.organization,
        };

        console.log(values);
        setSubmitting(true);
        dispatch(
          updateOrderInfo(address, 'address', () => {
            setSubmitting(false);
            dispatch(setCurrentCartTab('checkout'));
          })
        );
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} className="form-login">
          <p className="offscreen-menu__content__section-heading">
            {t('typeOfAddress')}
          </p>

          <Box display="flex" flexWrap="wrap">
            <Chip
              className={`chip-default ${
                values.addressType === 'home' ? 'selected' : ''
              }`}
              clickable
              icon={<HomeIcon fontSize="small" />}
              label={t('home')}
              onClick={() => {
                setFieldValue('addressType', 'home');
              }}
            />
            <Chip
              className={`chip-default ${
                values.addressType === 'apartment' ? 'selected' : ''
              }`}
              clickable
              icon={<BusinessIcon fontSize="small" />}
              label={t('apartment')}
              onClick={() => {
                setFieldValue('addressType', 'apartment');
              }}
            />
            <Chip
              className={`chip-default ${
                values.addressType === 'business' ? 'selected' : ''
              }`}
              clickable
              icon={<WorkIcon fontSize="small" />}
              label={t('work')}
              onClick={() => {
                setFieldValue('addressType', 'business');
              }}
            />
          </Box>

          <p
            className="offscreen-menu__content__section-heading"
            style={{ marginBottom: 0 }}
          >
            {t('address')}
          </p>

          <Autocomplete
            freeSolo
            style={{ width: '100%' }}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            noOptionsText="No address found"
            //@ts-ignore
            getOptionLabel={(option: any) =>
              option.Text + ' ' + option.Description
            }
            onChange={(e: any, v: any) => {
              handleAutoCompleteSelected(e, v);
              handleChange(e);
            }}
            autoComplete={false}
            options={options}
            loading={loading}
            onBlur={(e: any) => {
              handleAutoCompleteBlur();
              handleBlur(e);
            }}
            clearOnEscape={true}
            renderInput={(params) => (
              <TextField
                {...params}
                error={
                  errors.deliveryAddress && touched.deliveryAddress
                    ? true
                    : false
                }
                autoComplete="off"
                color="secondary"
                fullWidth
                margin="normal"
                label={t('deliveryAddress')}
                name="deliveryAddress"
                variant="standard"
                onChange={(e: any) => {
                  setSearchTerm(e.target.value);
                  handleChange(e);
                }}
                value={values.deliveryAddress}
                // helperText={
                //   errors.deliveryAddress &&
                //   touched.deliveryAddress &&
                //   errors.deliveryAddress
                // }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {/* {!loading && addressCorrect ? <DoneIcon /> : null} */}

                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />

          <img
            style={{ marginTop: '0px', marginBottom: '16px' }}
            width="154"
            height="21"
            src={
              i18n.language === 'fr'
                ? require('../../assets/address-complete-fr.png')
                : require('../../assets/address-complete-en.png')
            }
          />

          <Grid container spacing={3}>
            {values.addressType !== 'home' && (
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  error={errors.buzzer && touched.buzzer ? true : false}
                  id="buzzer"
                  name="buzzer"
                  label="Buzzer"
                  type="text"
                  color="secondary"
                  value={values.buzzer}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // helperText={errors.buzzer && touched.buzzer && errors.buzzer}
                />
              </Grid>
            )}

            {values.addressType === 'business' && (
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="organization"
                  name="organization"
                  label={t('organization')}
                  type="text"
                  color="secondary"
                  value={values.organization}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.organization && touched.organization ? true : false
                  }
                  // helperText={
                  //   errors.organization &&
                  //   touched.organization &&
                  //   errors.organization
                  // }
                />
              </Grid>
            )}
          </Grid>

          <p
            className="offscreen-menu__content__section-heading"
            style={{ marginTop: '32px' }}
          >
            {t('deliveryOptions')}
          </p>

          <Box display="flex" flexWrap="wrap">
            <Chip
              className={`chip-default ${
                values.deliveryOption === 'leaveAtDoor' && 'selected'
              }`}
              clickable
              icon={<MeetingRoomIcon fontSize="small" />}
              label={t('leaveAtDoor')}
              onClick={() => {
                setFieldValue('deliveryOption', 'leaveAtDoor');
              }}
            />
            <Chip
              style={{ cursor: 'not-allowed' }}
              className={`chip-default chip--disabled`}
              icon={<DirectionsWalkIcon fontSize="small" />}
              label={t('meetAtDoor')}
            />
            <Chip
              style={{ cursor: 'not-allowed' }}
              className={`chip-default chip--disabled`}
              icon={<DirectionsCarIcon fontSize="small" />}
              label={t('meetOutside')}
            />
          </Box>

          <Alert
            style={{ marginTop: '1em' }}
            severity="warning"
            icon={<WarningIcon />}
          >
            {t('covidMessage')}
          </Alert>

          <p className="offscreen-menu__content__section-heading">
            Instructions
          </p>

          <TextField
            color="secondary"
            label="Instructions"
            name="notes"
            fullWidth
            multiline
            value={values.notes}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(errors.notes && touched.notes)}
            // helperText={errors.notes && touched.notes && errors.notes}
          />

          <PrimaryButton
            style={{ width: '100%', marginTop: '2em' }}
            type="submit"
            disabled={isSubmitting}
          >
            {t('addAddress')}
          </PrimaryButton>
        </form>
      )}
    </Formik>
  );
}
