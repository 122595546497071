import { Button, styled } from '@mui/material';

export default styled(Button)({
  backgroundColor: '#fee173',
  fontFamily: "'Inter-SemiBold', sans-serif",
  fontSize: '1em',
  boxShadow: 'none',
  borderRadius: 0,
  textTransform: 'none',
  position: 'relative',
  '&.MuiButton-label': {
    color: '#fff',
  },
  '&:hover': {
    backgroundColor: '#fdd127',
    borderColor: '#fdd127',
  },
  padding: '12px 32px',
  transition: 'all .25s ease',
  color: '#000',
  border: '2px solid #fee173',
});
