import { createSlice } from "@reduxjs/toolkit";

import CategoriesService from "../api/categories";

import { AppDispatch, AppThunk } from "../store";

// Get all category lists.

const initialState = {
  categories: {
    data: [],
    total: 0,
  },
  categoriesLoading: true,
};

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    getCategoriesSuccess: (state, action) => {
      state.categories = action.payload;
      state.categoriesLoading = false;
    },
    setCategoriesLoading: (state, action) => {
      state.categoriesLoading = action.payload;
    },
  },
});

export const { getCategoriesSuccess, setCategoriesLoading } =
  categoriesSlice.actions;

export const getCategories =
  (query?: object | undefined): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setCategoriesLoading(true));
      const products = await CategoriesService.find(query);
      dispatch(getCategoriesSuccess(products));
      dispatch(setCategoriesLoading(false));
    } catch (error) {
      console.log(error);
    }
  };

export default categoriesSlice.reducer;
