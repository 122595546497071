import React from 'react';
import { Grid, Container } from '@mui/material';

import './CTAButtonWithHeading.scss';
import PrimaryButton from '../PrimaryButton/PrimaryButton';

export default (props: any) => {
  return (
    <Container className="container">
      <Grid container>
        <Grid item md={12}>
          <div className={`CTA`}>
            <div className={`CTA__Heading`}>
              <h1>{props.text}</h1>
            </div>
            <div className={`CTA__Button`}>
              <PrimaryButton
                style={{
                  fontSize: '21px',
                }}
                onClick={() => {
                  //   dispatch(setCurrentTabNonAuth('signup-options'));
                  //   toggleSignUpMenu();
                }}
              >
                Request a quote
                <span className="arrow" style={{ marginLeft: '8px' }}>
                  {'→'}
                </span>
              </PrimaryButton>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};
